.account-settings {
	background-color: white;
}

.account-tabs {
	display: flex;
	border: none;
	outline: none;
}

.account-tabs > button {
	background-color: white;
	color: rgb(170, 170, 170);
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 18px;
	font-weight: 500;
	margin-left: 30px;
	padding-bottom: 16px;
	padding-top: 19px;
	padding-left: 15px;
	padding-right: 15px;
}

.account-tabs > button:focus {
	color: rgb(51, 51, 51);
	border-bottom: 3px solid #33d7db;
}

.account-settings > .account-tabs > p {
	padding-left: 60px;
	font-size: 18px;
	font-weight: 500;
	color: rgba(170, 170, 170, 1);
}

.account-settings > .accounts-icon > .content > .name > .initial {
	margin: 0 auto;
	width: 80px;
	height: 80px;
	font-weight: bold;
}

.account-settings > .tip > hr {
	border: 0.5px solid #808080;
	width: 85vw;
}

.account-page-title {
	background-color: #f9f9f9;
	padding-bottom: 35px;
	padding-top: 31px;
	padding-left: 32px;
	padding-right: 32px;
}

.account-page-title > .account-page-title-container {
	font-weight: 800;
	font-size: 30px;
	font-style: normal;
	padding-top: 20px;
	padding-left: 47px;
	padding-bottom: 30px;
	border-bottom: 0.5px solid #808080;
}

.account-settings > .accounts-icon > .content > .handle-change-password > .text-change-pass {
	text-align: center;
	margin-bottom: 30px;
	/* margin: auto; */
}

.account-settings > .accounts-icon > .content > .description {
	text-align: center;
	margin: auto;
}

.account-settings .content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
}

.account-settings > .accounts-icon > .content > .description > .full-name {
	font-weight: bold;
	font-size: 20px;
	padding-bottom: 5px;
	text-transform: capitalize;
}

.account-settings > .accounts-icon > .content > .user-info {
	display: flex;
	/* padding-top: 50px;
	padding-bottom: 50px; */
	padding: 30px 10px;
	margin: 0 auto;
	width: 500px;
	justify-content: space-between;
}

.account-settings .master-setup .ant-input {
	width: 200px;
}

.ant-input-affix-wrapper:hover {
	border: 1px solid #33d7db !important;
}

.account-settings > .accounts-icon > .content > .user-info > .email > .heading,
.account-settings > .accounts-icon > .content > .user-info > .phone > .heading {
	font-weight: bold;
	padding-bottom: 8px;
}

.account-settings > .accounts-icon > .content > .user-info > .phone > .heading {
	text-align: left;
}

.account-settings > .accounts-icon > .content > .description > .desc {
	font-size: 16px;
	padding-top: 15px;
}

.accounts-icon > .content > .name > .name-info {
	display: flex;
	flex-direction: column;
	padding-left: 10px;
	max-width: 200px;
	/* gap: 10px; */
}

.account-settings > .accounts-icon > .content > .handle-change-password {
	/* padding-left: 30px; */
	/* margin-left: 460px; */
	display: flex;
	justify-content: space-between;
	width: 500px;
	margin: 0 auto;
}

.account-settings-tabs {
	padding: 30px;
}

.account-settings > .accounts-icon > .content > .handle-change-password > .change-password > .input-container {
	display: flex;
	padding-bottom: 10px;
	margin-top: 10px;
}

.dragger-download-template {
	font-family: 'League Spartan';
	font-size: 16px;
	color: #33d7db;
	text-align: center;
	width: 200px;
	cursor: pointer;
	margin: auto;
	margin-top: 10px;
}

.account-settings > .accounts-icon > .content > .handle-change-password > .text-change-pass {
	font-weight: bold;
}

.account-setting-label {
	/* background-color: #01f89d; */
	height: 35px;
	font-size: 18px;
}

.account-settings-tabs .ant-tabs .ant-tabs-tab {
	padding: 2px 0px !important;
}

.master-setup-maindiv .ant-tabs .ant-tabs-tab {
	padding: 2px 16px !important;
}

.admin-setup-maindiv .ant-tabs .ant-tabs-tab {
	padding: 2px 16px !important;
}

.account-settings .master-setup-maindiv {
	padding: 20px 0px;
}

.account-settings .master-tab-options {
	font-size: 16px;
	font-family: 'League Spartan';
	font-weight: 400;
}

.master-asset-div .master-asset-types-div .heading {
	font-size: 20px;
	font-family: 'League Spartan';
	font-weight: 600;
	color: rgba(19, 18, 18, 0.838);
}

.master-asset-div .size-div {
	width: 900px;
}

.account-settings .po-size-div {
	width: 900px;
}

.master-asset-div .master-asset-types-div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 10px;
}

.department-table-div .ant-table-content,
.master-asset-div .master-asset-table .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 13px;
	/* color: #000000; */
}

.department-table-div .ant-table-thead .ant-table-cell,
.master-asset-div .master-asset-table .ant-table-thead .ant-table-cell {
	background: #fafafa !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #555555;
}

.admin-setup-roles {
	width: 800px;
}

.admin-setup-roles .ant-table-content {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 15px;
}

.admin-setup-roles .ant-table-thead .ant-table-cell {
	background: #fafafa !important;
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 16px;
}

.roles-tables .ant-table-thead .ant-table-cell {
	background: #fafafa !important;
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 16px;
}

.roles-tables .ant-table-content {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 15px;
}

.roles-tables .table-details {
	margin-top: 20px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 15px;
}

.master-asset-drawer {
	width: 80%;
	margin: 20px auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.master-asset-drawer .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
}

/* .ant-tabs-nav .ant-tabs-tab-active {
	color: #33D7DB;
} */

/* .account-settings>.accounts-icon>.content>.handle-change-password>.change-password>.input-container>.new-pass {
	padding-left: 190px;
} */
.account-settings > .accounts-icon > .content > .handle-change-password > .change-password > .input-container {
	display: flex;
	justify-content: space-between;
}

.employee-setup-container .employee-search-container .ant-input-search .ant-input:hover {
	border-color: #33d7db !important;
}

.employee-setup-container .employee-search-container .ant-btn-primary {
	border-color: #33d7db !important;
	background: #33d7db !important;
}

.ant-design-button {
	font-weight: 400 !important;
	border-color: #33d7db !important;
	background: #33d7db !important;
	color: black !important;
	border-radius: 3px !important;
}

.ant-design-button-small {
	font-size: 16px !important;
	font-family: 'League Spartan';
	font-weight: 400 !important;
	border-color: #33d7db !important;
	background: #33d7db !important;
	color: black !important;
	border-radius: 3px !important;
}

.buttons > .save-button {
	padding: 10px 50px;
	border: none;
	background-color: #54f1a1;
	border-radius: 8px;
}

.buttons {
	display: flex;
	justify-content: space-around;
	margin: 20px 0;
}

.buttons > .cancel-button {
	padding: 10px 50px;
	border: none;
	border-radius: 8px;
}

.placeholder-old {
	display: flex;
	position: relative;
	/* padding: 10px 5px; */
	align-items: center;
	flex-direction: row;
	width: 200px;
}

.placeholder-new {
	display: flex;
	position: relative;
	/* padding: 10px 5px; */
	align-items: center;
	flex-direction: row;
	width: 200px;
}

.eye-icon {
	/* display: flex; */
	/* position: absolute; */
	/* right: 10px; */
	cursor: pointer;
	font-size: 17px;
	color: #333;
}

.master-setup > p {
	color: rgba(136, 136, 136, 1);
	font-size: 18px;
	font-weight: 800;
	/* font-family: "League-Spartan"; */
	/* text-align: center; */
	margin-top: 50px;
}

.master-setup > .tag-and-currency {
	display: flex;
	padding-top: 50px;
	/* margin: 0 auto; */
	/* width: 600px; */
	justify-content: space-between;
}

.company-info .heading-div,
.company-dept-div .heading-div {
	/* max-width: 500px; */
	display: flex;
	align-items: center;
	margin: 30px 0;
	justify-content: space-between;
}

.master-setup {
	max-width: 45vw;
}

.edit-company-info .upload-company-logo-button .ant-image .ant-image-img {
	padding: 3px;
	width: 70px !important;
	height: 70px;
	border-radius: 50%;
	border-radius: 50%;
	box-shadow: 0 0 2px #afafaf;
	object-fit: contain;
}

.edit-company-info .upload-company-logo-button {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}

.edit-company-info .ant-upload-wrapper {
	display: flex !important;
	flex-direction: column;
	align-items: center;
}

.department-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* .company-dept-div .heading-div {
	max-width: 700px;
} */

.company-dept-div {
	max-width: 70vw;
}

.company-info .heading-div .heading,
.company-dept-div .heading-div .heading {
	font-family: 'League Spartan';
	font-size: 26px;
	font-weight: 400;
}

.company-info .company-info-data {
	font-family: 'League Spartan';
	font-size: 17px;
}

.master-setup > .office-details {
	display: flex;
	padding-top: 50px;
	/* margin: 0 auto; */
	/* width: 600px; */
	justify-content: space-between;
}

.master-setup > .deprecValue-and-value {
	display: flex;
	padding-top: 50px;
	/* width: 600px; */
	/* margin: auto; */
	justify-content: space-between;
}

.master-setup > .tag-and-currency > .asset-tag > .master-heading,
.master-setup > .tag-and-currency > .currency > .master-heading,
.master-setup > .deprecValue-and-value > .asset-tag > .master-heading,
.master-setup > .deprecValue-and-value > .currency > .master-heading,
.master-setup > .office-details > .currency > .master-heading,
.master-setup > .office-details > .asset-tag > .master-heading {
	font-size: 22px;
	font-weight: 500;
	font-family: 'League-Spartan';
	color: rgb(51, 51, 51);
	padding-bottom: 5px;
}

.master-setup > .tag-and-currency > .asset-tag > input,
.master-setup > .tag-and-currency > .currency > input,
.master-setup > .deprecValue-and-value > .asset-tag > input,
.master-setup > .office-details > .asset-tag > input,
.master-setup > .office-details > .currency > input,
.master-setup > .deprecValue-and-value > .currency > .duration-months > input {
	border: 0.5px solid rgba(170, 170, 170, 1);
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 400;
	/* color: rgba(170, 170, 170, 1); */
}

.months {
	font-size: 18px;
	font-weight: 400;
	color: rgb(0, 0, 0);
}

/* .duration-months {
	display: flex;
} */

.add-admin {
	display: flex;
	justify-content: space-between;
	width: 220px;
	margin: auto;
	margin-top: 30px;
	padding-right: 350px;
}

.checkbox {
	color: #33d7db;
}

.add-admin > .add-admin-text {
	font-size: 18px;
	font-weight: 400;
	color: rgb(51, 51, 51);
}

.fill-details {
	display: flex;
	justify-content: space-between;
	width: 35%;
	margin: auto;
	margin-top: 20px;
}

.fill-details > input {
	border: 0.5px solid rgba(170, 170, 170, 1);
	border-radius: 5px;
	padding-left: 8px;
	padding-top: 5px;
	padding-bottom: 6px;
	font-size: 14px;
	font-weight: 400;
	color: rgba(170, 170, 170, 1);
}

/* .user-info>.email {
	margin-left: 30px;
} */

.designation {
	margin-left: 30px;
}

.action-button > .save-button {
	padding: 10px 50px;
	border: none;
	background-color: #54f1a1;
	margin: 20px 20px;
	border-radius: 8px;
}

.action-button {
	display: inline-block;
	margin: 0 auto;
}

.action-button > .cancel-button {
	padding: 10px 50px;
	border: none;
	border-radius: 8px;
}

.companyInfo-action-button {
	margin: auto;
	width: 500px;
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
}

.master-setup > .add-admin-button > button {
	border-radius: 5px;
	background-color: #33d7db;
	border: none;
	padding-left: 29px;
	padding-right: 28px;
	padding-top: 7px;
	padding-bottom: 7px;
	text-align: center;
	margin-left: 32%;
	margin-top: 30px;
}

.office-headquarter {
	display: inline-block;
	margin: 0 auto;
	text-align: center;
}

.office-headquarter-text {
	font-size: 21px;
	font-weight: 800;
	color: rgb(51, 51, 51);
	margin: auto;
}

.upload-img {
	background-color: #f8f8f8;
	border: 1px dashed #808080;
	padding: 10px 168px;
	border-radius: 5px;
}

.upload-ways {
	display: flex;
}

.upload-ways > button {
	font-size: 18px;
	font-weight: 900;
	color: #33d7db;
	border: none;
	background-color: #f8f8f8;
}

.upload-img > p {
	font-size: 14px;
	font-weight: normal;
	color: #333333;
}

.company-branch-button {
	display: flex;
	align-self: center;
}

.company-branch-button > button {
	padding: 10px;
	/* display: flex;
  align-self: center;
  justify-content: center; */
	border: none;
	background-color: #54f1a1;
	width: 150px;
	/* margin: 20px 20px; */
	border-radius: 8px;
	margin-top: 50px;
	margin-left: 30vw;
	font-size: 12px;
	font-weight: 400;
	color: #333;
}

.companyInfo.action-button {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.account-tabs > button.active {
	border-bottom: 2px solid #33d7db;
	color: #333;
}

.country-dropdown {
	width: 210px;
	font-size: 14px;
	font-weight: 400;
}

.currency > input {
	width: 200px;
	padding: 10px 10px !important;
	border: 1px solid #d0d0d0;
}

.master-setup > .tag-and-currency > .asset-tag > input,
.master-setup > .tag-and-currency > .currency > input,
.master-setup > .deprecValue-and-value > .asset-tag > input,
.master-setup > .office-details > .asset-tag > input,
.master-setup > .office-details > .currency > input,
.master-setup > .deprecValue-and-value > .currency > .duration-months > input {
	width: 200px;
	padding: 10px 10px !important;
	border: 1px solid #d0d0d0;
}

.admin-setup-container,
.add-user-container {
	text-align: center;
	padding: 20px;
}

.admin-setup-container {
	margin-top: 2rem;
}

.admin-setup-innerdiv {
	width: 80%;
}

/* .admin-setup-container > p,
.add-user-container > p {
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 800;
	color: rgba(136, 136, 136, 1);
	margin-top: 35px;
} */

.admin-setup-header p {
	font-size: 18px;
	font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
	font-weight: 700;
	color: rgba(19, 18, 18, 0.838);
}

.admin-setup-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.admin-setup-container > div > .add-user {
	font-size: 12px;
	font-weight: 400;
	color: rgb(51, 51, 51);
	padding: 7px 27px;
	border: none;
	background-color: #5efba1;
	border-radius: 5px;
	margin-top: 50px;
	margin-left: 750px;
}

.admin-setup-container > .admin-setup-table {
	border-top: 0.5px solid #808080;
	border-bottom: 0.5px solid #808080;
	border-collapse: collapse;
	margin: 20px auto;
}

.admin-setup-container > .admin-setup-table > tbody > tr > td {
	border-top: 0.5px solid #808080;
	border-collapse: collapse;
	padding-left: 40px;
	padding-right: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: 400;
	color: rgb(85, 85, 85);
}

.admin-setup-container > .admin-setup-table > thead > tr > th {
	font-size: 16px;
	font-weight: 800;
	color: rgb(51, 51, 51);
	padding-right: 10px;
	padding-left: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.admin-setup-action-button {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 90px;
}

.admin-setup-action-button > .save-button {
	padding: 10px 50px;
	border: none;
	background-color: #54f1a1;
	margin: 20px 20px;
	border-radius: 8px;
}

.admin-setup-action-button > .cancel-button {
	padding: 10px 50px;
	border: none;
	border-radius: 8px;
}

.admin-setup-modal {
	text-align: center;
	padding: 21px 45px;
}

.admin-setup-modal-text {
	font-size: 16px;
	font-weight: 800;
	width: 230px;
}

.admin-setup-modal-button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 35px;
	margin-bottom: 10px;
}

.admin-setup-modal > .admin-setup-modal-button > .save-button {
	padding: 6px 25px;
	border: none;
	background-color: #54f1a1;
	margin-left: 30px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 500;
	color: rgb(51, 51, 51);
}

.admin-setup-modal-button > .cancel-button {
	padding: 6px 35px;
	border: none;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 500;
	color: rgb(51, 51, 51);
}

.adminsetup-designation {
	display: flex;
	align-items: center;
	gap: 5px;
}

.adminsetup-role,
.adminsetup-designation,
.adminsetup-createdon {
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
}

.adminsetup-role {
	text-transform: capitalize;
}

.page-title {
	font-size: 18px;
	font-weight: 800;
	color: rgb(51, 51, 51);
	margin-top: 20px;
	margin-bottom: 60px;
}

.add-user-container > hr {
	border: 0.5px solid #808080;
	width: 60vw;
	margin: 40px auto;
	margin-right: 15%;
}

.add-user-input {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 20px;
}

.add-user-input > input {
	margin-bottom: 20px;
	border: 0px;
	padding: 10px 10px;
	border: 1px solid #808080;
	border-radius: 5px;
	background-color: white;
}

.add-user-input > div.password {
	margin-bottom: 20px;
	border: 0px;
	border: 1px solid #808080;
	border-radius: 5px;
	background-color: white;
	position: relative;
	margin-top: 20px;
}

.add-user-input > div.password > input {
	border: 0px;
	width: 90%;
	outline: none;
	padding: 10px 10px;
}

.add-user-input > div.password > .eye-icon {
	right: 10px;
	font-size: 20px;
	cursor: pointer;
	top: 7px;
}

.add-user-input > .teamname {
	margin-top: 20px;
}

.input-rows > input {
	background: #ffffff;
	border: 0.5px solid #808080;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 400;
	color: #aaaaaa;

	padding-left: 8px;
	padding-top: 11px;
	padding-bottom: 10px;

	margin-bottom: 20px;
	margin-right: 80px;
}

.input-rows-dropdown > input {
	background: #ffffff;
	border: 0.5px solid #808080;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 400;
	color: #aaaaaa;

	padding-left: 8px;
	padding-top: 11px;
	padding-bottom: 10px;

	margin-right: 80px;
}

.input-rows,
.input-rows-dropdown {
	display: flex;
	width: 500px;
	margin: auto;
}

.input-rows-last {
	margin-top: 20px;
}

.input-rows-last > input {
	background: #ffffff;
	border: 0.5px solid #808080;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 400;
	color: #aaaaaa;

	padding-left: 8px;
	padding-top: 11px;
	padding-bottom: 10px;

	margin-bottom: 20px;
	margin-right: 310px;
}

.pencil-icon,
.delete-icon {
	padding-left: 9px;
}

.user-icon {
	color: black;
	background-color: #33d7db;
	text-align: center;
	border-radius: 50% 50%;
	padding-right: 9px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
}

.user {
	display: flex;
}

.user-details {
	margin-left: 5px;
	text-align: left;
}

.user-details > .user-name {
	color: #29c7cb;
	font-weight: normal;
	font-size: 18px;
	text-transform: capitalize;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.user-details > .user-email {
	font-size: 14px;
	font-weight: normal;
	color: #888888;
}

.each-admin > .heading-department {
	text-transform: capitalize;
}

.employee-setup-id,
.employee-setup-createdon,
.employee-setup-doj {
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	max-width: 250px;
}

.employee-content-container {
	text-align: center;
	padding-top: 15px;
}

/* .employee-setup-container{
	margin-top: 5rem;
} */
.employee-setup-innerdiv {
	width: 80%;
}

.employee-setup-header p {
	font-size: 18px;
	font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
	font-weight: 700;
	color: rgba(19, 18, 18, 0.838);
}

.employee-search-container {
	margin-top: 2rem;
	display: flex;
	justify-content: space-between;
}

.employee-search-container > input {
	padding: 10px;
	/* margin-right: 11%; */
}

.employee-content-container > .head {
	font-size: 18px;
	font-weight: 800;
	color: rgba(136, 136, 136, 1);
	margin-top: 35px;
}

.update-employee-container {
	padding: 40px 20px;
}

.create-employee-heading {
	text-align: center;
}

.update-employee-container > input {
	display: block;
	margin-bottom: 20px;
	width: 300px;
	padding: 15px 10px;
	border: 0px;
	border-radius: 5px;
	border: 1px solid #333333;
}

.update-employee-container > .actions {
	display: flex;
	justify-content: space-between;
}

.update-employee-container > .actions > button {
	width: 45%;
	background-color: transparent;
	padding: 10px;
	border: 0px;

	border-radius: 5px;
}

.update-employee-container > .actions > button:nth-child(2) {
	border: 1px solid #333;
}

.update-employee-container > .actions > button:nth-child(1) {
	background-color: #33d7db;
	color: white;
	font-weight: bold;
}

.create-employee-button {
	background-color: transparent;
	padding: 10px 20px;
	background-color: #33d7db;
	color: white;
	border: 0px;
	border-radius: 5px;
	border: 1px solid #33d7db;
	font-weight: bold;
}

.sign-off-action {
	display: flex;
	padding-right: 10px;
	justify-content: space-between;
	align-items: baseline;
}

.sign-off-action > div,
.sign-off-action > svg {
	background: #33d7db;
	border-radius: 5px;
	border: none;
	padding: 5px 20px;
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	color: #ffffff;
	cursor: pointer;
	margin-right: 20px;
}

.newsignoff-container {
	padding-bottom: 90px;
}

.newsignoff-container > .sign-off-note {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 800;
	font-size: 21px;
	line-height: 29px;
	color: #333333;
	margin-top: 28px;
	margin-left: 80px;
}

.save-next {
	border: none;
	background: #33d7db;
	border-radius: 5px;
	margin-top: 60px;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #333333;
	padding: 10px 60px;

	margin-left: 40%;
}

.newsignoff-container > .dept-division {
	background: #fafafa;
	border-radius: 5px;
	margin: 40px 25vw;
	padding-left: 5vw;
	padding-right: 5vw;
	padding-top: 30px;
	padding-bottom: 30px;
}

.dept-division > .react-multi-email {
	outline: 0;
	border-width: 0 0 1px;
	border-radius: 0px;
	border-bottom: 0.5px solid #888888;
	width: 98%;
	background: #fafafa;
}

.dept-division > .react-multi-email.focused {
	background: #fafafa;
	border-bottom: 0.5px solid #888888;
}

.dept-division > .react-multi-email > input {
	background: #fafafa;
}

.dept-division > .react-multi-email > span {
	background: #fafafa;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;

	/* identical to box height */

	color: #888888;
}

.dept-division > .company-dept {
	display: flex;
	margin-top: 40px;
	align-items: center;
}

.dept-division > .company-dept > div {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 29px;
	color: #555555;
	margin-right: 30px;
}

.exit-checklist {
	box-sizing: border-box;
	background: #ffffff;
	border: 0.5px solid #888888;
	border-radius: 5px;
	padding-top: 20px;
	padding-left: 30px;
	margin-top: 45px;
	padding-bottom: 38px;
}

.exit-checklist > .round-checkbox {
	width: 1.3em;
	height: 1.3em;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
	margin-bottom: 15px;
}

.exit-checklist > label {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	color: #555555;
	padding-left: 6px;
}

.newsignoff-container > .success-note-1,
.newsignoff-container > .success-note-2 {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 29px;
	text-align: center;

	color: #333333;
}

.newsignoff-container > .success-note-1 {
	margin-top: 90px;
}

.newsignoff-container > .success-image {
	text-align: center;
	margin-top: 100px;
}

.newsignoff-container > table {
	background: #ffffff;
	border-collapse: collapse;
	border-radius: 5px;
	border-style: hidden;
	/* hide standard table (collapsed) border */
	box-shadow: 0 0 0 1px #888;
	margin: 60px 5vw;
}

.newsignoff-container > table > thead > tr > th {
	background: #01f89d;
	border-bottom: 0.5px solid #888888;

	font-family: 'Avenir';
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */
	text-align: center;

	color: #333333;
	padding-left: 10px;
	padding-right: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.newsignoff-container > table > tbody > tr > td {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */
	text-align: center;

	color: #000000;
	padding-left: 2vw;
	padding-right: 4vw;
	padding-top: 20px;
	padding-bottom: 20px;
}

.checked-signoff {
	background: #33d7db;
	border: none;
	border-radius: 5px;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;

	text-align: center;

	color: #000000;
	padding: 8px 32px;
}

.thanks-container {
	text-align: center;
	margin-top: 100px;
}

.thanks-container > div {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 41px;
	text-align: center;

	color: #555555;
}

.newsignoff-container > .authority-note {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 900;
	font-size: 30px;
	line-height: 41px;

	/* identical to box height */

	color: #333333;
	margin-top: 40px;
	margin-left: 90px;
}

.newsignoff-container > .authority-sign {
	display: flex;
	margin-left: 5.5vw;
	margin-right: 5vw;
	margin-top: 57px;
}

.newsignoff-container > .authority-sign > table {
	background: #fafafa;
	padding-left: 3vw;
	padding-top: 40px;
	padding-right: 2vw;
	margin-right: 7vw;
	padding-bottom: 30vh;
}

.newsignoff-container > .authority-sign > table > tbody > tr > td {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */

	color: #333333;
	padding-bottom: 18px;
}

.newsignoff-container > .authority-sign > table > tbody > tr > .sign-values {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */

	color: #333333;
	padding-left: 3vw;
}

.sign-section > .select-actions {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */

	color: #333333;
}

.sign-section > .exit-checklist {
	background: #fafafa;
	border: 0.5px solid #888888;
	border-radius: 5px;
	margin-top: 14px;
	width: 37vw;
}

.sign-section > .authority-comment {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */

	color: #555555;
	margin-top: 38px;
	margin-bottom: 10px;
}

.sign-section > textarea {
	width: 37vw;
	background: #ffffff;
	border: 0.5px solid #aaaaaa;
	border-radius: 5px;
	height: 7vh;
}

.sign-section > .agree-sign {
	margin-top: 30px;
}

.sign-section > .agree-sign > input {
	background: #ffffff;
	border: 0.5px solid #888888;
	border-radius: 3px;
	margin-right: 18px;
}

.sign-section > .agree-sign > label {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;

	/* identical to box height */

	color: #555555;
}

.sign-section > .auth-signature {
	background: #33d7db;
	border-radius: 5px;
	border: none;
	margin-top: 100px;

	padding: 9px 90px;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;

	/* identical to box height */

	color: #333333;
}

.confirm-signoff > .modal-message {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;

	/* identical to box height */

	color: #333333;
	padding-bottom: 20px;
}

.confirm-signoff,
.admin-proceed {
	margin: 30px 50px;
	text-align: center;
}

.confirm-signoff > input,
.admin-proceed > input {
	background: #ffffff;
	border: 0.5px solid #555555;
	border-radius: 5px;
	padding: 10px 10px;
	margin-bottom: 20px;
	width: 45%;
}

.confirm-signoff > .actions > button,
.admin-proceed > .actions > button {
	background: #33d7db;
	border-radius: 5px;
	border: none;

	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;

	/* identical to box height */

	color: #333333;

	padding: 9px 77px;
}

.admin-proceed > .modal-message {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 900;
	font-size: 30px;
	line-height: 41px;

	/* identical to box height */

	color: #333333;
}

.admin-proceed > .modal-note {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;

	/* identical to box height */

	color: #333333;
	padding-top: 20px;
	padding-bottom: 26px;
}

.deprecValue-and-value > .asset-tag > button,
.deprecValue-and-value > .currency > button {
	background-color: #29c7cb;
	padding: 15px 30px;
	/* border: 1px solid #3f3f3f; */
	border-radius: 5px;
	color: white;
	border: 0px;
	font-weight: 500;
	font-family: 'League Spartan';
}

.addShifts > button {
	background: #36c7ca;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	border: none;
	outline: none;
	padding: 15px 30px;
	width: 175px;
}

.shiftTimingModalContainer {
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin: 30px 18px 18px 21px;
	overflow-y: scroll;
}

.shiftTimingModalContainer > h3 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 21px;
	color: #555555;
	border-bottom: 0.5px solid #aaaaaa;
	padding: 5px 10px;
}

.shiftTimeContainer {
	display: flex;
	gap: 90px;
	justify-content: space-between;
}

.shiftInfo {
	margin-left: 10px;
}

.shiftTime {
	display: flex;
	gap: 24px;
}

.timePickerContainer {
	height: 300px;
	width: 120px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 10px;
	background: #ffffff;
	box-shadow: 8px 8px 16px rgba(136, 136, 136, 0.25), -8px -8px 16px rgba(136, 136, 136, 0.25);
	border-radius: 5px;
	margin-left: -100px;
	padding: 5px 10px;
	overflow-y: scroll;
	z-index: 10;
}

.timePicker {
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 16px;
	color: #555555;
	padding: 15px 10px;
	/* overflow-y: scroll; */
}

.selected {
	color: #33d7db;
}

.meridianPicker {
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 12px;
	color: #888888;
	padding: 6px 0px;
}

.meridianPicker > h6 {
	border: 0.5px solid #888888;
	border-radius: 3px;
	padding: 3px;
	/* margin-left: -10px; */
}

.shiftAddBtn > button {
	background: #33d7db;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 12px;
	color: #333333;
	border: none;
	outline: none;
	margin-left: 10px;
	padding: 10px 40px;
	align-self: center;
}

.master-asset-type-container {
	padding: 20px;
	width: 450px;
}

.master-asset-type-container > .add-container {
	display: flex;
	margin-bottom: 20px;
}

.master-asset-type-container > .add-container > input {
	display: flex;
	flex: 1;
	font-size: 16px;
	padding: 5px 10px;
	border: 1px solid #3f3f3f;
}

.master-asset-type-container > .add-container > button {
	padding-left: 40px;
	padding-right: 40px;
	margin-left: 20px;
	background-color: #29c7cb;
	border: 0px;
	border-radius: 5px;
	color: white;
}

.category-show-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.category-show-container > .each-asset-type {
	background-color: #01f89d;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-right: 10px;
	padding-left: 20px;
	margin-bottom: 10px;
	border-radius: 100000px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.category-show-container > .each-asset-type > span {
	padding-right: 10px;
}

.category-show-container > .each-asset-type > svg {
	padding-right: 10px;
	cursor: pointer;
}

.non-it-asset-container {
	display: flex;
	align-items: center;
	margin: 0px 10px;
	font-size: 12px;
}

.admin-setup-actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

.admin-setup-actions > button {
	background-color: #5efba1;
	border: none;
	border-radius: 5px;
	color: #333;
	font-size: 12px;
	font-weight: 400;
	margin-left: 750px;
	margin-top: 50px;
	padding: 7px 27px;
}

.migrate-user-container-modal > h3 {
	margin-top: 0px;
	padding-top: 20px;
}

.migrate-user-container-modal {
	width: 350px;
	padding: 0 15px;
	background: #ffffff;
	padding-bottom: 20px;
}

.migrate-user-container-modal > input {
	margin-bottom: 20px;
	width: calc(100% - 20px);
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #888888;
	border: 0.5px solid #888888;
	border-radius: 3px;
	padding: 10px;
}

.migrate-user-container-modal > input:first-child {
	margin-top: 30px;
}

.migrate-user-container-modal > .actions {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}

.migrate-user-container-modal > .actions > button {
	border-radius: 5px;
	border: 0px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	padding: 10px 20px;
	color: #333333;
	background: #5efba1;
}

.migrate-user-container-modal > .actions > .yes-btn {
	background: #f2f2f2;
	margin-right: 30px;
	padding: 10px 30px;
}

.migrate-user-container-modal > .confirmation-text {
	font-size: 18px;
	padding-top: 20px;
	line-height: 26px;
	text-align: center;
}

.additional-comments {
	width: 250px;
	margin: 10px;
	margin-top: 0px;
	border: 0.5px solid #808080 !important;
	border-radius: 5px;
	color: #808080;
	margin-bottom: 30px;
}

.additional-comments::placeholder {
	padding: 10px;
}

.create-employee-heading {
	margin-bottom: 30px;
	font-weight: 900;
	font-size: 21px;
	color: #333333;
	margin-left: 10px;
}

input[type='date']:before {
	color: #808080;
	content: attr(placeholder) !important;
	margin-right: 0.5em;
}

input[type='date']:focus:before {
	content: '' !important;
}

.vendor-content-container {
	margin-top: 30px;
	margin-right: 60px;
	margin-left: 60px;
}

.table-heading {
	background-color: #54f1a1;
	color: #333333;
	text-align: center;
	font-weight: 800;
	width: 90vw;
	/* width: 300vw; */
	/* padding-left: 30px; */
	/* border: 0.5px solid #888888; */
	/* border-collapse: collapse; */
}

.table-data {
	color: #888888;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	border-bottom: 0.5px solid #888888;
	height: 50px;
	/* border-collapse: collapse; */
}

.vendorContainer {
	background-color: #f9f9f9;
}

.vendor-registration-container {
	margin: 30px 120px;
}

.vendor-registration-container > h3 {
	font-weight: 900;
	font-size: 30px;
	color: #333333;
	font-family: 'Avenir';
	border-bottom: 0.5px solid #808080;
	padding: 10px;
}

.custom-send-btn {
	border: none;
	outline: none;
	background: #33d7db;
	border-radius: 5px;
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: #333333;
	font-size: 12px;
	font-family: 'Avenir';
	font-weight: 400;
	margin-left: 74vw;
}

.vendor-form {
	margin-top: 50px;
	margin: 0px 200px;
	width: 50vw;
}

.vendor-form > h3 {
	margin-top: 10px;
	font-weight: 900;
	font-size: 21px;
	color: #333333;
	font-family: 'Avenir';
	border-bottom: 0.5px solid #808080;
	padding: 5px;
}

.vendor-form-section {
	display: flex;
	height: 50px;
	justify-content: space-between;
	gap: 120px;
	margin: 30px 0px;
}

.vendor-input {
	background: #f9f9f9 !important;
}

.vendor-input input {
	background: #f9f9f9;
	width: 250px;
}

.custom-question {
	font-family: 'Avenir';
	font-weight: 900;
	font-size: 21px;
	color: #333333;
	margin-bottom: 30px;
}

.question-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 210px;
	height: 45px;
	background: #5efba1;
	border-radius: 5px;
	color: #333333;
	font-weight: 500;
	font-size: 16px;
	font-family: 'League Spartan';
	border: none;
	outline: none;
}

.add-vendor-btn {
	display: flex;
	flex-direction: row-reverse;
	margin-right: 10px;
	margin-bottom: 30px;
}

.add-vendor-btn > button {
	width: 150px;
	height: 45px;
	margin-top: -30px;
	background: #33d7db;
	border-radius: 5px;
	color: #333333;
	font-weight: 500;
	font-size: 16px;
	font-family: 'League Spartan';
	border: none;
	outline: none;
}

.vendor-btn {
	margin-top: 30px;
	margin-left: 3vw;
	font-family: 'League Spartan';
}

.vendor-modal-container {
	width: 50vw;
	margin: 30px 200px;
	min-height: 80vh;
}

.templateContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.5px solid #333333;
}

.templateContainer > h3 {
	font-weight: 800;
	font-family: 'Avenir';
	font-size: 21px;
	color: #333333;
	padding: 10px;
}

.templateContainer > h5 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 14px;
	color: #33d7db;
	cursor: pointer;
}

.custom-btn {
	font-family: 'League Spartan';
	margin-left: 0px;
}

.questionFieldContainer {
	margin-top: 30px;
	min-height: 175px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-top: 15px solid #33d7db;
	border-bottom: 0.5px solid #808080;
	border-left: 0.5px solid #808080;
	border-right: 0.5px solid #808080;
	border-radius: 5px;
	background: #ffffff;
	box-shadow: 5px 5px 14px 4px rgba(136, 136, 136, 0.1);
	z-index: -10;
}

.question-container {
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-left: 30px;
}

.topSection {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.topSection span {
	position: absolute;
	top: -42px;
	left: -40.5px;
	/* margin-top: -42px; */
	margin-bottom: 34px;
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	background: #33d7db;
	border-radius: 5px;
	/* margin-left: -40.5px; */
	width: 150px;
	padding: 5px 10px;
	/* z-index: 10; */
}

.moveIcons {
	display: flex;
	position: absolute;
	right: 0;
	top: -42px;
	gap: 5px;
	z-index: 30;
}

.question-container input {
	border: none;
	outline: none;
	border-bottom: 0.5px solid #555555;
	width: 282px;
	padding: 10px;
	color: #555555;
}

.question-container textarea {
	border: none;
	outline: none;
	border-bottom: 0.5px solid #555555;
	width: 282px;
	text-align: justify;
	padding: 10px;
	color: #555555;
}

.question-container h6 {
	border-bottom: 0.5px solid #555555;
	width: 381px;
	padding: 10px;
	color: #555555;
	margin-top: 10px;
	cursor: pointer;
}

.action-container {
	display: flex;
	flex-direction: column;
	/* align-items: flex-end; */
	justify-content: space-around;
}

/* .select-container {
  padding: 10px;
  margin-right: 30px;
  background: #ffffff;
  border: 0.5px solid #888888;
  border-radius: 3px;
}
.select-container > select {
  border: none;
  outline: none;
} */
.icons-container {
	margin-left: 18px;
	display: flex;
	gap: 10px;
	font-size: 21px;
	cursor: pointer;
	margin-top: 21px;
}

.dropdown-container {
	position: relative;
}

.dropdown-questions {
	position: absolute;
	background: #ffffff;
	box-shadow: -4px -4px 12px rgba(170, 170, 170, 0.15), 4px 4px 12px rgba(136, 136, 136, 0.15);
	border-radius: 3px;
	width: 180px;
	padding: 10px;
	margin: 21px -160px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	z-index: 10;
}

.dropdown-questions .each-question {
	display: flex;
	gap: 10px;
}

.icons-container-section {
	padding: 10px;
	margin-right: 30px;
	display: flex;
	gap: 10px;
	background: #ffffff;
	border-radius: 3px;
	margin-top: 30px;
	font-size: 21px;
	cursor: pointer;
}

.upload-container {
	padding: 10px;
	margin-left: 30px;
}

.upload-container textarea {
	border: none;
	outline: none;
	border-bottom: 0.5px solid #555555;
	width: 350px;
	padding: 10px;
	color: #555555;
}

.upload-container p {
	font-family: 'League Spartan';
	font-size: 12px;
	font-weight: 300;
	color: #555555;
	margin-top: 10px;
}

.upload-icons {
	margin-top: 50px;
	margin-right: 30px;
}

.select > h6 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 35px;
	background: #ffffff;
	border: 0.5px solid #888888;
	border-radius: 3px;
	padding: 5px;
	margin-right: 10px;
	font-family: 'Avenir';
	font-weight: 400;
	font-size: 14px;
	position: relative;
}

.question-type {
	/* width: 150px; */
	/* height: 62px; */
	background: #ffffff;
	border: 0.25px solid #888888;
	box-shadow: 0px 6px 10px rgba(136, 136, 136, 0.15);
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	position: absolute;
	margin: -10px 0px;
	border-top: none;
	cursor: pointer;
}

.question-type h6 {
	font-family: 'Avenir';
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}

.optionsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0px;
	padding: 0;
}

.dropDownOptions {
	display: flex;
	text-align: justify;
}

.choice-btn {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.choice-btn label {
	display: flex;
	align-items: center;
	gap: 15px;
}

.radio-btn {
	border: 0.5px solid #329c9e;
}

.share-modal-container {
	width: 750px;
	/* height: 163px; */
	background: #ffffff;
	box-shadow: 4px 4px 12px rgba(136, 136, 136, 0.15), -4px -4px 12px rgba(136, 136, 136, 0.15);
	border-radius: 12px;
	padding: 30px;
	/* margin: 10px 30px; */
}

.share-modal-container h3 {
	font-family: 'Avenir';
	font-weight: 900;
	font-size: 21px;
	color: #333333;
	border-bottom: 0.5px solid #333333;
	padding: 5px;
}

.share-link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
}

.share-link p {
	border-bottom: 0.5px solid #333333;
	padding: 5px;
}

.copy-send-btn {
	border: none;
	outline: none;
	background: #33d7db;
	border-radius: 5px;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: #333333;
	font-size: 12px;
	font-family: 'Avenir';
	font-weight: 500;
}

.responseModalContainer {
	/* min-width: 600px; */
	/* min-height: 100vh; */
	overflow-y: scroll;
}

.vendorBasicInfo {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.vendorCompanyDetails {
	display: flex;
	flex-direction: column;
}

.vendorCompanyDetails h3 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 30px;
	text-align: center;
	color: #333333;
}

.vendorCompanyDetails h5 {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 21px;
	text-align: center;
	color: #888888;
}

.vendorContantInfo {
	background: #e3fdfe;
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	gap: 50px;
	padding: 21px 30px;
	align-items: center;
	margin-left: 30px;
	margin-right: 30px;
}

.vendorContantInfo h3 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 21px;
	line-height: 19px;
	color: #333333;
	display: flex;
	align-items: center;
	gap: 10px;
}

.vendorEmailInfo {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-left: 60px;
}

.vendorEmailInfo h3 {
	display: flex;
	align-items: center;
	gap: 30px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 21px;
	color: #333333;
}

.vendorCustomInfo {
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-left: 30px;
	margin-right: 30px;
}

.vendorCustomInfo > h3 {
	margin-top: 30px;
	background: #33d7db;
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 21px;
	color: #333333;
	padding: 10px;
	padding-left: 30px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.vendorLongAns {
	display: flex;
	flex-direction: column;
	gap: 30px;
	border-bottom: 0.5px solid #888888;
}

.question {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 21px;
	color: #333333;
}

.answer {
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 21px;
	color: #333333;
	/* padding: 4px; */
}

.vendorMCQ {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.vendorMCQ .mcq {
	display: flex;
	gap: 30px;
	min-width: 60px;
	text-align: center;
	border: 1px solid #0000;
}

.checkedAnswer {
	background: #33d7db;
	padding: 5px 10px;
	border-radius: 5px;
}

.uncheckedAnswer {
	background: #fff;
	padding: 5px 10px;
	border: 0.5px solid #888888;
	border-radius: 5px;
}

.vendorUpload {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.vendorUpload button {
	background: #33d7db;
	border-radius: 5px;
	width: 100px;
	border: none;
	outline: none;
	padding: 5px;
}

.optionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.optionContainer .optionBtn {
	border: none;
	outline: none;
	background: #33d7db;
	border-radius: 5px;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 12px;
	padding-bottom: 12px;
	color: #333333;
	font-size: 12px;
	font-family: 'Avenir';
	font-weight: 500;
}

.addComponentBtn {
	margin-top: 10px;
	border: none;
	outline: none;
	background: #33d7db;
	border-radius: 5px;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 12px;
	padding-bottom: 12px;
	color: #333333;
	font-size: 12px;
	font-family: 'Avenir';
	font-weight: 500;
}

.vendor_footer_container {
	display: flex;
	/* flex-direction: row-reverse; */
	position: relative;
}

.vendor_sub_footer {
	position: absolute;
	display: flex;
	flex-direction: row;
	gap: 10px;
	right: 10px;
	top: -30px;
}

.vendor_question_container {
	display: flex;
	flex-direction: row;
	margin: 20px;
	justify-content: space-around;
}

.left_vendor_question {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.left_vendor_question textarea {
	border: none;
	outline: none;
	border-bottom: 0.5px solid #555555;
	width: 282px;
	padding: 10px;
	color: #555555;
}

.left_vendor_question input {
	border: none;
	outline: none;
	border-bottom: 0.5px solid #555555;
	width: 282px;
	padding: 10px;
	color: #555555;
}

.question_selector_container {
	cursor: pointer;
	text-align: center;
	width: 10rem;
}

.question_type {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 35px;
	background: #ffffff;
	border: 0.5px solid #888888;
	border-radius: 3px;
	/* padding: 5px; */
	margin-right: 10px;
	font-family: 'Avenir';
	font-weight: 400;
	font-size: 14px;
	position: relative;
}

.question_type_options {
	/* width: 150px; */
	background: #ffffff;
	border-bottom: 0.5px solid #888888;
	border-right: 0.5px solid #888888;
	border-left: 0.5px solid #888888;
	border-radius: 3px;
	padding: 5px;
	margin-right: 10px;
	font-family: 'Avenir';
	font-weight: 400;
	font-size: 14px;
}

.question_type_options div {
	display: flex;
	align-items: center;
	/* text-align: start; */
	padding: 3px;
}

.mcq_container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.mcq_container div {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: flex-end;
}

.mcq_answer_container {
	display: flex;
	align-items: center;
	/* margin-top: 10px; */
}

.radioField {
	margin-bottom: 10px;
}

.sendModalContainer {
	/* min-width: 60%; */
	background: #ffffff;
	margin: 10px 48px;
	/* box-shadow: 8px 8px 21px 6px rgba(136, 136, 136, 0.15); */
}

.sendModalContainer > h3 {
	font-family: 'Avenir';
	font-weight: 900;
	font-size: 21px;
	color: #333333;
	padding: 5px;
	border-bottom: 0.5px solid #333333;
}

.sendMailContainer {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.sendTo {
	display: flex;
	gap: 10px;
	align-items: center;
}

.sendTo div {
	/* border-bottom: 0.5px solid #888888; */
	width: 400px;
	margin-bottom: 30px;
}

.sendTo div .senderMail {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: none;
	margin-bottom: 5px;
	color: #555555;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 14px;
	background: #f3f3f3;
}

.autoCompleteContainer {
	display: flex;
	gap: 10px;
	margin-bottom: -30px;
}

.autoCompleteContainer > h3 {
	font-family: 'Avenir';
	font-weight: 900;
	font-size: 14px;
	color: #333333;
	align-self: center;
	margin-top: 10px;
}

.autoCompleteContainer > .autoCompleteInput {
	border: none;
	outline: none;
}

/* .autoCompleteInput {
  background: #f3f3f3;
  border-radius: 3px;
  height: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  margin-bottom: 5px;
  color: #555555;
  font-family: "League Spartan";
  font-weight: 400;
  font-size: 14px;
  background: #f3f3f3;
} */
.sendTo > input[value] {
	background: #f3f3f3;
	border-radius: 3px;
}

.noteContainer {
	display: flex;
	gap: 10px;
}

.noteContainer > textarea {
	background: #f3f3f3;
	border-radius: 3px;
	border: 0.25px solid #888888;
	margin-left: 30px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 12px;
	color: #aaaaaa;
	width: 350px;
	height: 75px;
	padding: 5px;
}

.sendMailBtn {
	display: flex;
	align-items: flex-end;
	background: #33d7db;
	border-radius: 5px;
	padding: 3px 10px;
	margin-top: 40px;
	cursor: pointer;
	gap: 3px;
}

.sendMailBtn > h5 {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	color: #333333;
}

/* .sendMailContainer > h5 {
  overflow: hidden;
  text-align: center;
} */
.sendMailContainer > h5 {
	width: 100%;
	text-align: center;
	border-bottom: 0.5px solid #555555;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

.sendMailContainer > h5 span {
	background: #fff;
	padding: 0 10px;
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 14px;
	color: #555555;
}

.sendLink {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.sendLink input {
	border-bottom: 0.5px solid #888888;
	border-top: none;
	border-right: none;
	border-left: none;
	width: 473px;
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 14px;
	color: #555555;
}

.sendLink > button {
	background: #33d7db;
	border-radius: 5px;
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 12px;
	color: #333333;
	padding: 10px;
	cursor: pointer;
	border: none;
	outline: none;
	width: 100px;
}

.sendLink button:active {
	transform: scale(0.98);
	box-shadow: 3px 2px 22px 1px #33d7db;
}

.customErrorMessage {
	color: #c64343;
}

.delete-employee-modal {
	padding: 20px 40px;
	width: 300px;
}

.delete-employee-modal > .actions {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.delete-employee-modal > .actions > .submit {
	background-color: #c64343;
	color: white;
	font-weight: bold;
}

.delete-employee-modal > .actions > .cancel {
	border: 1px solid #3f3f3f;
}

.delete-employee-modal > .actions > button {
	width: 45%;
	background-color: transparent;
	padding: 10px;
	border: 0px;
	border-radius: 5px;
}

.agent-tab .agent-tab-bar {
	width: 171px;
	height: 603px;
	background: #f6f6f6;
	border-radius: 8px;
	padding: 0 10px;
}

.agent-tab-bar .ant-tabs .ant-tabs-tab {
	width: 150px;
	height: 26px;
	border-radius: 3px;
	text-align: left;
}

.agent-tab-bar .ant-tabs-nav .ant-tabs-tab-active {
	background: #affcfe !important;
}

.agent-tab-bar .ant-tabs-left > .ant-tabs-content-holder {
	border: none !important;
}

.agent-tab-bar .ant-tabs > .ant-tabs-nav {
	margin-top: 20px;
}

.agent-options {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	color: #333333;
	width: 140px;
	padding-left: 10px;
}

.agent-tab {
	padding: 28px 61px;
}

.agent-overview {
	width: 1000px;
	margin-left: 50px;
}

.agent-overview .heading {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 54px;
}

.overview-div .overview-upperdiv {
	display: flex;
	gap: 24px;
}

.overview-div .overview-upperdiv .card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 276px;
	height: 261px;
	background: #ffffff;
	border: 0.25px solid #999999;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 14px;
}

.overview-div .overview-upperdiv .card Button {
	width: 200px;
	height: 45px;
	background: #33d7db;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 22px;
	color: #333333;
}

.overview-div .overview-upperdiv .card Button:hover {
	background-color: #33d7db;
	color: black;
}

.overview-div .overview-upperdiv .card .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	color: #333333;
	position: relative;
}

.overview-div .overview-upperdiv .card .overview-icon {
	font-size: 60px;
}

.overview-lowerdiv {
	display: flex;
	gap: 24px;
	margin-top: 24px;
}

.overview-lowerdiv .card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 276px;
	height: 141px;
	background: #ffffff;
	border: 0.25px solid #999999;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
	text-align: center;
	cursor: pointer;
}

.overview-lowerdiv .card .flex-div {
	display: flex;
	justify-content: space-around;
}

.overview-lowerdiv .card .overview-icon {
	font-size: 50px;
}

.overview-lowerdiv .card .val {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 72px;
	line-height: 66px;
	color: #33d7db;
}

.overview-lowerdiv .card .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 19px;
	color: #333333;
}

.agent-computers {
	width: 881px;
	margin-left: 24px;
}

.agent-computers .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #000000;
}

.agent-computers .ant-table-thead .ant-table-cell {
	background: #e3fafb !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #555555;
}

.agent-computers .ant-table-wrapper .ant-table-tbody > tr > td {
	height: 60px;
}

.agent-computers
	.ant-table-wrapper
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	height: 28px;
	border: 1px solid #b7b7b7;
}

.agent-computers .icon-bar {
	display: flex;
	justify-content: right;
	padding-right: 50px;
	gap: 20px;
	margin: 20px 0;
}

.agent-computers .icon-bar .search-input .ant-input-affix-wrapper {
	border-radius: 10px;
	width: 215px;
	height: 30px;
}

.agent-computers .icon-bar .export-icon {
	font-size: 22px;
	cursor: pointer;
}

.agent-tab .ant-tabs-ink-bar {
	background-color: transparent !important;
}

.agent-drawer {
	padding-top: 20px;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	gap: 50px;
}

.agent-drawer .div1 {
	display: flex;
	align-items: center;
	gap: 50px;
}

.agent-drawer .div1 .text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: #333333;
}

.agent-drawer .div1 .icon-div {
	font-size: 30px;
}

.agent-drawer .div1 Button {
	width: 133px;
	height: 30px;
	background: #33d7db;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #333333;
}

.agent-drawer .div1 Button:hover {
	background-color: #33d7db !important;
	color: black !important;
}

.agent-drawer .div2 {
	display: flex;
	gap: 48px;
}

.agent-drawer .div2 .text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 17px;
	color: #333333;
	margin-top: 10px;
}

.agent-drawer .div2 Input {
	width: 314px;
	height: 40px;
	border: 0.5px solid #888888;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: #555555;
	text-overflow: ellipsis;
}

.agent-drawer .div2 .ant-input-group-wrapper-outlined .ant-input-group-addon:last-child {
	background-color: #33d7db;
	font-size: 20px;
}

.agent-drawer .div2 .ant-input-group .ant-input-group-addon {
	cursor: pointer;
	color: white;
}

.agent-drawer .div2 .innerdiv {
	align-items: center;
	margin-top: 15px;
	display: flex;
	gap: 10px;
}

.agent-drawer .div2 .innerdiv .icon-div {
	font-size: 20px;
}

.agent-drawer .div2 .download-text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #000000;
}

.agent-drawer .div2 .download-text span {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #33d7db;
	margin-left: 10px;
	cursor: pointer;
}

.agent-drawer .div3 {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	color: #333333;
}

.agent-drawer .div3 .innerdiv {
	margin-left: 20px;
	margin-top: 20px;
}

.agent-drawer .div4 .btn-div {
	display: flex;
	justify-content: right;
	gap: 20px;
	padding-right: 10px;
}

.master-setup .heading-div button {
	margin-right: 100px;
}

.ant-drawer-close {
	position: absolute;
	right: 10px;
}

.account-settings .master-setup .tag-and-currency .ant-select.ant-select-in-form-item {
	width: 200px;
}

.employee-setup-container .employee-search-container Input {
	width: 400px;
}

.roles-step-div {
	padding: 25px 10px;
}

.rotate-default {
	/* transform: rotate(0deg); */
	transition: transform 500ms linear;
}
.rotate-open {
	/* transform: rotate(360deg); */
	transition: transform 500ms linear;
}

@media only screen and (max-width: 800px) {
	.admin-setup-roles {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.account-settings > .accounts-icon > .content > .user-info {
		width: 300px;
	}

	.master-setup .heading-div button {
		margin-right: 0px;
	}

	.master-setup {
		max-width: 100%;
	}

	.employee-setup-container .employee-search-container .btns-div {
		justify-content: space-around;
	}

	.employee-setup-container .employee-search-container Input {
		width: 300px;
	}

	.employee-setup-container .employee-search-container .ant-input-group-wrapper {
		width: unset;
	}

	.employee-setup-container .employee-search-container {
		flex-direction: column;
		gap: 30px;
	}

	.agent-tab {
		padding: 5px 5px;
	}

	.agent-overview {
		margin-left: 10px;
	}

	.admin-setup-container {
		margin-top: 0;
	}

	.employee-setup-innerdiv,
	.admin-setup-innerdiv {
		width: 100%;
	}

	.account-settings-tabs {
		padding: 13px;
	}

	.account-settings > .accounts-icon > .content > .handle-change-password .input-container {
		display: flex;
		flex-direction: column;
		width: 80%;
		margin: auto;
	}

	.account-settings > .accounts-icon > .content > .handle-change-password {
		width: 80%;
	}

	.account-settings .master-setup .ant-input {
		width: 100%;
	}

	.master-setup > .tag-and-currency,
	.master-setup .deprecValue-and-value,
	.master-setup .office-details {
		gap: 20px;
		flex-direction: column;
	}

	.account-settings .master-setup > .tag-and-currency,
	.account-settings .master-setup > .office-details,
	.account-settings .master-setup > .deprecValue-and-value {
		padding-top: 20px;
		width: 100%;
	}

	.account-settings .master-setup .tag-and-currency .ant-select.ant-select-in-form-item {
		width: 100%;
	}

	.companyInfo-action-button {
		width: 100%;
	}

	.header-container {
		padding: 0 15px;
	}

	.account-page-title {
		padding-bottom: 5px;
		padding-top: 0px;
	}

	.master-asset-div .size-div {
		width: 100%;
	}

	.admin-setup-container {
		padding: 5px;
		margin-bottom: 20px;
	}

	.admin-setup-header {
		margin-top: 20px;
	}
}
