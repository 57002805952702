.getstarted-header {
	background-color: #3fd7da;
	display: flex;
	flex: 1;
	justify-content: space-between;
	padding-top: 15px;
	padding-bottom: 15px;
}

.getstarted-header > .heading {
	font-size: 36px;
	font-weight: 800;
	color: #ffffff;
}

.icon {
	color: #ffffff;
	width: 25px;
	height: 25px;
	padding-top: 5px;
	padding-left: 30px;
	padding-right: 20px;
}

.dot-icon {
	color: #ffffff;
	width: 25px;
	height: 25px;
	padding-top: 5px;
	padding-left: 80px;
}
