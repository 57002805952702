.unassign__button {
    width: 150px;
    height: 40px;
    border: none;
    outline: none;
    background: #33d7db;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Avenir';
    font-weight: 500;
}

.modal__button__no {
    color: '#333333';
    background-color: '#33D7DB';
    width: '105px';
    height: '35px';
    outline: 'none';
    border: 'none';
    border-radius: '5px';
}
