.timeline {
	margin-top: 30px;
	display: flex;
	margin-left: 30px;
}

.mydevices-container > div > .status {
	background: #cffee6;
	border-radius: 5px;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 55px;
	padding: 16px 16%;

	font-size: 18px;
	font-weight: 900;
	color: #555555;
}
.mydevices-container > div > .left {
	display: flex;
	flex-direction: column;
}

.left > hr {
	width: 50px;
	margin-top: 20px;
	margin-bottom: 25px;
	border: 1px solid #33d7da;
	transform: rotate(90deg);
}

.left > .active {
	color: #33d7da;
	width: 30px;
	height: 30px;
}
.right {
	text-align: left;
}

.status1 {
	font-size: 18px;
	font-weight: 500;
	color: #333333;
}

.timestamp {
	font-size: 14px;
	font-weight: 500;
	color: #888888;
}
.right > .st1,
.right > .st2 {
	padding-bottom: 30px;
}

.st2 > .status1 {
	font-size: 18px;
	font-weight: 500;
	color: #aaaaaa;
}

.st2 > .timestamp {
	font-size: 14px;
	font-weight: 500;
	color: #cccccc;
}
