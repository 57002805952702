.register-container {
	text-align: center;
	overflow-x: hidden;
}

.register-container > .logo {
	margin-top: 30px;
}

.register-container > .tagline {
	margin-top: 30px;
	font-size: 25px;
	font-weight: 400;
	color: #333333;
}

.register-container > .inputs {
	display: flex;
	flex-direction: column;
	padding-top: 30px;
}

.register-container > .inputs > input {
	background: #ffffff;
	border: 0.5px solid #808080;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 30px;
	margin-left: 30px;
	margin-right: 30px;

	color: #a0a0a0;
	font-size: 10px;
	font-weight: 400;

	padding-top: 20px;
	padding-bottom: 15px;
	padding-left: 20px;
}

.register-container > button {
	border: none;
	background: linear-gradient(90deg, #55fc9d 0%, #54f1a1 100%);
	border-radius: 5px;

	font-size: 20px;
	font-weight: 500;
	color: #333333;
	padding: 10px 50px;

	margin-top: 40px;
	margin-left: 30px;
	margin-right: 30px;
}

.register-container > hr {
	margin-top: 30px;
	width: 75%;
}
