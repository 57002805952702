.partner-access-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 50px 0px 50px 0px;
	color: #333;
	margin-top: 120px;
}

.partner-access-container > h1 {
	font-size: 32px;
}

.partner-access-container > .head-description {
	max-width: 50%;
	text-align: center;
}

.partner-content-1 {
	padding: 20px 5% 50px 5%;
	color: #333;
	background-color: #f9f9f9f9;
	text-align: center;
}

.partner-content-1 > h2 {
	font-size: 32px;
	margin-bottom: 30px;
}

.partner-info-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.partner-info-container > .each-partner-info {
	width: 25%;
}
.partner-info-container > .each-partner-info > .head {
	font-size: 20px;
	margin-bottom: 15px;
	font-weight: 800;
	margin-top: 15px;
}

.white-bg.partner-content-1 {
	background-color: white;
}

.partner-content-2 {
	background-color: #f9f9f9;
	padding: 40px 10% 40px 5%;
	color: #333;
}

.partner-content-2 > .head {
	font-size: 18px;
	margin-bottom: 24px;
	font-weight: 800;
}

.partner-content-2 > .description {
}

@media only screen and (max-width: 600px) {
	.partner-access-container {
		padding-top: 13vh;
	}

	.partner-info-container {
		flex-direction: column;
	}
	.partner-info-container > .each-partner-info {
		width: 100%;
		max-width: 100%;
		margin-bottom: 40px;
	}
	.partner-content-1 {
		padding: 20px 0% 50px 0%;
		max-width: 100%;
	}
	.partner-info-container > .each-partner-info > .description {
		width: 85%;
		margin: 0 auto;
	}
}
