.feedback-container > .header {
	background: #ffffff;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 100px;
	box-shadow: 0px 4px 12px 4px rgba(136, 136, 136, 0.1);
}

.title {
	font-size: 36px;
	font-weight: 500;
	color: #333333;
	margin-top: 95px;
}

.body {
	margin-left: 200px;
	margin-bottom: 100px;
}

.body > .qn {
	font-size: 21px;
	font-weight: 400;
	color: #333333;

	margin-top: 70px;
	margin-bottom: 15px;
}

.options {
	margin-top: 20px;
}

.options > .each-option {
	display: flex;
	margin-top: 10px;
}

.opinion {
	font-size: 21px;
	font-weight: 400;
	color: #555555;
	margin-left: 20px;
}

.body > .answer {
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
	border-radius: 5px;

	width: 500px;
	height: 162px;

	margin-bottom: 20px;
}

.body > .email {
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 10px;
	width: 500px;
	height: 50px;
}

.name > .first-name,
.name > .last-name {
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
	border-radius: 5px;
	width: 200px;
	height: 50px;
	margin-right: 50px;

	padding-left: 10px;
}

.follow-up {
	display: flex;
}

.follow-up > .ans1,
.follow-up > .ans2 {
	display: flex;
	margin-right: 50px;
}

.body > button {
	background-color: #33d7db;
	border-radius: 5px;
	border: none;
	color: #ffffff;
	font-size: 21px;
	font-weight: 400;
	margin-top: 50px;
	padding: 11px 60px;
}

.fill {
	color: #33d7db;
}
