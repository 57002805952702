.incidents-container {
	margin: 0 2.5%;
	/* background-color: white; */
}

.incidents-container>.add-filter-container {
	background-color: #f4f4f4;
	padding: 10px 5%;
}

.incidents-container>.add-filter-container>button {
	background-color: #3fd7da;
	border: 0px;
	padding: 10px 20px;
	border-radius: 5px;
	font-weight: 500;
}

.incidents-container-list {
	background-color: white;
}

.each-incident-container {
	padding: 20px 20px 20px 5%;
	border-bottom: 1px solid #333;
}

.incident-short {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.each-incident-container>.incident-short>.each-incident-info {
	display: flex;
	align-items: flex-start;
	max-width: 60%;
}

.each-incident-container>.incident-short>.each-incident-info>.logo-container {
	width: 60px;
	height: 60px;
	margin-right: 20px;
	border-radius: 50%;
	background-color: #3fd7da;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	color: #333;
}

.each-incident-container>.incident-short>.each-incident-info>.logo-container:nth-child(1) {
	background-color: #4285f4;
}

.each-incident-container>.incident-short>.each-incident-info>.logo-container:nth-child(2) {
	background-color: #ea4335;
}

.each-incident-container>.incident-short>.each-incident-info>.logo-container:nth-child(3) {
	background-color: #f7bd45;
}

.each-incident-container>.incident-short>.each-incident-info>.logo-container:nth-child(4) {
	background-color: #4ca954;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.error-message {
	/* font-size: 18px; */
	color: #333333;
	/* text-transform: capitalize; */
	font-weight: 800;
	/* max-width: 60%; */
	/* padding-left: 10px; */
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.name-container {
	display: flex;
	align-items: center;
	color: #888;
	font-weight: 500;
	font-size: 14px;
	padding-top: 10px;
	padding-bottom: 5px;
	/* text-transform: capitalize; */
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.name-container>div:first-child {
	margin-right: 25px;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.name-container>div {
	display: flex;
	align-items: center;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.name-container>div>svg {
	margin-right: 5px;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.priority-container {
	display: flex;
	margin-top: 10px;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.priority-container>div:first-child {
	margin-right: 20px;
}

.each-incident-container>.incident-short>.each-incident-info>.each-incident-data>.priority-container>.asset-name {
	font-weight: 800;
	color: #333;
}

.priority-container>.high {
	color: red;
	text-transform: capitalize;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.priority-container>.medium {
	color: #f5a710;
	text-transform: capitalize;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.each-incident-action {
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	width: 400px;
}

.each-incident-action>div:first-child {
	margin-right: 20px;
}

.each-incident-container:last-child {
	border-bottom: 0px;
}

.incidentinfo-container {
	padding: 20px;
}

.incidentinfo-container>.name-container>div {
	font-size: 18px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}

.incidentinfo-container>.name-container>div>svg {
	margin-right: 10px;
}

.conversation-container {
	background-color: #f9fbfc;
	height: 500px;
	max-height: 500px;
	margin-top: 20px;
	border-radius: 10px;
	width: 500px;
	margin: 0 auto;
	margin-top: 40px;
	padding: 20px 20px 0px 20px;
}

.conversation-container>.content {
	height: 440px;
	overflow-y: scroll;
}

.conversation-container>.reply-content {
	height: 60px;
	border-top: 1px solid #3fd7da;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding-right: 20px;
	background-color: #f9fbfc;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.conversation-container>.reply-content>input {
	border: 0px;
	display: flex;
	flex: 1;
	padding-left: 20px;
	height: 58px;
	background-color: #f9fbfc;
}

.conversation-container>.reply-content>button {
	border: 0px;
	height: 30px;
	padding: 0 20px;
	background-color: transparent;
	border: 2px solid #3fd7da;
	border-radius: 40px;
}

.each-message-container {
	margin-bottom: 10px;
}

.each-message-container>div {
	border-radius: 10px;
}

.employee-message {
	background-color: #4285f425;
	padding: 20px;
	margin-right: auto;
	width: 300px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.admin-message {
	background-color: #f7bd4540;
	padding: 20px;
	margin-left: auto;
	width: 300px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.admin-message>.date-message,
.employee-message>.date-message {
	font-size: 12px;
	margin-top: 20px;
}

.downward-arrow {
	color: #e0e0e0;
	margin-left: 10px;
}

.toggle-show-container {
	padding: 20px 20px 20px 5%;
}

.toggle-show-container>.comment-insight {
	display: flex;
	justify-content: space-between;
}

.comment>.heading,
.admin-insight>.heading,
.history>.heading {
	font-family: 'Avenir';
	font-size: 18px;
	font-weight: 800;
	color: #555555;
}

.history {
	margin-top: 18px;
}

.toggle-show-container .comment textarea,
.toggle-show-container .admin-insight textarea {
	box-sizing: border-box;
	border: 0.5px solid #aaaaaa;
	border-radius: 5px;

	font-family: 'Avenir';
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #555555;
	width: 31vw;
	padding: 10px;
	margin-top: 10px;
}

.timestamp,
.issues,
.user {
	display: flex;
	margin-bottom: 8px;
	align-items: center;
}

.incident-timeline>div {
	width: 400px;
}

.each-issue>.open-in-new {
	display: flex;
	justify-content: space-between;
}

.history>.timeline {
	display: block;
	margin-left: 0px;
}

.each-issue {
	box-sizing: border-box;
	border: 0.3px solid #888888;
	border-radius: 5px;
	padding: 8px 20px;
	width: 31vw;
	margin-top: 20px;
}

.timestamp>div,
.user>div {
	font-size: 9px;
	font-family: 'Avenir';
	font-weight: 400;
	color: #333333;
	padding-left: 8px;
}

.issues>div {
	font-size: 12px;
	font-weight: 400;
	color: #333333;
	font-family: 'Avenir';
	padding-left: 8px;
}

.status-each {
	display: flex;
	align-items: baseline;
	border: none;
	border-radius: 3px;
	background: #5efaa0;
	padding-left: 10px;
	padding-right: 5px;
	padding-top: 5px;
}

.status-each>.resolved {
	font-size: 10px;
	font-family: 'Avenir';
	font-weight: 400;
	color: #333333;
	padding-right: 30px;
	text-transform: capitalize;
}

.status-each>div {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	font-size: 6px;

	color: #333333;
}

.name-status {
	display: flex;
	justify-content: space-between;
}

.each-incident-data {
	padding-left: 10px;
}

.incidents-container>.incident-filter {
	margin-bottom: 20px;
}

.incidents-container>.incident-filter>.filter-select {
	max-width: 400px;
}

.incident-filters-div {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
}

.incident-filters-div .innerdiv {
	display: flex;
	flex-direction: column;
}

.incident-filters-div .heading-div {
	font-family: "League Spartan";
	font-size: 17px;
	margin-bottom: 6px;
}

.incident-filters-div .action-div {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 30px;
}