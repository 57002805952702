.tag-generation-container {
	margin-top: 15px;
	padding-left: 10px;
	padding-right: 10vw;
	display: flex;
	justify-content: left;
}

.tag-generation-container>.input-container {
	width: 40%;
}

.tag-generation-container>.tag-container {
	position: relative;
	width: 50%;
	background: #f5f5f5;
	padding: 30px;
}

.tag-generation-container>.tag-container>.tag-content {
	background-color: white;
	padding: 30px 0px 0px 0px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data {
	display: flex;
	padding: 0px 20px 0px 20px;
	justify-content: space-between;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data>.right-tag>img {
	position: absolute;
	top: 40px;
	right: 50px;
	height: 80px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data>.left-tag>.tag-logo {
	height: 30px;
	margin-bottom: 10px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data>.left-tag>.tag-address {
	font-size: 12px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-info {
	background-color: #ff6347;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
	height: 50px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-info>.tag-id {
	font-size: 24px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-info>.tag-note {
	font-size: 12px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data>.left-tag>.tag-phone {
	font-size: 12px;
}

.tag-generation-container>.tag-container>.tag-content>.tag-data>.left-tag>.tag-name {
	font-size: 12px;
}

.account-settings .invoice-heading-div .asset-tag-head {
	/* text-align: center;
	margin-top: 20px;
	margin-bottom: 40px; */
	font-size: 20px;
	font-family: 'League Spartan';
	font-weight: 600;
	color: rgba(19, 18, 18, 0.838);
}

.account-settings .asset-tag-setup-heading {
	font-size: 20px;
	font-family: 'League Spartan';
	font-weight: 600;
	color: rgba(19, 18, 18, 0.838);
	padding: 15px 10px;

}

.tag-generation-container>.input-container>.validator {
	color: #555;
	font-family: League Spartan;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: right;
	padding-right: 25%;
	margin-bottom: 15px;
}

.po-table .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 13px;
	color: #333333;
	text-transform: capitalize;
}

.po-table .ant-table-thead .ant-table-cell {
	/* text-align: center; */
	background: #FAFAFA !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 13px;
	color: #333333;
}

.invoice-heading-div .search-input .ant-input-affix-wrapper {
	border-radius: 10px;
	width: 215px;
	height: 30px;
}

.tag-generation-container>.input-container>.validator-note {
	color: #515151;
	font-family: Avenir;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	width: 85%;
	margin-top: 40px;
}

/* .tag-generation-container>.input-container>.each-input {
	border-radius: 5px;
	border: 1px solid #9c9e9e;
	background: #f9f9f9;
	padding-top: 10px;
	padding-left: 15px;
	width: 75%;
	margin-bottom: 20px;
	padding-bottom: 10px;
} */

.tag-generation-container .input-container Input,
.tag-generation-container .input-container TextArea {
	border-radius: 5px;
	border: 1px solid #9c9e9e;
	/* background: #f9f9f9; */
	padding-top: 10px;
	padding-left: 15px;
	width: 85%;
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.tag-generation-container>.input-container>.tag-logo-drop {
	width: 75%;
}

.tag-generation-container>.input-container>.each-input>label {
	display: block;
	color: #9dacac;
	font-family: League Spartan;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.tag-generation-container>.input-container>.each-input>label {
	margin-bottom: 2px !important;
}

.account-settings .invoice-heading-div {
	width: 900px;
	display: flex;
	justify-content: space-between;
	padding: 15px 10px;
}

.invoice-heading-div .innerdiv {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* .tag-generation-container>.input-container>.each-input>input {
	color: #555;
	font-family: League Spartan;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border: 0px;
	width: 100%;
	background-color: transparent;
	outline: none;
} */

.tag-generation-container .input-container .dragger-div {
	width: 85%;
	height: 150px;
}

.tag-generation-container .input-container .dragger-div .ant-upload-drag-icon {
	font-size: 30px;
}

.tag-generation-container .input-container .dragger-div .ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
	margin-bottom: 0 !important;
}

.account-settings .asset-tag-setup-actions {
	display: flex;
	justify-content: space-around;
	width: 500px;
	margin: 50px auto;
	/* margin-top: 30px; */
}

.ant-design-save-btn,
.ant-design-cancel-btn {
	width: 150px !important;
	height: 50px !important;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 18px !important;
	color: #333333;
}


.ant-design-cancel-btn:hover {
	color: #33D7DB !important;
	border: 1px solid #33D7DB !important;
}

.ant-design-save-btn {
	background-color: #33D7DB !important;
	border: 1px solid #33D7DB !important;
	color: #333333 !important;
}

.ant-design-save-btn:hover {
	background-color: #33D7DB !important;
	color: black !important;
}

.tag-logo-drop {
	border-radius: 5px;
	border: 0.5px dashed #808080;
	background: #f8f8f8;
	padding-top: 25px;
	padding-bottom: 25px;
	cursor: pointer;
}

.tag-highlight {
	color: #33d7db;
	font-family: Avenir;
	font-size: 18px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	padding-left: 15px;
}

.not-highlish {
	color: #333;
	font-family: Avenir;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 20px;
}

.color-picker-container {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.color-picker-container>.color-picker>img {
	height: 20px;
	cursor: pointer;
}

.color-picker-container>.text-color>.white {
	display: inline-block;
	height: 25px;
	width: 25px;
	border: 1px solid #3f3f3f;
	background-color: white;
	margin-right: 10px;
	border-radius: 5px;
	cursor: pointer;
}

.color-picker-container>.text-color>.black {
	display: inline-block;
	height: 25px;
	width: 25px;
	border: 1px solid #ffffff;
	background-color: black;
	border-radius: 5px;
	cursor: pointer;
}

.color-picker-container>.text-color>.black.active,
.color-picker-container>.text-color>.white.active {
	border: 1px solid #33d7db;
}

.account-settings>.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}

.account-settings>.actions>.cancel {
	border: 0px;
	border-radius: 5px;
	background: #f2f2f2;
	margin-right: 20px;
	padding: 10px 40px 10px 40px;
}

.account-settings>.actions>.save {
	border: 0px;
	border-radius: 5px;
	background: #5efba1;
	margin-left: 20px;
	padding: 10px 40px 10px 40px;
}

@media only screen and (max-width:1000px) {

	.tag-generation-container .input-container {
		width: 40%;
	}

	.tag-generation-container .tag-container {
		width: 50%;
	}

	.tag-generation-container .input-container Input,
	.tag-generation-container .input-container TextArea {
		width: 100%;
	}

	.tag-generation-container .input-container .dragger-div {
		width: 100%;
	}

	.tag-generation-container {
		justify-content: space-between;
		padding-right: 0px;
	}
}

@media only screen and (max-width:700px) {
	.tag-generation-container {
		flex-direction: column;
		padding: 0px 15px;
	}

	.tag-generation-container .input-container,
	.tag-generation-container .tag-container {
		width: 100%;
	}

	.tag-generation-container .input-container Input,
	.tag-generation-container .input-container TextArea {
		width: 100%;
	}

	.tag-generation-container .input-container .dragger-div {
		width: 100%;
	}

	.tag-generation-container>.input-container>.validator-note {
		font-size: 14px;
		margin: 30px 10px;
	}

	.tag-generation-container>.tag-container {
		padding: 10px;
	}

	.tag-generation-container>.tag-container>.tag-content>.tag-data>.right-tag>img {
		right: 40px;
		position: absolute;
		width: 60px;
		height: 60px;
		top: 25px;
	}

	/* .tag-generation-container>.tag-container>.tag-content>.tag-data>.left-tag>.tag-address {
		margin-top: 10px;
		margin-bottom: 6px;
	} */

	.color-picker-container {
		margin-top: 10px;
	}

	.tag-generation-container>.input-container>.validator {
		padding-right: 0;
	}

	.tag-generation-container>.tag-container>.tag-content {
		padding: 15px 0px 0px 0px;
	}

	.tag-generation-container>.tag-container>.tag-content>.tag-info {
		height: 40px;
	}

	.account-settings .po-size-div {
		width: 100%;
	}

	.account-settings .invoice-heading-div {
		width: 100%;
		flex-direction: column;
	}
}