.app-container {
	width: 100%;
}

.app-header {
	display: flex;
	padding: 0 50px;
	height: 60px;
	justify-content: space-between;
	align-items: center;
	background-color: #fafafa;
}

.app-header > .app-header-action {
	display: flex;
	align-items: center;
}

.app-header-action > button {
	margin-right: 20px;
}

.app-header-action > button.price {
	border: 0px;
	background-color: transparent;
	font-weight: 800;
	font-size: 14px;
}

button.early-access,
button.bgradient {
	border: 0px;
	padding: 12px 20px;
	background: linear-gradient(90deg, #0bac9f 0%, #58f5a1 100%);
	border-radius: 5px;
	color: white;
	font-weight: 500;
	font-size: 14px;
}

.app-header-action > button.login {
	border: 0px;
	background-color: transparent;
	color: #52efa1;
	font-weight: 500;
	font-size: 14px;
	border: 1px solid #52efa1;
	border-radius: 5px;
	padding: 5px 20px;
}

.app-container-1 {
	background-color: #f9f9f9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 5%;
}

.app-container-1 > .app-content-1 > .head {
	font-size: 42px;
	color: #09aa9f;
	line-height: 50px;
	font-weight: 800;
}

.app-container-1 > .app-content-1 > .head-2 {
	font-size: 24px;
	line-height: 30px;
	margin-top: 20px;
	/* max-width: 75%; */
}

.app-container-1 > .app-content-1 > .headers-contatiner > .head-2 {
	font-size: 24px;
	line-height: 30px;
	margin-top: 20px;
	max-width: 75%;
}

.app-container-1 > .app-content-1 > .headers-contatiner {
	border-left: 2px solid #09aa9f;
	padding-left: 20px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.app-container-1 > .app-content-1 > .headers-contatiner > .head-3 {
	font-size: 18px;
	margin-top: 10px;
	max-width: 75%;
}

.app-container-1 > .app-content-1 > .actions {
	margin-top: 20px;
}

.app-container-1 > .app-content-1 > .actions > button {
	margin-right: 20px;
}

button.bno-gradient {
	border: 0px;
	background-color: transparent;
	color: #000;
	font-weight: 500;
	font-size: 14px;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 12px 20px;
}

.app-container-2 {
	margin-top: 40px;
	background-color: #f9f9f9;
	text-align: center;
	padding: 30px 5%;
}
.app-container-2 > .head-1 {
	/* font-size: 40px; */
}

.app-container-2 > .head-2 {
	font-size: 20px;
	margin-bottom: 20px;
	margin-top: -10px;
}

.app-container-2 > .app-content-1,
.app-container-3 > .app-content-1,
.app-container-3 > .app-content-2 {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: 40px;
}

.app-container-2 > .app-content-1 > .each-assest-content {
	width: 25%;
}

.each-assest-content > .image-container {
	margin-bottom: 20px;
}

.each-assest-content > .image-container > img {
	height: 150px;
	width: 150px;
	object-fit: contain;
}

.each-assest-content > .text-container {
	margin-bottom: 20px;
	font-weight: 500;
	margin-top: 20px;
}

.app-container-3 {
	background-color: #f9f9f9;
	margin-top: 40px;
	text-align: center;
	padding: 30px 5%;
}

.app-container-4 {
	background-color: #54f2b1;
	text-align: center;
	padding: 40px 5% 20px 5%;
	color: white;
}

.app-container-4 > .head-1 {
	font-weight: 900;
	font-size: 30px;
	line-height: 41px;
}

.app-container-4 > .actions {
	margin-bottom: 20px;
}

.app-container-4 > .actions > button {
	margin-right: 20px;
}

.app-container-4 > .actions > .early-access {
	border: 0px;
	padding: 5px 20px;
	background-color: #fff;
	border-radius: 5px;
	color: black;
	font-weight: 500;
	font-size: 14px;
}

.app-container-4 > .actions > .request-demo {
	background-color: transparent;
	border: 0px;
	padding: 5px 20px;
	border-radius: 5px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	border: 1px solid #fff;
}

.app-container-4 > .copyright {
	font-size: 12px;
	font-weight: 800;
}

@media only screen and (max-width: 600px) {
	.app-header > .app-header-action {
		display: none !important;
	}

	.app-container-1 > .image-container {
		display: none !important;
	}
	.app-container-1 > .app-content-1 {
		text-align: center;
	}
	.app-container-2 > .app-content-1 {
		flex-direction: column;
	}
	.app-container-2 > .app-content-1 > .each-assest-content {
		width: 100%;
	}
	.app-container-2 > .app-content-1 > .each-assest-content > .image-container > img {
		width: 100px;
	}
	.app-container-3 > .app-content-1,
	.app-container-3 > .app-content-2 {
		flex-direction: column;
	}
	.each-assest-content > .image-container > img {
		width: 120px;
	}
	.app-header {
		padding: 0 20px;
	}
}

.landing-page-design,
.landing-page-design.mbl {
	background: url(https://assetze.s3.ap-south-1.amazonaws.com/bg-1.png);
	height: 65vh;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.landing-page-design > h1,
.landing-page-design.mbl > h1 {
	color: #333333;
}

.landing-page-design > h4,
.landing-page-design.mbl > h4 {
	color: #333333;
}

.landing-page-design > .actions > .bgradient,
.landing-page-design.mbl > .actions > .bgradient {
	margin-right: 20px;
}

.landing-page-design > .actions > .bno-gradient,
.landing-page-design.mbl > .actions > .bno-gradient {
	margin-left: 20px;
	background-color: white;
}

.landing-page-content-1 {
	padding: 70px 5%;
}

.graphs-container {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
	align-items: flex-end;
}

.graphs-container > div > img.assets-valuation-graph {
	width: 400px;
}

.graphs-container > .content {
	width: 32%;
	padding-right: 10%;
	color: #333333;
}

.graphs-container > .content > .head {
	font-size: 20px;
	font-weight: 500;
}

.landing-page-content-2 {
	padding: 35px 5%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.landing-page-content-2 > .image-container > .assetze-benefits {
	height: 80vh;
}

.landing-page-content-2 > .content {
	width: 25%;
}

.landing-page-content-2 > .content > .head {
	font-size: 22px;
	/* width: 50%; */
}

.landing-page-content-0 {
	background-color: #3fd7da;
	height: 200px;
	padding: 20px 5%;
}

.landing-page-content-0 > .head {
	text-align: center;
	font-size: 18px;
	color: white;
	font-weight: bold;
	margin-top: 20px;
}

.each-slick-item {
	/* width: 300px; */
	text-align: center;
}
.each-slick-item > svg {
	height: 60px;
}

.each-slick-item > img {
	/* width: 300px; */
}

.prod-slider > .slick-list > .slick-track > .slick-active {
	display: flex !important;
	align-items: center;
	justify-content: space-around;
}

.custom-slider-1 {
	background: #f9f9f9;
}

.custom-slider-1 > .slick-dots {
	padding-bottom: 20px;
}

.landing-page-content-3 {
	padding: 35px 5%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.landing-page-content-3 > .content > .head {
	font-size: 22px;
}

.landing-page-content-4,
.landing-page-content-5 {
	padding: 35px 5%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.landing-page-content-4 > .content > .header-1 {
	font-size: 24px;
}

.landingpage-content-6 {
	padding: 20px 5%;
	text-align: center;
}

.each-testimonial {
	display: flex !important;
	flex-direction: row;
	background: rgb(63, 215, 218);
	background: linear-gradient(90deg, rgba(63, 215, 218, 1) 0%, rgba(85, 243, 176, 1) 100%);
	height: 350px;
	border-radius: 20px;
}

.each-testimonial > .image-container {
	padding-left: 20px;
	position: relative;
	/* box-sizing: border-box; */
}

.each-testimonial > .image-container > div {
	position: absolute;
	height: 360px;
	z-index: 2;
	top: -20px;
	bottom: -20px;
	left: 20px;
}

.each-testimonial > .image-container > div > img {
	width: 320px;
	object-fit: cover;
}

.each-testimonial > .contents {
	padding-left: 350px;
	padding-top: 60px;
	text-align: left;
	width: 50%;
	color: #333333;
	font-size: 20px;
	word-wrap: break-word;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
}

.each-testimonial > .contents > .company-info > div {
	padding-bottom: 20px;
}

.landingpage-content-6 > .testimonial-contianer {
	margin-top: 30px;
}

.landingpage-content-7 {
	background-color: #eeffff;
	padding: 20px;
	text-align: center;
	/* margin-top: 50px; */
}

.landingpage-content-7 > div > img {
	height: 60px;
	margin-right: 70px;
	margin-left: 70px;
	margin-top: 20px;
}

.landingpage-content-9 {
	background-color: #333333;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 36px;
}

.landingpage-content-9 > .head-text {
	color: #3fd7da;
	background: #3fd7da;
	background: -webkit-linear-gradient(to right, #3fd7da 0%, #4feeb8 100%);
	background: -moz-linear-gradient(to right, #3fd7da 0%, #4feeb8 100%);
	background: linear-gradient(to right, #3fd7da 0%, #4feeb8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.testimonia-container-mbl {
	display: none;
}

.landing-page-design.mbl {
	display: none;
}

@media only screen and (max-width: 600px) {
	.landing-page-content-1 > .graphs-container {
		flex-direction: column;
	}
	.landing-page-content-1 > .graphs-container > .content {
		width: 100%;
	}
	.landing-page-content-2 {
		flex-direction: column-reverse;
	}
	.landing-page-content-2 > .image-container > .assetze-benefits {
		height: inherit;
		width: 100vw;
	}
	.landing-page-content-2 > .content {
		width: 100%;
	}
	.landing-page-content-3 {
		flex-direction: column;
	}
	.landing-page-content-3 > .image-container > img {
		width: 100vw;
		height: inherit;
	}
	.landing-page-content-4 {
		flex-direction: column;
	}
	.graphs-container > .content {
		padding-right: 0% !important;
	}
	.landing-page-design {
		background-size: cover;
	}
	.graphs-container > div > img.assets-valuation-graph {
		width: 90vw;
	}
	.landing-page-content-4 > .image-container > img {
		width: 90vw;
	}
	.landingpage-content-9 > .head-text {
		font-size: 24px;
		text-align: center;
	}
	.landingpage-content-7 > div > img {
		margin: 0px;
		width: 50px;
		height: inherit;
		margin-right: 10px;
		margin-left: 10px;
	}
	.landingpage-content-6 > .testimonial-contianer {
		display: none;
	}
	.testimonia-container-mbl {
		display: block;
		margin-top: 20px;
		color: #333;
	}
	.each-testimonial-mbl {
		background: rgb(63, 215, 218);
		background: linear-gradient(90deg, rgba(63, 215, 218, 1) 0%, rgba(85, 243, 176, 1) 100%);
		border-radius: 10px;
	}

	.each-testimonial-mbl > .image-container > img {
		width: 80vw;
		margin: 0 auto;
		margin-top: 20px;
	}

	.each-testimonial-mbl > .contents {
		padding: 20px;
		text-align: left;
	}
	.each-testimonial-mbl > .contents > .company-info {
		margin-top: 20px;
	}
	.each-testimonial-mbl > .contents > .company-info > img {
		margin-top: 20px;
	}
	.landing-page-design.mbl > .actions > .bgradient {
		padding: 14px 40px;
	}
	.app-container-4 > .head-1 {
		font-size: 20px;
	}
	.landing-page-design {
		display: none;
	}
	.landing-page-design.mbl {
		display: flex;
		background-image: none;
		height: 45vh;
	}
	.landing-page-design.mbl > h1 {
		font-size: 25px;
	}
}
