.qr-scanner-div .main-container {
    display: flex;
    height: 100vh;
}

.qr-scanner-div .main-container>.main-bg-image {
    width: calc(100vw - 370px);
    object-fit: cover;
}

.qr-scanner-div .main-content {
    width: 100%;
    overflow-y: scroll;
    /* margin: auto; */
}

button {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .main-container>.main-bg-image {
        display: none;
    }

    .main-content {
        width: 100vw;
    }
}

.qr-scanner-div .login-contianer {
    /* padding: 40px 20px; */
    margin: auto;
}

.qr-scanner-div .login-contianer>.login-form {
    width: 100%;
}

.qr-scanner-div .login-form .eye-icon {
    position: absolute;
    top: 274px;
    right: 60px;
    color: #808080;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.qr-scanner-div .scan-login-div {
    margin-top: 30px;
}

/* .qr-scanner-div .login-contianer Input, */
.qr-scanner-div .login-contianer .ant-input-affix-wrapper {
    padding: 10px 15px;
    margin-bottom: 20px;
    border: 0px;
    border: 1px solid #808080;
    border-radius: 5px;
    /* color: #a0a0a0; */
    font-weight: 400;
}

.qr-scanner-div .ant-design-scanner-button {
    height: 40px !important;
    font-size: 20px !important;
    font-family: 'League Spartan';
    font-weight: 400 !important;
    border-color: #33D7DB !important;
    background: #33D7DB !important;
    color: white !important;
    border-radius: 3px !important;
}

.qr-scanner-div .login-form {
    margin-top: 30px;
}