.dashboard-content {
	background-color: white;
	margin-top: 120px;
	display: flex;
	padding: 5vh 10vw 5vh 10vw;
	align-items: center;
	justify-content: center;
}

.dashboard-content>.content-container>.head-1 {
	font-weight: 600;
	font-size: 36px;
	line-height: 120%;
	/* or 43px */

	color: #2d3636;
	margin-bottom: 33px;
}

.dashboard-content>.content-container>.content-1 {
	font-weight: 400;
	font-size: 21px;
	line-height: 135.5%;
	/* or 28px */
	margin-bottom: 30px;
	color: #7b7b7b;
	max-width: 80%;
}

.dashboard-content>.right-image-container>img {
	width: 35vw;
}

.companies-container>img {
	width: 100vw;
}

.companies-list {
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-features {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10vw;
	padding-bottom: 40px;
	font-family: 'League-Spartan';
	padding-top: 40px;
	padding-bottom: 40px;
}

.main-features>.each-feature {
	width: 30%;
}

.main-features>.each-feature>.image-container>img {
	/* width: 55px; */
	margin-bottom: 15px;
}

.main-features>.each-feature>.head-1 {
	font-weight: 700;
	font-size: 24px;
	line-height: 108%;
	/* identical to box height, or 26px */
	margin-bottom: 10px;
	color: #1a2e35;
}

.main-features>.each-feature>.content-1 {
	font-weight: 400;
	font-size: 18px;
	line-height: 108%;
	/* or 19px */

	color: #5e5e5e;
}

.assetze-smart {
	background: #1a2e35;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 7vw;
	padding-right: 7vw;
}

.assetze-smart>.head-1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 108%;
	/* or 45px */
	text-align: center;
	color: #ffffff;
	margin-bottom: 70px;
}

.assetze-smart>.all-smart-thing {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.assetze-smart>.all-smart-thing>.each-smart-thing {
	width: 50%;
	display: flex;
	align-items: flex-start;
	margin-bottom: 50px;
}

.assetze-smart>.all-smart-thing>.each-smart-thing>.image-container>img {
	width: 50px;
	margin-right: 20px;
}

.assetze-smart>.all-smart-thing>.each-smart-thing>.content-container>.head-1 {
	font-weight: 700;
	font-size: 24px;
	line-height: 108%;
	/* identical to box height, or 26px */
	padding-bottom: 20px;
	color: #5efba1;
}

.assetze-smart>.all-smart-thing>.each-smart-thing>.content-container>.content-1 {
	font-weight: 400;
	font-size: 18px;
	line-height: 108%;
	/* or 19px */
	padding-bottom: 13px;
	color: #ffffff;
	width: 90%;
}

.assetze-smart>.all-smart-thing>.each-smart-thing>.content-container>.action {
	font-weight: 400;
	font-size: 18px;
	line-height: 108%;
	/* or 19px */
	cursor: pointer;
	color: #5efba1;
}

.support-container {
	background-color: white;
	padding-left: 7vw;
	padding-right: 7vw;
	padding-top: 50px;
	padding-bottom: 50px;
}

.support-container>.head-1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 108%;
	/* or 45px */
	text-align: center;
	color: #1a2e35;
	padding-bottom: 8px;
}

.support-container>.head-2 {
	font-weight: 500;
	font-size: 21px;
	line-height: 108%;
	/* identical to box height, or 23px */
	text-align: center;
	color: #1a2e35;
}

.support-container>.content-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 50px;
}

.support-container>.content-container>.text-content {
	width: 50%;
}

.support-container>.content-container>.text-content>.each-info>.head-1 {
	font-weight: 700;
	font-size: 24px;
	line-height: 108%;
	/* identical to box height, or 26px */
	padding-bottom: 20px;
	color: #1a2e35;
	margin-top: 20px;
}

.support-container>.content-container>.text-content>.each-info>.content-1 {
	font-weight: 500;
	font-size: 18px;
	line-height: 108%;
	/* or 19px */
	padding-bottom: 12px;
	color: #43484a;
}

.support-container>.content-container>.image-container>img {
	width: 45vw;
}

.featurelist-container {
	padding: 50px 5vw;
}

.featurelist-container>.head-1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 39px;
	/* identical to box height */

	text-align: center;
	padding-bottom: 50px;
	color: #1a2e35;
}

.featurelist-container>.feature-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 35px;
}

.featurelist-container>.feature-row>.each-feature-container {
	width: 30%;
	text-align: center;
	margin-bottom: 20px;
}

.featurelist-container>.feature-row>.each-feature-container>.image-container>img {
	height: 60px;
	padding-bottom: 21px;
}

.featurelist-container>.feature-row>.each-feature-container>.head-1 {
	font-weight: 700;
	font-size: 16px;
	line-height: 15px;
	/* identical to box height */

	text-align: center;
	padding-bottom: 8px;
	color: #555555;
	font-size: 21px;
	font-weight: 700;
	line-height: 15px;
	padding-bottom: 14px;
}

.featurelist-container>.feature-row>.each-feature-container>.content-1 {
	font-weight: 400;
	font-size: 12px;
	line-height: 11px;
	text-align: center;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	color: #555555;
	color: #919191;
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
}

.featurelist-container>.view-feature-btn {
	background: none;
	border: 0px;
	background: #33d7db;
	border-radius: 3px;
	font-weight: 700;
	font-size: 16px;
	line-height: 15px;
	/* identical to box height */

	text-align: center;
	color: #333333;
	margin-left: auto;
	margin-right: auto;
	padding: 15px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.circle-container>.head-1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 39px;
	text-align: center;
	color: #1a2e35;
	padding-bottom: 50px;
}

.connect-with-team {
	background: #1a2e35;
	padding: 25px 5vw;
}

.connect-with-team>.container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.connect-with-team>.container>.image-container>img {
	width: 25vw;
	margin-right: 5vw;
	margin-left: 5vw;
}

.connect-with-team>.container>.text-content>.head-1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 108%;
	/* or 45px */
	padding-bottom: 30px;
	color: #5efba1;
}

.connect-with-team>.container>.text-content>.head-2 {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 108%;
	/* identical to box height, or 26px */
	padding-bottom: 40px;
	color: #ffffff;
}

.connect-with-team>.container>.text-content>.expert-btn {
	border: 0px;
	background-color: none;
	background: #ffffff;
	border-radius: 5px;
	padding: 10px 25px;
	font-weight: 400;
	font-size: 24px;
	line-height: 108%;
	/* identical to box height, or 26px */

	color: #1a2e35;
}

.desktop {
	display: block;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 600px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.early-access-new.full-width {
		width: 90vw;
	}

	.main-features {
		flex-direction: column;
		text-align: center;
	}

	.main-features>.each-feature {
		width: 90%;
		margin-bottom: 30px;
	}

	.assetze-smart>.all-smart-thing {
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
	}

	.assetze-smart>.all-smart-thing>.each-smart-thing {
		align-items: center;
	}

	.assetze-smart>.all-smart-thing>.each-smart-thing>.image-container>img {
		margin-right: 0px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	.assetze-smart>.all-smart-thing>.each-smart-thing>.content-container>.content-1 {
		width: 100%;
	}

	.assetze-smart>.all-smart-thing>.each-smart-thing {
		width: 90%;
		flex-direction: column;
	}

	.dashboard-content {
		flex-direction: column;
		padding: 13vh 10px;
	}

	.dashboard-content>.content-container>.head-1 {
		font-weight: 600;
		font-size: 30px;
		line-height: 120%;
		text-align: center;
		color: #2d3636;
	}

	.dashboard-content>.content-container>.content-1 {
		max-width: 100%;
		text-align: center;
	}

	.dashboard-content>.right-image-container>img {
		width: 90vw;
	}

	.support-container>.head-1 {
		font-size: 30px;
		line-height: 120%;
		/* or 36px */

		text-align: center;

		color: #2d3636;
	}

	.support-container>.head-2 {
		font-weight: 500;
		font-size: 21px;
		line-height: 108%;
		/* or 23px */

		text-align: center;

		color: #1a2e35;
	}

	.support-container>.content-container {
		flex-direction: column-reverse;
	}

	.support-container>.content-container>.text-content {
		width: 100%;
		text-align: center;
	}

	.support-container>.content-container>.image-container>img {
		width: 90vw;
	}

	.featurelist-container>.feature-row {
		flex-direction: column;
	}

	.featurelist-container>.feature-row>.each-feature-container {
		width: 100%;
	}

	.register-screen>.content-2 {
		flex-direction: column;
	}

	.register-screen>.content-2>.form-container {
		width: 100%;
	}

	.form-container>.section-2>.input-container-name {
		flex-direction: column;
	}

	.form-container>.section-2>.input-container-name>div {
		width: 100%;
	}

	.register-screen>.content-2>.testimonials {
		width: 100%;
		margin-top: 50px;
	}

	.connect-with-team>.container {
		flex-direction: column;
	}

	.connect-with-team>.container>.image-container>img {
		width: 85vw;
	}

	.connect-with-team>.container>.text-content>.head-1 {
		margin-top: 20px;
		text-align: center;
		font-weight: 700;
		font-size: 36px;
		line-height: 108%;
		/* or 39px */

		text-align: center;

		color: #5efba1;
	}

	.connect-with-team>.container>.text-content {
		text-align: center;
	}

	.footer-container>.content>.text-content {
		flex-direction: column;
	}

	.footer-container>.content>.text-content>.each-content {
		width: 100%;
	}

	.highlighter-page {
		padding-top: 20px;
		flex-direction: column;
		align-items: center;
		height: fit-content;
		text-align: center;
		padding-bottom: 20px;
	}

	.highlighter-page>.early-access-new {
		margin-top: 20px;
	}

	.highlighter-page>.text-head {
		padding-right: 0px;
	}

	.register-screen>img {
		padding-left: 0px !important;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 30px;
	}
}