@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.asset-management-tabs {
	display: flex;
	justify-content: center;
	margin-bottom: 60px;
}

.asset-management-tabs .ant-tabs-nav .ant-tabs-tab {
	background: #eeeeee !important;
}

.asset-management-tabs .ant-tabs-nav .ant-tabs-tab-active {
	background: #f6f6f6 !important;
}

.tab-options {
	width: 75px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
}

.asset-tab {
	display: flex;
	padding: 22px;
	width: 950px;
	justify-content: space-between;
	margin: auto;
}

/* .asset-left-half{
	width: 50%;
} */
.asset-right-half {
	/* width: 50%; */
	text-align: right;
}

.asset-device-name {
	margin-top: 40px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 800;
	font-size: 21px;
	line-height: 29px;
	padding-bottom: 6px;
	margin-right: 15px;
}

.asset-device-id {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	margin-right: 15px;
}

.asset-id {
	display: flex;
	padding-top: 8px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #33d7db;
	margin-right: 15px;
	justify-content: right;
}

.asset-right-half .asset-id .id-flexdiv {
	display: flex;
	align-items: center;
}

.asset-id span {
	margin-top: 3px;
	color: black;
	margin-left: 5px;
	cursor: pointer;
}

.asset-po {
	padding-top: 8px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 25px;
	margin-right: 15px;
}

.asset-innerdiv {
	margin-top: 15px;
	width: 318px;
	background: #efefef;
	border-radius: 5px;
	/* float: right; */
	margin-left: auto;
	padding: 12px 15px;
}

.asset-username {
	/* margin-top: 10px; */
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 800;
	font-size: 21px;
	/* line-height: 29px; */
}

.asset-userid {
	margin-top: 8px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	/* line-height: 19px; */
}

.asset-user-email {
	margin-top: 6px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
}

.asset-user-no {
	margin-top: 6px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
}

.asset-assigned-div,
.asset-department-div {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.asset-assigned-on,
.asset-department {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	color: #33d7db;
}

.asset-assigned-date,
.asset-department-value {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
}

.asset-left-half>.dev-images>.asset-min-image-container>img {
	margin-right: 30px;
	box-sizing: border-box;
	height: 60px;
	cursor: pointer;
	width: 60px;
	object-fit: contain;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.asset-left-half>.dev-images {
	display: flex;
	margin-top: 30px;
	margin-left: 50px;
}

.asset-btn {
	margin-top: 21px;
	display: flex;
	flex-direction: column;
	float: right;
}

.unassign-btn,
.dispose-btn {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	width: 150px;
	height: 40px;
	background: #33d7db;
	border-radius: 5px;
	border: none;
	color: white;
	outline: none;
}

.dispose-btn {
	margin-top: 12px;
	background: #52f1b4;
}

.asset-left-half>.dev-images>.asset-min-image-container {
	position: relative;
}

.asset-left-half>.dev-images>.asset-min-image-container>svg {
	position: absolute;
	z-index: 100;
	right: 30px;
	color: red;
	top: -10px;
	cursor: pointer;
}

.tab-health {
	padding: 22px;
	width: 950px;
}

.health-outerdiv {
	margin: auto;
	width: 672px;
}

.health-tab-div {
	display: flex;
	justify-content: space-between;
	width: 672px;
	height: 94px;
	background: #f5f5f5;
}

.health-tab-ratingdiv {
	padding: 11px 18px;
	display: flex;
	flex-direction: column;
}

.health-tab-text {
	margin-bottom: 11px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 25px;
	color: #333333;
}

.health-working-condition {
	display: flex;
	align-items: center;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 15px;
	color: #333333;
	margin-right: 77px;
	text-transform: capitalize;
}

.health-textdiv {
	padding: 15px;
}

.health-comment,
.health-warranty-text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 17px;
	margin-bottom: 10px;
	margin-top: 22px;
}

.health-textdiv .comment-div {
	display: flex;
	justify-content: space-between;
	font-size: 20px;
}

.comment-div .edit-btn {
	margin-top: 10px;
	cursor: pointer;
	height: 20px;
}

/* .health-comment-ul{
	padding: 0 0 0 10px;
} */
.health-comment-div {
	padding: 0 0 0 10px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
}

.comment-div .comment-textarea {
	width: 650px;
}

.comment-div .comment-textarea TextArea {
	border-radius: 10px;
	background-color: #fafafa;
}

.comment-div .comment-textarea .note {
	margin: 10px 5px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 15px;
	color: #333333;
}

.comment-div .comment-textarea .update-cmt-btn {
	font-family: 'League Spartan';
	font-style: normal;
	background-color: #33d7db;
	border: none;
	color: black;
}

.comment-div .comment-textarea .update-cmt-btn:hover {
	color: black !important;
}

.comment-div .flexdiv .btns {
	display: flex;
	align-items: center;
	gap: 5px;
}

.comment-div .flexdiv {
	display: flex;
	margin-top: 10px;
	justify-content: space-between;
}

.health-textdiv hr {
	margin-top: 9px;
	border: 1px solid #888888;
}

.health-warranty-text {
	margin-top: 38px;
}

.health-purchase-date {
	margin-top: 17px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
}

.tab-health .expire-date {
	display: flex;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
}

.health-status {
	margin-top: 16px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
}

.health-status span {
	color: #888888;
	margin-left: 4px;
}

.health-steps {
	margin-top: 46px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
}

.health-vendor-div {
	display: flex;
	height: 47px;
	background: #f5f5f5;
	padding: 0 12px;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}

.health-vendor-text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 17px;
}

.health-vendor-name {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
}

.tab-software {
	padding: 22px;
	width: 950px;
}

.software-innerdiv {
	/* width: 647px; */
	width: 750px;
	text-align: center;
	margin: auto;
}

/* .tab-software .ant-table-thead .ant-table-cell {
	background-color: #E3FAFB !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 13px;
}

.tab-software .ant-table-content {
	background-color: #FAFAFA !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #535353;
} */

.tab-config {
	width: 900px;
	margin-top: 60px;
}

.ant-tabs-nav .ant-tabs-nav-operations-hidden {
	position: relative !important;
}

.tab-config .ant-tabs-nav .ant-tabs-tab {
	background: #fafafa !important;
}

.tab-config .ant-tabs-nav .ant-tabs-tab-active {
	background: #fafafa !important;
}

.tab-config .tab-options {
	/* width: 75px; */
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
}

.config-device-specs,
.config-memory {
	width: 700px;
	/* margin: auto; */
}

.config-device-specs .manufacturer,
.config-memory .memory-flexdiv {
	width: 650px;
	height: 40px;
	background: #f0f0f0;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 15px;
	color: #555555;
	display: flex;
	align-items: center;
	padding-left: 43px;
}

.config-device-specs .manufacturer .manu-label,
.config-memory .memory-flexdiv .memory-label {
	width: 125px;
}

.config-device-specs .manufacturer .manu-value,
.config-memory .memory-flexdiv .memory-value {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 15px;
	color: #555555;
}

.config-device-specs .innerdiv .flexdiv,
.config-memory .innerdiv .flexdiv {
	display: flex;
	margin-top: 12px;
}

/* .config-device-specs .innerdiv .slot, */
.config-memory .innerdiv .slot {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 15px;
	color: #535353;
	margin-bottom: 14px;
}

.config-device-specs .innerdiv .label,
.config-memory .innerdiv .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	color: #535353;
	width: 125px;
}

.config-device-specs .innerdiv,
.config-memory .innerdiv {
	margin-top: 30px;
	margin-left: 43px;
}

.config-device-specs .innerdiv .value,
.config-memory .innerdiv .value {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #6b6a6a;
}

.config-tab-usersdiv {
	width: 750px;
}

.config-tab-usersdiv .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #6b6a6a;
	background-color: #fafafa;
	text-transform: capitalize;
}

.config-tab-harddisk {
	width: 750px;
}

/* .config-tab-harddisk .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #6B6A6A;
}

.config-tab-harddisk .ant-table-thead .ant-table-cell {
	color: #555555;
	font-weight: 600;
	font-size: 15px;
} */

.config-tab-harddisk .progress-flexdiv {
	display: flex;
	justify-content: center;
	gap: 7px;
	align-items: center;
}

.progress-flexdiv .ant-progress .ant-progress-inner {
	top: -3px;
}

.config-tab-harddisk .total-hard-disk {
	display: flex;
	align-items: center;
	width: 271px;
	height: 40px;
	background: #5efba1;
	border-radius: 3px;
	padding-left: 23px;
	gap: 30px;
}

.config-tab-harddisk .total-hard-disk .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	color: #555555;
}

.config-tab-harddisk .total-hard-disk .value {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 15px;
	color: #555555;
}

.config-tab-harddisk .upperdiv {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 0 20px;
}

.config-tab-harddisk .upperdiv .search-btn {
	cursor: pointer;
	font-size: 15px;
}

.tab-history {
	width: 900px;
}

.tab-history .audit-table {
	margin-top: 20px;
}

.tab-history-innerdiv {
	width: 672px;
	margin: 60px auto;
}

.tab-history-innerdiv .edit-btn {
	background-color: #33d7db;
	font-family: 'League Spartan';
	font-style: normal;
	float: right;
	margin-left: 5px;
	outline: none;
	border: none;
	margin-bottom: 5px;
	font-size: 15px;
	color: black;
}

.tab-history-innerdiv .edit-btn:hover {
	color: black !important;
}

.asset-management-tabs .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #333333;
	text-transform: capitalize;
}

.asset-management-tabs .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	background-color: gray !important;
}

.asset-management-tabs .ant-table-thead .ant-table-cell {
	text-align: center;
	background-color: #33d7db !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 13px;
	color: #333333;
}

/* .tab-history .ant-table-thead .ant-table-cell {
	background-color: #33D7DB;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 13px;
	color: #333333;
} */

.audit-health-onsave {
	background-color: #33d7db !important;
	outline: none;
	border: #33d7db !important;
	color: black !important;
}

.audit-health-onsave:hover {
	color: black !important;
}

.audit-health-cancel:hover {
	background-color: white !important;
}

/* .tab-history .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #333333;
	text-transform: capitalize;
} */

.tab-depreciation {
	width: 900px;
}

.tab-depreciation .innerdiv {
	width: 650px;
	margin: auto;
	margin-top: 20px;
}

.tab-depreciation .innerdiv .flexdiv {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.tab-depreciation .textdiv {
	display: flex;
	flex-direction: column;
}

.tab-depreciation .textdiv .heading {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 900;
	font-size: 30px;
	line-height: 41px;
	color: #333333;
}

.tab-depreciation .textdiv .subheading {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	color: #888888;
}

.depr-text-div .flexdiv {
	display: flex;
	justify-content: flex-start !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 13px;
	color: #6b6a6a;
}

.depr-text-div .flexdiv .label {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	width: 250px;
}

.depr-text-div {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.tab-depreciation .depreciation-btn {
	background-color: #33d7db;
	font-family: 'League Spartan';
	font-style: normal;
	margin-left: 5px;
	outline: none;
	border: none;
	margin-bottom: 5px;
	font-size: 15px;
	color: black;
	width: 200px;
	float: left;
}

.tab-depreciation .depreciation-btn:hover {
	color: black !important;
	background-color: #33d7db !important;
}

.depr-text-div .download-div {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	margin: 30px 0;
	display: flex;
	justify-content: space-between;
}

/* .depr-text-div .download-div {
	display: flex;
	align-items: center;
	gap: 30px;
	margin-top: 15px;
	margin-bottom: 30px;
}

.depr-text-div .select-div {
	display: flex;
	align-items: center;
	gap: 10px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
}

.depr-text-div .download-div .download-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2px;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	width: 130px;
	height: 30px;
	background: #33D7DB;
	border-radius: 5px;
	border: none;
	color: white;
	outline: none;
} */

/* .line-chart {
	border-radius: 20px;
	padding: 10px 10px;
} */

.audit-health-div {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	padding-left: 10px;
}

.audit-health-div .each-health-container {
	display: flex;
	justify-content: space-between;
}

.audit-health-innerdiv .audit-health-comments {
	width: 200px;
	border-radius: 3px;
	/* background-color: #F6F6F6; */
	background-color: white;
}

.audit-health-innerdiv .audit-health-select {
	/* background-color: white !important; */
	/* border: 1px solid #d9d9d9; */
	width: 200px;
}

.ant-input:hover {
	border-color: #33d7db !important;
}

.ant-input:focus {
	border-color: #33d7db !important;
}

.audit-health-innerdiv .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
	background: white !important;
	border: 1px solid #d9d9d9;
}

.config-tab-custom {
	margin-top: 10px;
}

.config-tab-custom ul b {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 13px;
	color: #535353;
	text-transform: capitalize;
}

.config-tab-custom ul li {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #6b6a6a;
	text-transform: capitalize;
}

.laptop {
	margin-left: 99px;
	margin-top: 39px;
	text-transform: capitalize;
}

.device-user-details {
	background-color: #fafafa;
	max-width: 60vw;
}

.device-user-details>.top-half {
	display: flex;
	justify-content: space-between;
}

.device-user-details>.top-half>.right-half {
	padding-right: 30px;
	padding-top: 40px;
	text-align: right;
	max-width: 50%;
}

.device-user-details>.top-half>.right-half>.user-details {
	font-size: 15px;
	text-align: right;
}

.device-user-details>.top-half>.right-half>.dev-user-details>.time-details {
	display: flex;
	font-size: 15px;
	justify-content: space-between;
}

.device-user-details>.top-half>.right-half>.dev-user-details>.department {
	display: flex;
	font-size: 15px;
	margin-top: 10px;
	width: 100px;
}

.time-details>.query {
	color: rgb(15, 215, 219);
}

.time-details>.value {
	font-weight: 800;
	color: rgba(136, 136, 136, 1);
}

.device-user-details>.top-half>.right-half>.dev-user-details>.department>.query {
	color: rgb(15, 215, 219);
}

.top-half>.right-half>.device-name {
	font-family: 'Avenir';
	font-size: 21px;
	font-weight: 800;
	color: #333333;
	line-height: 29px;
	padding-bottom: 6px;
	text-transform: capitalize;
}

.top-half>.right-half>.device-id,
.top-half>.right-half>.emp-id {
	line-height: 19px;
	padding-bottom: 5px;

	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	text-align: right;

	color: #333333;
}

.left-half>.dev-images {
	display: flex;
	margin-top: 30px;
	margin-left: 50px;
}

.left-half>.dev-images>.asset-min-image-container {
	position: relative;
}

.left-half>.dev-images>.asset-min-image-container>svg {
	position: absolute;
	z-index: 100;
	right: 30px;
	color: red;
	top: -10px;
	cursor: pointer;
}

.left-half>.dev-images>.asset-min-image-container>img {
	margin-right: 30px;
	box-sizing: border-box;
	height: 60px;
	cursor: pointer;
	width: 60px;
	object-fit: contain;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.asset-right-half .id {
	font-size: 16px;
	cursor: pointer;
	color: #33d7db;
	display: flex;
	align-items: center;
	cursor: pointer;
	/* justify-content: flex-end; */
}

.top-half>.right-half>.id>.downlaod-asset-tag-click {
	padding-right: 10px;
}

.asset-right-half .downlaod-asset-tag-click {
	padding-right: 8px;
	padding-top: 2px;
}

.dev-user-details {
	margin-top: 15px;
	background: #fafafa;
	margin-right: -15px;
	border-radius: 5px;
	padding: 15px;
	padding-left: 1em;
}

.dev-user-details>.dev-user-name {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 800;
	font-size: 21px;
	line-height: 29px;
	padding-bottom: 10px;
	text-align: right;
	text-transform: capitalize;
	color: #333333;
}

.dev-user-details>.dev-user-id,
.dev-user-details>.dev-user-email,
.dev-user-details>.dev-user-phno {
	font-size: 14px;
	font-weight: 500;
	color: #333333;
	padding-bottom: 5px;
	font-family: 'Avenir';
}

.right-half>.configuration {
	margin-top: 20px;
	/* text-align: right; */
}

.right-half>.configuration>div {
	font-size: 18px;
	font-weight: 800;
	color: #333333;
	font-family: 'Avenir';
}

.right-half>.configuration>ul {
	font-size: 14px;
	font-weight: 500;
	color: #333333;
	font-family: 'Avenir';
	text-transform: capitalize;
}

.button-unassign {
	color: white;
	margin-top: 20px;
	padding: 12px 25px;
	border: 0px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 18px;
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
}

.button-assign {
	color: white;
	margin-left: 45%;
	margin-top: 20px;
	padding: 12px 5px;
	border: 0px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 18px;
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
}

.device-user-details>.top-half>.right-half>.dev-user-details>.department>.value {
	text-transform: capitalize;
}

.device-user-details>.health-tab {
	display: flex;
	justify-content: flex-start;
	gap: 150px;
	margin: 44px;
	margin-right: 30px;
	padding-bottom: 15px;
	padding-left: 18px;
	background-color: #f5f5f5;
}

.device-user-details>.health-tab>.health-tab-left>.health-text {
	font-size: 18px;
	font-weight: bold;
	padding-bottom: 3px;
	padding-top: 11px;
	font-family: 'Avenir';
}

.device-user-details>.health-tab>.health-tab-left>.working-condition {
	font-size: 14px;
	font-weight: 800;
	padding-top: 5px;
	font-family: 'Avenir';
}

.device-user-details>.health-tab>.health-tab-left>.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 14px;
	font-size: inherit;
	text-rendering: auto;
}

.pc-image {
	flex: none;
	margin-top: 40px;
	margin-left: 50px;
	width: 332px;
	height: 332px;
	object-fit: contain;
	background-color: #ffffff;
	border-radius: 5px;
	border: 1px solid #f0f0f0;
}

.update-asset-image {
	padding: 20px;
	width: 100%;
	width: 100%;
}

.upload-asset-button {
	width: 100%;
	color: white;
	padding: 12px 25px;
	border: 0px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 18px;
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
	margin-top: 20px;
}

.upload-company-logo-button {
	color: white;
	border: 0px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	/* justify-content: center; */
	position: relative;
}

.upload-company-logo-button>button>.add-image {
	color: white;
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 18px;
}

.upload-company-logo-button .company-img-edit {
	background-color: #33D7DB;
	border-radius: 50%;
	right: -7px;
	height: 25px;
	width: 25px;
	position: absolute;
	cursor: pointer;
	font-size: 16px;
	outline: none;
	box-shadow: 3px 3px 3px grey;
}

.upload-company-logo-button .cmp-img-div {
	position: relative;
}

.upload-company-logo-button>button {
	border: 0px;
	background-color: transparent;
	position: relative;
}

.comapany-logo-edit {
	color: red;
	position: absolute;
	font-size: 12px;
	top: 0px;
	right: 0px;
	font-size: 20px;
	/* background-color: #3f3f3f; */
	/* opacity: 0.5; */
}

.upload-company-logo-button .ant-image .ant-image-img {
	width: 70px !important;
	height: 70px;
	border-radius: 50%;
	border-radius: 50%;
	box-shadow: 0 0 10px #afafaf;
	object-fit: contain;
}

.pc-image-2 {
	flex: none;
	width: 332px;
	height: 332px;
	object-fit: cover;
	background-color: #ffffff;
	object-fit: contain;
	border-radius: 5px;
	border: 1px solid #f0f0f0;
}

.laptop-model {
	padding-left: 60px;
}

.device-model {
	padding-left: 20px;
}

.asset-number {
	padding-left: 45px;
}

.device-user-details>.health-tab>.health-tab-right {
	text-align: left;
	padding-top: 23px;
}

.device-user-details>.health-tab>.health-tab-right>.comment {
	font-size: 16px;
	font-weight: 800;
	font-family: 'Avenir';
}

.device-user-details>.health-tab>.health-tab-right>.comments {
	font-weight: 500;
	font-size: 14px;
	padding-top: 7px;
	font-family: 'Avenir';
}

.device-user-details>.Warranty {
	margin-left: 56px;
	padding-right: 30px;
	padding-bottom: 35px;
}

.device-user-details>.Warranty>.warranty-text {
	font-size: 24px;
	font-weight: 900;
	font-family: 'Avenir';
}

.device-user-details>.Warranty>.horiz-line {
	height: 0px;
	border: 1px solid #888888;
	transform: rotate(-180deg);
}

.device-user-details>.Warranty>.date {
	display: flex;
	padding-top: 4px;
}

.device-user-details>.Warranty>.date>.date-text {
	font-weight: 800;
	font-size: 14px;
}

.device-user-details>.Warranty>.date>.date-num {
	font-weight: 500;
	font-size: 14px;
	padding-left: 5px;
}

.device-user-details>.Warranty>.status {
	display: flex;
	padding-top: 30px;
}

.device-user-details>.Warranty>.status>.status-text {
	font-size: 18px;
	font-weight: 800;
}

.device-user-details>.Warranty>.status>.status-value {
	font-size: 18px;
	color: rgb(204, 204, 204);
	padding-left: 4px;
}

.device-user-details>.Warranty>.warranty-track {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	padding-top: 15px;
	text-align: center;
}

.device-user-details>.Warranty>.warranty-track>.one-year {
	padding-left: 75px;
}

.device-user-details>.Warranty>.warranty-track>.two-years {
	padding-left: 69px;
}

.device-user-details>.Warranty>.warranty-track>.one-year>.duration,
.device-user-details>.Warranty>.warranty-track>.two-years>.duration,
.device-user-details>.Warranty>.warranty-track>.warranty-start>.duration {
	padding-left: 5px;
}

.device-user-details>.vendor-details {
	display: flex;
	background-color: #f5f5f5;
	margin: 40px;
	display: flex;
	padding-left: 18px;
}

.device-user-details>.vendor-details>.vendor {
	padding-left: 12px;
	padding-top: 14px;
	padding-bottom: 13px;
	font-weight: 900;
	font-size: 24px;
}

.device-user-details>.vendor-details>.vendor-name {
	font-size: 16px;
	font-weight: 800;
	padding-left: 285px;
	padding-top: 19px;
	padding-bottom: 19px;
}

.right-half>.assigned-employee-name {
	text-transform: capitalize;
}

.configuration {
	text-align: left;
}

.search-employee-input {
	width: 250px;
	padding: 10px;
	border: 0px;
	border: 1px solid #3f3f3f;
	border-radius: 5px;
}

.heading-text {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
}

.search-input-employee {
	text-align: left;
	padding: 10px 30px 30px 30px;
	min-height: 15vh;
	overflow-y: scroll;
	width: 350px;
}

.selected-employee {
	font-family: 'League Spartan';
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
	border: 1px solid #8f8f8f;
	padding: 10px;
	border-radius: 5px;
	font-weight: 300;
}

.select-other-information .date-assign-div {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	font-family: 'League Spartan';
	font-size: 18px;
	padding: 0 5px;
}

.temporarily-assigned {
	color: #3f3f3f;
	/* display: inline-flex; */
	margin-top: 20px;
	margin-bottom: 15px;
}

.ant-checkbox+span {
	font-family: 'League Spartan';
	font-size: 17px;
}

.ant-checkbox {
	top: -2px !important;
}

.temporarily-assigned>svg {
	color: #3f3f3f;
	margin-right: 12px;
}

.select-other-information {
	margin-top: 20px;
}

.select-other-information .cross-btn {
	position: absolute;
	right: 10px;
	bottom: 29px;
	cursor: pointer;
}

.search-employee {
	margin-top: 20px;
}

.search-employee .dropdown-options {
	max-height: 400px;
	margin-top: 20px;
	overflow-y: scroll;
}

.select-other-information>.assign-button {
	width: 100%;
	background-color: aqua;
	padding-top: 10px;
	padding-bottom: 10px;
	border: 0px;
	border-radius: 5px;
	color: #3f3f3f;
	font-weight: 800;
	/* text-transform: uppercase; */
	font-size: 16px;
	margin-top: 20px;
}

.last-usage-date {
	width: 100%;
}

.each-employee {
	font-family: 'League Spartan';
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 10px;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	padding: 5px 10px;
}

.each-employee:hover {
	/* background-color: #a0a0a0; */
	/* background-color: #57a8e9; */
	background-color: #5ae8e6;
	cursor: pointer;
	border-radius: 5px;
	color: white;
}

.each-employee>.buttons>button {
	margin-top: 10px;
	background-color: aqua;
	border: 0px;
	padding: 10px 30px;
	border-radius: 5px;
	font-size: 16px;
}

.device-history {
	margin-left: 55px;
	margin-bottom: 30px;
}

.device-history>.section-name {
	font-family: 'Avenir';
	font-size: 24px;
	font-weight: 900;
	color: #333333;
	line-height: 33px;
	margin-bottom: 15px;
}

.device-history>.each-history {
	font-family: 'Avenir';
	border: 0.5px solid #888888;
	border-radius: 10px;
	margin-right: 30px;
}

.StandardTable {
	border-collapse: collapse;
	width: 100%;
}

.StandardTable thead th {
	background: #33d7db;
	color: red;
}

.StandardTable thead th:first-of-type {
	border-top-left-radius: 10px;
}

.StandardTable thead th:last-of-type {
	border-top-right-radius: 10px;
}

.device-history>.each-history>.history-header {
	background: #33d7db;
	border-bottom: 0.5px solid #888888;
	border-radius: 5px;
	padding: 10px 18px;
	display: flex;
	justify-content: space-between;
}

.device-history>.each-history>.history-header>div {
	font-size: 16px;
	font-weight: 800;
	color: #ffffff;
	line-height: 22px;
}

.each-history>.role-history>table>thead>tr>th {
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 12px;
	line-height: 16px;
	color: #3f3f3f;
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
}

.each-history>.role-history>table>tbody>tr>td {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	padding-top: 7px;
	color: #333333;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 13px;
}

.expand-icon {
	color: #ffffff;
}

.comment-container {
	margin-left: 44px;
	margin-right: 44px;
	margin-top: 40px;
}

.comment-container>.comment-text:hover {
	background-color: #ffffff40;
	cursor: pointer;
	border: 1px solid #33d7db50;
}

.comment-container>.comment-text {
	border-radius: 5px;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	display: block;
	font-size: 16px;
	border: 1px solid #333333;
	color: #3f3f3f;
	font-size: 15px;
	line-height: 20px;
	text-align: justify;
}

.comment-container>.comment-text:after {
	/* content: ''; */
	display: inline-block;
	width: 100%;
}

.comment-container>button.update,
.add-comment-container>button.update {
	float: right;
	background-color: #33d7db;
	padding: 10px 20px;
	margin-top: 10px;
	background: rgb(63, 215, 218);
	background: linear-gradient(90deg, rgba(63, 215, 218, 1) 0%, rgba(85, 243, 176, 1) 100%);
	border: 0px;
	border-radius: 5px;
	margin-bottom: 10px;
	color: white;
	font-weight: 600;
	display: block;
	margin-left: auto;
}

.add-comment-container>button.update {
	width: 100%;
	margin-top: 0px;
}

.comment-container>label {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: bold;
	display: block;
	margin-top: 20px;
}

.add-comment-container {
	padding: 10px;
	width: 300px;
}

.add-comment-container>textarea {
	width: calc(100% - 20px);
	max-width: calc(100% - 20px);
	max-height: 50vh;
	padding: 10px;
	display: block;
	border: 1px solid #333333;
	border-radius: 5px;
	font-size: 16px;
	line-height: 20px;
	color: #3f3f3f;
}

.add-comment-container>.note {
	font-size: 14px;
	margin-top: 20px;
	margin-bottom: 20px;
	color: #333333;
}

.add-comment-container>.head {
	color: #3f3f3f;
}

.unassign-options {
	font-family: 'League Spartan';
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 10px;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	padding: 5px 10px;
}

.selected-unassign-option {
	font-family: 'League Spartan';
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 10px;
	border-radius: 5px;
	padding: 5px 10px;
	transition: 0.2s ease-in-out;
	border: 1px solid #33D7DB;
	background-color: #33D7DB;
	color: whitesmoke;
}

.unassign-options:hover {
	background-color: #FAFAFA;
	cursor: pointer;
	border-radius: 5px;
}

.scheduled-unassign-div {
	padding: 10px 10px;
}

.scheduled-unassign-div .innerdiv {
	padding: 20px 0;
}

.unassign-option-div {
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: left;
	padding: 20px 10px;
}

.unassign-option-div .heading {
	font-size: 17px;
	font-weight: 500;
}

.unassign-option-div .text {
	font-size: 15px;
	font-weight: 300;
}

.replacement-div .select-div {
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 300px;
	overflow-y: scroll;
	/* margin-top: 10px; */
	/* padding: 10px 10px; */
	margin: 20px 0;
}

.replacement-div .select-div .div1 {
	width: 160px;
}

.replacement-div .heading-text {
	padding: 20px 0;
}

.replacement-asset-options {
	font-family: 'League Spartan';
	font-size: 16px;
	font-weight: 400;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	/* flex-direction: row; */
	/* margin-top: 10px; */
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	height: 55px;
	gap: 40px;
	padding: 5px 10px;
	width: 100%;
}

.selected-asset-div {
	padding: 0px 5px 30px 5px;
}

.selected-asset-div .innerdiv .delete-btn {
	height: 55px;
	background-color: #FAFAFA;
}

.replacement-asset-options:hover {
	background-color: #FAFAFA;
	cursor: pointer;
}

.scheduled-unassign-div .action-btns,
.replacement-div .action-btns {
	display: flex;
	justify-content: right;
	gap: 10px;
}

/* .exit-modal-container {
	width: 450px;
	height: 150px;
	margin-top: 30px;
	margin-left: 25px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
} */

.exit-modal {
	font-weight: 800;
	font-family: 'League Spartan', sans-serif;
	color: #333333;
	font-size: 16px;
}

.exit-span {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -10px;
	gap: 10px;
}

.exit-span span {
	font-weight: 500;
	font-size: 12px;
}

/* #radio {
  accent-color: #33d7db;
} */
input[type='radio'] {
	accent-color: #33d7db;
	font-size: 20px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

/* input[type="radio"]:checked {
  background-color: #33d7db;
  border: none;
  outline: none;
} */
input[type='date']:required:invalid::-webkit-datetime-edit {
	color: transparent;
}

input[type='date']:focus::-webkit-datetime-edit {
	color: black !important;
}

/* .exit-modal-container {
	height: 160px;
	width: 450px;
	display: flex;
	gap: 10px;
} */

.exit-modal-container h3 {
	font-weight: 800;
	font-size: 15px;
	color: #333333;
	font-family: 'League Spartan', sans-serif;
}

.exit-modal-container .date-unassign {
	width: 240px;
	height: 40px;
	border: 1px solid #808080;
}

.exit-unassign {
	width: 150px;
	height: 45px;
	font-weight: 500;
	font-size: 14px;
	margin-top: 10px;
}

.date-assign {
	width: 290px;
	height: 46px;
	padding: 10px;
	border: 1px solid #8f8f8f;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.addUserBtn {
	background: #33d7db;
	border-radius: 5px;
	margin-left: 40vw;
	border: none;
	outline: none;
	margin-top: 10px;
	padding: 5px 10px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}

.dualUserContainer {
	padding: 30px 50px;
	display: flex;
	flex-direction: column;
	gap: 18px;
	width: 450px;
}

.dualUserInput input {
	background: #f9f9f9;
	/* border-bottom: none; */
}

.dualUserCheckboxContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.dualUserContainer .dualUserCheckbox {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: -15px;
}

.dualUserCheckbox input {
	align-self: center;
}

.dualUserCheckbox p {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 16px;
	color: #888888;
	margin-top: 21px;
}

.assignBtn {
	background: #33d7db;
	border-radius: 5px;
	border: none;
	outline: none;
	/* margin-top: 10px; */
	padding: 5px 10px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 16px;
	color: #000000;
	width: 175px;
	margin-left: 100px;
}

.customTimeContainer {
	display: flex;
	gap: 10px;
}

.timeContainer {
	display: flex;
	gap: 30px;
	color: #9dacac;
}

.customTime span {
	display: flex;
	padding-left: 50px;
}

.leftIcon {
	font-size: 21px;
	margin-left: 5px;
}

.shiftUsers {
	display: flex;
	flex-direction: column;
	gap: 3px;
	cursor: pointer;
}

.morningShift {
	background: #ffffcf;
	border-radius: 5px;
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 21px;
	text-align: right;
	color: #333333;
	padding: 5px;
}

.eveningShift {
	background: #fff4db;
	border-radius: 5px;
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 21px;
	text-align: right;
	color: #333333;
	padding: 5px;
}

.nightShift {
	background: #f7ebff;
	border-radius: 5px;
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 21px;
	text-align: right;
	color: #333333;
	padding: 5px;
}

.assetInfoContainer {
	margin-bottom: 10px;
}

.assetDropDownContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	border: 0.5px solid #888888;
	border-radius: 5px;
	/* margin: 3px 10px; */
	margin-right: 30px;
	/* margin-left: -10px; */
}

.morning {
	background: #ffffcf;
}

.custom {
	background: #dbfff4;
}

.assetDropDownContainer .upIcon {
	margin: 10px;
	margin-top: 3px;
}

.userName {
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 16px;
	color: #333333;
	margin-left: 15px;
	padding: 10px 3px 3px 3px;
}

.assetDetails table {
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-bottom: 0.5px solid #888888;
	border-left: 0.5px solid #888888;
	border-right: 0.5px solid #888888;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-top: -3px;
	margin-right: 30px;
	margin-bottom: 10px;
}

.userHeader {
	display: flex;
	gap: 42px;
	padding: 5px;
	margin: 3px 18px;
}

.userHeader th {
	font-family: 'Avenir';
	font-weight: 800;
	font-size: 14px;
	color: #33d7db;
	text-align: center;
}

.userData {
	display: flex;
	gap: 42px;
	padding: 5px;
	margin: 3px 18px;
}

.userData td {
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	text-align: center;
}

.evening {
	background: #fff4db;
}

.night {
	background: #f7ebff;
}

.shiftModalContainer {
	display: flex;
}

.leftModal {
	flex: 4;
	width: 350px;
}

.morning {
	background: #ffffcf;
}

.evening {
	background: #fff4db;
}

.night {
	background: #efdafe;
}

.userLoginDetails {
	display: flex;
	justify-content: center;
	gap: 50px;
	margin-top: 18px;
}

.userLogin h4 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 14px;
	color: #888888;
}

.userLogin p {
	font-family: 'League Spartan';
	font-weight: 700;
	font-size: 12px;
	color: #555555;
}

.userLogin p span {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	color: #333333;
	margin-right: 3px;
}

.circle {
	height: 300px;
	width: 300px;
	border-radius: 50%;
	background: #b1c1c2;
	border: 0.5px solid #aaaaaa;
	padding: 10px;
	margin: 10px 30px 30px 21px;
	position: relative;
}

.userClock {
	position: absolute;
	top: 42px;
	left: 42px;
	width: 72%;
}

.rightModal {
	flex: 4;
	display: flex;
	flex-direction: column;
	margin: 18px;
	width: 300px;
	gap: 30px;
}

.userInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.userInfo h3 {
	font-family: 'League Spartan';
	font-weight: 800;
	font-size: 21px;
	text-align: right;
	color: #333333;
	border-radius: 5px;
	padding: 5px;
}

.morningShiftName {
	background: #ffffcf;
}

.eveningShiftName {
	background: #fff4db;
}

.nightShiftName {
	background: #efdafe;
}

.userInfo h6 {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: right;
	color: #333333;
}

.userAssignInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.userAssignInfo h4 {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	color: #33d7db;
	margin-left: 30px;
}

.userAssignInfo p {
	font-family: 'League Spartan';
	font-weight: 800;
	font-size: 14px;
	color: #888888;
}

.userAssignInfo h6 {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 14px;
	color: #333333;
}

.rightModal>button {
	background: #33d7db;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 5px;
	margin: 5px 30px;
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 21px;
	color: #333333;
}

.rightModal h5 {
	display: flex;
	align-items: center;
	gap: 5px;
	align-self: center;
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 14px;
	color: #555555;
	cursor: pointer;
}

.downlaod-asset-tag-action>button {
	border: 0px;
	border-radius: 5px;
	background: #1a2e35;
	color: #fff;
	text-align: right;
	font-family: Avenir;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: 100%;
	padding: 15px 20px;
	text-align: center;
	margin-top: 20px;
}

.action-column-title {
	background-color: #33d7db;
}

.audit-health-div .audit-info-container {
	display: flex;
	margin-bottom: 10px;
	justify-content: space-around;
	padding: 0 20px;
	gap: 10px;
}

.audit-health-div .audit-info-name {
	font-size: 16px;
}

.audit-health-div .audit-info-input,
.audit-health-div .audit-info-name {
	width: 50%;
}

.audit-health-div .ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
	font-size: 16px;
}

.audit-health-div .health-note-on-expiry {
	margin-top: 30px;
}

.audit-health-div .audit-health-innerdiv .ant-btn-default {
	margin-top: 20px;
	margin-bottom: 10px;
	border: 1px solid #d9d9d9;
}

.health-steps-antd {
	width: 500px;
}

.health-textdiv .expire-date {
	gap: 5px;
}

.each-health-container .input-div {
	display: flex;
	gap: 10px;
}

.tab-depreciation .depr-form {
	display: flex;
	flex-direction: column;
	width: 300px;
}

@media only screen and (max-width: 600px) {
	.asset-tabs {
		width: 100%;
	}

	.ant-tabs-nav-wrap {
		justify-content: unset !important;
	}

	.asset-management-tabs .asset-tab {
		padding: 12px;
		flex-direction: column;
		width: 100%;
	}

	.asset-management-tabs .pc-image {
		margin-top: 0;
		margin-left: 0;
		width: 100%;
	}

	.asset-management-tabs .asset-left-half>.dev-images {
		margin-top: 20px;
		justify-content: space-around;
		margin-left: 0;
	}

	.update-asset-image {
		padding: 0px;
	}

	.exit-modal-container {
		width: 100%;
		gap: 10px;
		margin: 0;
	}

	.asset-management-tabs .asset-device-name {
		margin-top: 30px;
	}

	.asset-management-tabs .asset-innerdiv {
		width: 100%;
	}

	.asset-management-tabs .asset-left-half>.dev-images>.asset-min-image-container>img {
		margin-right: 0px;
	}

	.asset-management-tabs .asset-left-half>.dev-images>.asset-min-image-container>svg {
		left: 0px;
	}

	.tab-health {
		padding: 5px;
		width: 100%;
	}

	.health-tab-div {
		width: 100%;
		flex-direction: column;
		padding: 15px;
		gap: 8px;
		height: 120px;
	}

	.comment-div .flexdiv .btns {
		margin-top: 15px;
		justify-content: space-between;
	}

	.health-outerdiv {
		width: 100%;
	}

	.health-steps-antd {
		width: 100%;
	}

	.health-tab-ratingdiv {
		padding: 0;
	}

	.comment-div .flexdiv {
		flex-direction: column;
	}

	.comment-div .comment-textarea {
		width: 100%;
	}

	.comment-div .comment-inner-div {
		width: 100%;
	}

	.tab-history,
	.tab-history-innerdiv,
	.tab-config,
	.config-device-specs,
	.config-device-specs .manufacturer,
	.config-tab-harddisk,
	.config-tab-usersdiv,
	.software-innerdiv,
	.tab-depreciation,
	.tab-depreciation .innerdiv {
		width: 100%;
	}

	.tab-config .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
		padding-left: 0;
	}

	.tab-config {
		margin-top: 20px;
	}

	.config-device-specs .innerdiv {
		margin-top: 20px;
		margin-left: 20px;
	}

	.config-device-specs .manufacturer {
		padding-left: 20px;
	}

	.config-tab-harddisk .total-hard-disk {
		width: 200px;
		height: 30px;
		padding-left: 13px;
		gap: 10px;
	}

	.config-tab-usersdiv .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
		width: 100%;
		touch-action: none;
	}

	.tab-software {
		padding: 0px;
		width: 100%;
	}

	.tab-history .ant-card .ant-card-body {
		padding: 10px;
	}

	.each-health-container .input-div {
		flex-direction: column;
		gap: 5px;
	}

	.tab-history .audit-health-div {
		padding-left: 0px;
	}

	.audit-health-div .audit-info-container {
		flex-direction: column;
		padding: 0;
		margin-bottom: 20px;
		gap: 5px;
	}

	.audit-health-div .audit-info-input,
	.audit-health-div .audit-info-name {
		width: 100%;
	}

	.tab-depreciation .depr-form {
		width: 100%;
	}
}