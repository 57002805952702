.early-access-modal-conatiner {
	display: flex;
	padding: 20px 40px;
}

.earlyAccessImage {
	height: 300px;
}

.early-access-modal-conatiner>.section-1 {
	padding-right: 50px;
}

.early-access-modal-conatiner>.section-1>.header-1 {
	color: #666666;
	line-height: 27px;
}

.early-access-modal-conatiner>.section-1>.header-2 {
	color: #333333;
	margin-bottom: 20px;
	font-size: 12px;
	font-weight: 400;
}

.early-access-modal-conatiner>.section-1 {
	margin-bottom: 20px;
}

.early-access-modal-conatiner>.section-2 {
	width: 350px;
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	/* justify-content: space-around; */
}

.early-access-modal-conatiner>.section-2>.input-container {
	display: flex;
}

.early-access-modal-conatiner>.section-2>.input-container-name {
	display: flex;
	flex: 1;
}

.early-access-modal-conatiner>.section-2>.input-container-name>input {
	font-size: 14px;
	margin-bottom: 20px;
	margin-right: 20px;
	border: 1px solid #808080;
	border-radius: 3px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 0%;
	width: 160px;
}

.early-access-modal-conatiner>.section-2>.input-container>input {
	display: flex;
	flex: 1;
	font-size: 14px;
	margin-bottom: 20px;
	padding: 10px 5px 10px 10px;
	border: 0px;
	border: 1px solid #808080;
	border-radius: 3px;
}

.early-access-modal-conatiner>.section-2>.input-container:first-child>input:first-child {
	margin-right: 20px;
}

.early-access-modal-conatiner>.section-2>.input-container:first-child>input:first-child {
	display: flex;
	flex: 1;
}

.early-access-modal-conatiner>.section-2>.terms-accept {
	font-size: 12px;
	text-align: center;
	display: inline-block;
	word-break: break;
	font-weight: 400;
	margin-bottom: 20px;
}

.early-access-modal-conatiner>.section-2>.submit-button {
	background: rgb(16, 176, 160);
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
	color: white;
	padding: 12px 0px;
	border: 0px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 18px;
}

.early-access-modal-conatiner>.section-2>.already-have-account {
	text-align: center;
	font-weight: 400;
	font-size: 12px;
}

.early-access-modal-conatiner>.section-2>.already-have-account>.login-btn {
	font-weight: 900;
	color: #54f1a1;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.early-access-modal-conatiner>.section-1 {
		display: none !important;
	}

	.early-access-modal-conatiner>.section-2>.input-container {
		display: flex;
		flex-direction: column;
	}

	.early-access-modal-conatiner>.section-2>.input-container:first-child>input:first-child {
		margin-right: 0px;
	}

	.early-access-modal-conatiner>.section-2 {
		width: 85vw;
	}

	.early-access-modal-conatiner {
		padding: 20px 10px;
	}

	.early-access-modal-conatiner>.section-2 {
		margin-top: 10px;
	}
}

.register-screen {
	margin-top: 10px;
}

.register-screen>.content-2 {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 5vw;
	padding-right: 5vw;
}

.register-screen>.content-2>.form-container>.head-1 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 22px;
	/* identical to box height */
	margin-bottom: 20px;
	color: #1a2e35;
}

.register-screen>.content-2>.testimonials {
	width: 50%;
	padding-left: 40px;
}

.register-screen>.content-2>.testimonials>.each-testimonials {
	display: flex;
}

.register-screen>.content-2>.testimonials>.each-testimonials>.image-container>img {
	width: 100px;
	padding-right: 20px;
}

.register-screen>.content-2>.testimonials>.each-testimonials>.text-content>.image-container>img {
	width: 30px;
	margin-bottom: 20px;
}

.register-screen>.content-2>.testimonials>.each-testimonials>.text-content>.content-1 {
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 130%;
	/* or 30px */
	padding-bottom: 40px;
	color: #555555;
}

.register-screen>.content-2>.testimonials>.each-testimonials>.text-content>.name {
	font-weight: 500;
	font-size: 23px;
	line-height: 130%;
	/* or 30px */

	color: #555555;
}

.register-screen>.content-2>.testimonials>.each-testimonials>.text-content>.company-name {
	font-weight: 500;
}

.register-screen>.content-2>.form-container {
	padding: 30px;
	-webkit-box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
	box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
	width: 50%;
}

.form-container>.section-2>.input-container-name {
	display: flex;
	justify-content: space-between;
	/* margin-bottom: 20px; */
}

.form-container>.section-2>.input-container-name>div {
	width: 50%;
}

.form-container>.section-2>.input-container-name>div:first-child {
	margin-right: 20px;
}

.form-container>.section-2>.submit-button {
	background: none;
	border: 0px;
	background: #33d7db;
	border-radius: 3px;
	width: 100%;
	padding: 15px 20px;
	font-weight: 600;
	font-size: 24px;
	line-height: 22px;
	/* identical to box height */

	color: #ffffff;
}

.form-container>.section-2>.already-have-account {
	margin-top: 15px;
	text-align: center;
}

.form-container>.section-2>.already-have-account>.login-btn {
	color: #33d7db;
	cursor: pointer;
	font-weight: 700;
}

.custom-input {
	border: 0.5px solid #aaaaaa;
	border-radius: 3px;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 12px;
	padding-bottom: 14px;
	margin-bottom: 20px;
}

.custom-input>label {
	font-weight: 300;
	font-size: 12px;
	line-height: 11px;
	/* identical to box height */
	font-size: 12px;
	line-height: 11px;
	/* identical to box height */
	color: #555555;
	padding-bottom: 12px;
	color: #555555;
	display: block;
}

.custom-input>input {
	border: 0px;
	outline: 0px;
	font-weight: 100;
	font-size: 18px;
	line-height: 17px;
	/* identical to box height */

	/* color: #aaaaaa; */
}

.portfolio-companies {
	padding-left: 5vw;
	padding-right: 5ve;
	padding-top: 40px;
	margin-bottom: 70px;
}

.portfolio-companies>img {
	width: 90vw;
}

@media only screen and (max-width:600px) {
	.register-screen>.content-2>.form-container {
		width: 100%;
		padding: 25px 20px;
	}

	.register-screen>.content-2>.testimonials {
		width: 100%;
		padding-left: 0px;
	}
}