.assets-header {
	background-color: #f9f9f9;
	padding: 20px 20px 10px 20px;
}

.assets-header>h3 {
	padding-left: 20px;
	font-style: League-Spartan;
	border-bottom: 1px solid #808080;
	padding-bottom: 20px;
}

.add-assest-container {
	/* padding: 20px 30% 20px 60px; */
	padding: 30px 0px;
	background-color: white;
	/* overflow-y: scroll; */
	/* max-height: 100vh; */
}

.add-assest-container .add-assets-innerdiv {
	margin: auto;
	width: 80%;
}

.add-newasset-form {
	margin-bottom: 100px;
}

.add-newasset-form>.additional-container {
	font-size: 1.4rem;
	border-top: 2px solid #3f3f3f;
	margin-top: 40px;
	padding-top: 30px;
}

.add-newasset-form>.device-section-head {
	font-size: 1.4rem;
	margin-top: 30px;
	margin-bottom: 25px;
}

.add-assest-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.add-assest-container .add-assest-header h2 {
	font-family: "League-Spartan";
	font-weight: 400;
}

.add-assest-action {
	display: flex;
}

.add-assest-action>button {
	border: 0px;
	padding: 10px 25px;
	font-family: "League-Spartan";
	font-size: 16px;
	font-weight: 500;
	border-radius: 5px;
	cursor: pointer;
}

.add-assest-action>.cancel-btn {
	margin-right: 30px;
}

.add-assest-action>.save-btn {
	background-color: #5efba1;
}

.each-assest-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
}

.each-assest-field {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.each-assest-field>label {
	/* display: block; */
	font-family: "League-Spartan";
	/* font-size: 14px; */
	font-size: 17px;
	/* font-weight: 500; */
	margin-bottom: 8px;
	margin-top: 20px;
}

.each-assest-field>input {
	/* border: 1px solid #808080;
	border-radius: 3px;
	padding: 5px 0px 5px 10px; */
	font-size: 18px;
}

.each-assest-field .ant-picker .ant-picker-input>input {
	font-size: 18px !important;
}

/* .each-assest-field>button {
	background-color: #33d7d8;
	border: 0px;
	padding: 10px 0;
	border-radius: 5px;
	cursor: pointer;
} */

.configuration-modal-container {
	/* padding: 10px 20px 20px 20px; */
	/* height: 70vh; */
	min-height: 400px;
	/* overflow-y: scroll; */
}

.configuration-modal-container>.head {
	font-size: 24px;
	padding-bottom: 10px;
	border-bottom: 1px solid #333333;
	margin-bottom: 20px;
	color: #333333;
}

.configuration-list-container>div>input {
	margin-top: 10px;
	padding: 5px 10px;
	border: 0px;
	border: 1px solid #808080;
	border-radius: 5px;
	margin-right: 20px;
	margin-bottom: 10px;
}

.configuration-modal-container>button {
	background-color: #33d7db;
	/* padding: 10px 20px; */
	border: 0px;
	border-radius: 5px;
	color: #333333;
	margin-top: 20px;
	margin-bottom: 40px;
}

.configuration-modal-container>.actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

.configuration-modal-container>.actions>button {
	margin-right: 20px;
	/* padding: 10px 50px; */
	border-radius: 5px;
	color: #333333;
	border: 0px;
}

.configuration-modal-container>.actions>button.save {
	background-color: #5efba1;
}

.conf-textare {
	margin-bottom: 10px;
	color: #555555;
	padding: 10px;
	resize: none;
	font-size: 12px;
}

.modal-health-container {
	/* padding: 20px;
	height: 70vh;
	overflow-y: scroll; */
}

.modal-health-container>.head {
	font-size: 20px;
	color: #333333;
	font-family: "League-Spartan";
	padding-bottom: 10px;
	border-bottom: 1px solid #333333;
	margin-bottom: 20px;
}

.each-health-container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.each-health-container>.health-name {
	/* width: 250px; */
	font-family: "League-Spartan";
	font-size: 15px;
	font-weight: normal;
	margin-right: 10px;
	/* width: 35%; */
}

.health-condition-options {
	/* width: 200px; */
	width: 40%;
	margin-right: 10px;
	/* margin-left: 20px; */
}

.each-health-container>.health-comments {
	width: 250px;
	/* margin-left: 30px;
	border: 0px;
	border: 1px solid #808080;
	padding: 10px 10px;
	border-radius: 5px;
	color: #404040; */
}

.health-note-on-expiry {
	color: #333333;
	font-size: 12px;
	text-align: center;
	margin-top: 40px;
}

.health-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.health-actions>.cancel {
	margin-right: 20px;
	border: 0px;
	background-color: #f2f2f2;
	padding: 10px 20px;
	border-radius: 5px;
	color: #333333;
}

.health-actions>.save {
	background-color: #5efba1;
	margin-left: 20px;
	border: 0px;
	padding: 10px 30px;
	border-radius: 5px;
	color: #333333;
}

.edit-asset-confirmation {
	color: #fff;
	background: #1677ff;
	box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
	outline: none;
	border: 0px;
	margin-left: 10px;

	cursor: pointer;
	font-size: 14px;
	line-height: 1.5714285714285714;
	height: 32px;
	padding: 4px 15px;
	border-radius: 6px;
}

.add-asset-confirmation-action {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.add-asset-dragger {
	margin-top: 20px;
	width: 40%;
}

.remarks-class {
	border: 1px solid #808080;
	border-radius: 3px;
	padding: 5px 0px 5px 10px;
	font-size: 16px;
	margin-top: 20px;
	width: 100%;
	margin-bottom: 20px;
}

@media only screen and (max-width:600px) {
	.add-assest-container {
		padding: 20px;
		width: 100%;
		margin: auto;
	}

	.each-health-container>.health-comments {
		width: 180px;
	}

	.add-assest-container .each-assest-row {
		flex-direction: column;
		gap: 20px;
	}

	.add-assest-container .each-assest-field {
		/* margin: auto; */
		width: 100%;
	}

	.add-assest-container .search-invocice-container {
		width: 100%;
	}

	.add-assest-container .add-assets-innerdiv {
		width: 95%;
		margin: auto;
	}

	.add-assest-container .search-office-container .search-office-select {
		width: 100%;
	}

	.add-assest-container .add-assest-header {
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
	}

	.add-assest-container .add-assest-header h2 {
		font-size: 25px;
	}

	.add-assest-header {
		margin-bottom: 40px;
	}

	.add-assest-container .new-email-add .select-emp {
		width: 100%;
	}

	.add-asset-dragger {
		width: 100%;
	}

}