@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.app-container {
	font-family: 'League Spartan', sans-serif;
	overflow: hidden;
}

.top-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-top: 40px;
	/* margin-bottom: 6rem; */
}
.heading h1 {
	margin-top: 3rem;
	font-weight: 800;
	font-size: 2.5rem;
	color: #555;
	text-align: center;
	line-height: 5rem;
}
.sub-heading {
	color: #555;
	font-weight: 300;
	font-size: 2rem;
	margin-top: -10px;
	line-height: 3rem;
	text-align: center;
}
.started-btn {
	background: #14e2e2;
	border-radius: 36px;
	padding: 10px;
	width: 200px;
	border: none;
	outline: none;
	color: #fff;
	font-weight: 500;
	font-size: 1.2rem;
	margin-top: 2.5rem;
	margin-bottom: 6.3rem;
}
.container2 {
	margin-top: -30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	margin: 10px 75px;
	padding: 10px;
}
.content {
	display: flex;
	flex-direction: column;
	flex: 2;
	gap: 2rem;
	margin-top: 0px;
	/* padding-top: 50px; */
}
.content h3 {
	color: #555;
	font-weight: 700;
	font-size: 1.9rem;
	text-align: left;
	line-height: 3rem;
	word-break: keep-all;
	padding: 20px;
}
.content p {
	font-weight: 300;
	color: #555;
	font-size: 1.3rem;
	line-height: 2rem;
	text-align: start;
	padding: 20px;
	margin-top: -75px;
	padding-top: 30px;
}
.laptop {
	width: 90%;
}
.image {
	flex: 2.9;
}
.media-content {
	display: none;
}
.content-paragraph {
	display: none;
}
.container3 {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	margin-top: 5rem;
}
.top {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}
.top h3 {
	color: #555;
	font-weight: 700;
	font-size: 2rem;
	text-align: center;
	line-height: 3rem;
	word-break: keep-all;
}
.bottom {
	display: flex;
	margin: 10px 75px;
	flex-direction: row;
	justify-content: space-between;
}
.bottom .image {
	flex: 3;
}
/* .bottom .image img {
  width: 90%;
} */
.bottom .content {
	flex: 2;
	align-self: center;
	margin-top: 5rem;
	margin-left: -120px;
}
.card-container {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 5px;
}
.media-chores {
	display: none;
}
.card-container .card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #d9d9d9;
	padding: 10px;
	/* margin: 10px; */
	background: #fff;
	width: 300px;
	flex: 1;
	/* gap: 1.5rem; */
	box-shadow: 4px 4px 12px rgba(136, 136, 136, 0.15);
	border-radius: 5px;
	/* position: relative; */
}
.card p {
	text-align: center;
	color: #555;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	margin-top: 10px;
}

.card h6 {
	color: #555;
	font-size: 1.3rem;
	font-weight: 500;
	margin-top: 30px;
}
.card img {
	/* height: 60%; */
	/* width: 80%; */
	margin-top: 6px;
}
.card .manage {
	margin-top: -9px;
}

.content .paragraph {
	line-height: 2rem;
	font-size: 1.3rem;
}
.vendor {
	display: flex;
	flex-direction: column;
	margin-top: 0;
	/* gap:rem; */
}
.vendor h1 {
	color: #555;
	font-weight: 700;
	font-size: 3rem;
	text-align: left;
	line-height: 3rem;
	word-break: keep-all;
	padding: 20px;
	margin-bottom: -60px;
	margin-left: -4px;
}
.vendor p {
	margin-bottom: -10px;
}
.content .coming-soon-btn {
	background: #14e2e2;
	border-radius: 36px;
	width: 180px;
	border: none;
	outline: none;
	padding: 10px;
	margin-left: 48px;
	color: #555;
	/* margin-top: 0px; */
}
/* .container2 .image .bottom {
  width: 50px;
} */

.landingpage-content-7 {
	background-color: #eeffff;
	padding: 20px;
	text-align: center;
	/* margin-top: 50px; */
}

.landingpage-content-7 h4 {
	color: #555;
}
.container4 {
	display: flex;
	flex-direction: column;
	/* align-items: center;
  justify-content: center; */
}
.container4 .testimonial p {
	text-align: center;
	color: #555;
	font-weight: 300;
	font-size: 1.4rem;
}
.container4 .testimonial h3 {
	color: #555;
	font-weight: 700;
	font-size: 2rem;
	text-align: center;
}
.testimonial-container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* align-items: center; */
	/* flex: 1; */
	padding: 10px;
	margin: 10px 90px;
	/* margin-left: 300px; */

	/* gap: -2rem; */
}
.testimonial-container .testimonial-image {
	display: flex;
	align-self: center;
	flex: 1;
}
.testimonial-content-container {
	display: flex;
	flex-direction: row;
	flex: 1;
	position: relative;
}
.testimonial-content-container .quotes-container {
	position: absolute;
	left: -10px;
}
.quotes-container2 {
	display: none;
}
.testimonial-container .testimonial-image .datafoundry {
	width: 45%;
	margin-left: 280px;
}
.testimonial-container .testimonial-content {
	/* flex: 1; */
	background: #e8fff2;
	height: 60%;
	padding: 60px;
	width: 72%;
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	/* gap: 1rem; */
	/* margin: 30px; */
}
.testimonial-content p {
	color: #333;
	line-height: 1.4rem;
	font-size: 1.2rem;
	font-weight: 400;
}

@media only screen and (max-width: 600px) {
	.app-container {
		overflow-x: hidden;
		/* margin: 10px 10px; */
	}
	.top-container {
		/* display: flex;
    flex-direction: column;
    justify-content: center; */
		align-items: center;
		margin-top: 100px;
	}
	.heading h1 {
		padding: 10px;
		font-size: 2rem;
		line-height: 3.5rem;
	}
	.sub-heading {
		font-size: 1.5rem;
	}
	.container2 {
		flex-direction: column-reverse;
		align-items: center;
		padding: 10px;
		margin: 10px;
	}

	.content h3 {
		font-size: 1.7rem;
		padding: 10px;
		text-align: center;
		/* margin: 0; */
	}
	.content p {
		font-size: 1.3rem;
		padding: 10px;
		margin-top: -50px;
		/* margin-top: -60px; */
		/* margin-top: 0px; */
		/* margin: 10px; */
		text-align: center;
	}
	.container2 image {
		width: 100%;
		padding: 30px;
	}
	.container3 {
		gap: 1rem;
	}
	.group .content {
		display: none;
	}
	.media-content {
		display: flex;
		flex-direction: column;
	}
	.media-content h3 {
		color: #555;
		font-weight: 700;
		font-size: 1.7rem;
		text-align: center;
		line-height: 3rem;
		word-break: keep-all;
		padding: 20px;
	}
	.media-content p {
		font-weight: 300;
		color: #555;
		font-size: 1.3rem;
		line-height: 2rem;
		text-align: start;
		padding: 20px;
		margin-top: -60px;
		padding-top: 30px;
		text-align: center;
	}
	.content .coming-soon-btn {
		margin-top: -10px;
		margin-bottom: 30px;
		margin-left: 75px;
	}
	.vendor h1 {
		font-size: 2.1rem;
		text-align: center;
		line-height: 4rem;
	}
	.container3 {
		margin-top: 0px;
	}
	.top h3 {
		font-size: 1.7rem;
	}
	.bottom {
		flex-direction: column;
		/* align-items: center;
    justify-content: center; */
		margin: 10px;
	}
	.bottom img {
		/* margin-left: 0px; */
		width: 100%;
		/* margin-left: 5px; */
	}
	.bottom .content {
		margin-left: 0px;
		word-break: normal;
	}
	.card {
		width: 100%;
	}
	.card p {
		font-size: 1.2rem;
	}
	.testimonial-container {
		flex-direction: column;
		align-items: center;
		margin: 5px;
		padding: 0px;
		margin-bottom: 10px;
	}
	.card-container {
		flex-direction: column;
	}

	.testimonial-container .testimonial-content {
		/* width: 50%; */
		width: 85%;
		padding-top: 27px;
		padding-bottom: 30px;
		padding-left: 30px;
		padding-right: 0px;
		margin: 10px;
		/* margin: 5px; */
		/* margin-left: 12px; */
	}

	.testimonial-container {
		position: relative;
		padding-top: 0px;
	}
	.testimonial-content-container .quotes-container {
		display: none;
	}
	/* .testimonial-content-container {
    position: relative;
  } */
	.quotes-container2 {
		display: flex;
		position: absolute;
		bottom: -10px;
		right: 120px;
		z-index: 10;
	}
	.testimonial-container .testimonial-image .datafoundry {
		width: 40%;
		position: relative;
		top: 30px;
		left: -190px;
		z-index: 10;
	}

	.testimonial-content p {
		/* padding-top: 30px; */
		font-size: 1rem;
		/* position: relative; */
	}
	.container4 .testimonial h3 {
		font-size: 1.8rem;
	}
	.container4 .testimonial p {
		font-size: 1.3rem;
		padding-right: 10px;
		padding-left: 10px;
		margin: 10px;
		line-height: 2rem;
	}
	.laptop {
		width: 100%;
		margin-left: 0px;
		margin-top: -10px;
	}
	.group4 {
		width: 100%;
	}
	.cyber-security {
		width: 100%;
	}
	.top .chores {
		display: none;
	}
	.media-chores {
		display: flex;
	}
}
