.asset-stock-table {
    width: 90%;
    margin: auto;
}

.asset-stock-table .ant-table-content {
    font-family: 'League Spartan';
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    text-transform: capitalize;
}

.asset-stock-table .ant-table-thead .ant-table-cell {
    font-family: 'League Spartan';
    font-weight: 500;
    font-size: 17px;
    color: #333333;
}