.faq--body {
	background-color: rgb(249, 249, 249);
	width: 100%;
	overflow: hidden;
	margin-top: 120px;
}

.faq--body > .h3 {
	padding-left: 66px;
	padding-top: 49px;
	font-weight: 900;
	font-size: 36px;
	color: #333333;
	font-style: normal;
}

.faq--body > .h2 {
	font-weight: 700;
	font-size: 48px;
	padding-left: 83px;
	padding-top: 100px;
	color: rgb(51, 51, 51);
	font-style: normal;
}

.faq--body > .search-bar {
	margin-left: 79px;
	margin-top: 39px;
	border-radius: 5px;
	background-color: #ffffff;
	width: 40vw;
	border: 1px solid white;
}

.faq--body > .faq-main {
	background-color: white;
	margin-top: 55px;
	padding-top: 70px;
	display: flex;
	gap: 3rem;
}

.faq--body > .faq-main > .faq-options > button {
	background-color: white;
	color: rgb(170, 170, 170);
	font-style: normal;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 35px;
	font-weight: 700;
	display: block;
	margin-left: 70px;
	padding-top: 30px;
}

.faq--body > .faq-main > .faq-options > button:focus {
	color: rgb(85, 85, 85);
}

.faq--body > .faq-main > .vert-line > hr {
	height: 80vh;
	border: 1px solid rgb(170, 170, 170);
	transform: rotate(0deg);
}

.faq-row-wrapper > .faq-body {
	background-color: white;
	border: white;
	width: min(700px, 70vw);
}

.faq-row-wrapper > .faq-title {
	display: flex;
	border: 0px;
	padding-left: 30px;
	margin-bottom: 40px;
}

.faq-row-wrapper > .faq-title > h2 {
	font-size: 52px;
	font-weight: 400;
	color: rgb(85, 85, 85);
}

.faq-row:nth-child(odd) {
	background-color: white;
	background: #effafb;
	height: fit-content;
}

.faq-row-wrapper > .faq-body > .faq-row > .row-content > .row-content-text {
	color: rgb(51, 51, 51);
	font-size: 24px;
	font-weight: 400;
	padding-left: 30px;
}

.faq-row-wrapper > .faq-body > .faq-row {
	border-bottom: 0px;
	border-radius: 5px;
	margin-bottom: 20px;
}

.faq-row-wrapper > .faq-body > .faq-row > .row-title {
	font-size: 30px;
	font-weight: 800;
	color: rgb(51, 51, 51);
	padding-left: 30px;
}

.faq--body > .faq-main > .faq-options > button.active {
	color: rgb(85, 85, 85);
}
