.each-analysis {
	background-color: white;
}

.each-analysis-icon {
	background: linear-gradient(90deg, #33d7db 0%, #36dad7 25.39%, #40e2ca 52.58%, #50efb5 80.5%, #5efba1 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: #33d7db;
	font-size: 60px;
}

.analysis-container {
	margin: 60px 60px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.analysis-container > .each-analysis {
	width: 20%;
	margin: 0 2.5%;
	margin-bottom: 40px;
	box-shadow: 0 0 10px #f1f1f1;
	padding: 20px;
	border-radius: 10px;
}

.analysis-title {
	margin-top: 0px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.analysis-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.each-analysis-value {
	font-size: 24px;
	margin: auto;
	font-weight: bold;
}
