.im-div {
    background-color: #ececec;
    padding-bottom: 60px;
}

.im-header {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    padding: 20px 10px 30px 10px;
}

.im-header .heading {
    font-family: "League Spartan";
    font-size: 25px;
    font-weight: 500;
}

.im-maindiv {
    margin: auto;
    width: 90%;
}

.im-maindiv .upperdiv {
    display: flex;
    gap: 20px;
    margin-bottom: 21px;
}

.im-maindiv .upperdiv .smalldiv {
    padding: 25px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    width: 254px;
    height: 175px;
    background: #FFFFFF;
    border-radius: 21px;
}

.im-maindiv .upperdiv .smalldiv .value {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #33D7DB;
}

.im-maindiv .upperdiv .smalldiv .text {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
}

.lowerdiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.lowerdiv .div1 .week-div {
    font-family: 'League Spartan';
    font-size: 18px;
    padding: 18px 40px;
    width: 530px;
    height: 390px;
    background: #FFFFFF;
    border-radius: 21px;
}

.lowerdiv .div1 .week-div .flexdiv {
    display: flex;
    align-items: center;
    height: 70%;
    justify-content: space-between;
}

.lowerdiv .div1 .flexdiv .leftdiv {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 20px;
}

.lowerdiv .div1 .flexdiv .leftdiv .colordiv {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: #5EFAA0;
}

.lowerdiv .div1 .flexdiv .leftdiv .list {
    display: flex;
    gap: 10px;
    align-items: center;
}

.lowerdiv .div1 .leaderboard-div .headingdiv {
    text-align: center;
    padding-bottom: 15px;
}

.lowerdiv .div1 .leaderboard-div .headingdiv .tableheading {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #3FD7DA;
    width: 150px;
}

.lowerdiv .div1 .leaderboard-div .headingdiv {
    display: flex;
    justify-content: space-around;
}

.lowerdiv .div1 .leaderboard-div .datadiv {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding-top: 10px;
}

.lowerdiv .div1 .leaderboard-div {
    font-family: "League Spartan";
    padding: 18px 40px;
    width: 530px;
    height: 390px;
    background: #FFFFFF;
    border-radius: 21px;
}

.lowerdiv .div1 .leaderboard-div .datadiv .text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    width: 150px;
    color: #333333;
}

.lowerdiv .div1 .leaderboard-div .datadiv .percentage {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    width: 150px;
    color: #333333;
}

.lowerdiv .div1,
.lowerdiv .div2 {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.div2 .weekly-chart-div {
    padding: 18px 40px;
    width: 530px;
    height: 390px;
    background: #FFFFFF;
    border-radius: 21px;
}

.div2 .ticket-board-div .flexdiv {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.div2 .ticket-board-div .flexdiv .text {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    width: 150px;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
}

.div2 .ticket-board-div {
    padding: 18px 40px;
    width: 530px;
    height: 390px;
    background: #FFFFFF;
    border-radius: 21px;
}

.div3 .flexdiv {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.div3 .flexdiv .text {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    width: 40%;
    color: #333333;
}

.div3 {
    padding: 18px 20px 18px 25px;
    width: 259px;
    height: 801px;
    background: #FFFFFF;
    border-radius: 21px;
}

.im-heading {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #333333;
    padding-bottom: 20px;
}

.lowerdiv .div1 .chartdiv {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
}