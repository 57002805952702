.scanner-asset-div .asset-name {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
}

.scanner-asset-div .assetid {
    color: #33D7DB;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
}

.scanner-asset-div .asset-innerdiv {
    width: 90%;
    text-align: right;
    margin: auto;
}

.scanner-asset-div {
    height: 100%;
    margin: 10px auto;
    padding: 0px 10px;
}

.scanner-asset-div .heading {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
}

.scanner-asset-div .asset-name-div {
    margin: 20px 0px;
    padding-left: 20px;
}

.scanner-asset-div .ant-carousel .slick-dots {
    margin-left: 0 !important;
}

.scanner-asset-div .ant-carousel .slick-dots-bottom {
    bottom: -5px !important;
}

.scanner-asset-div .img-div {
    width: 90%;
    margin: 40px auto;
}

.scanner-asset-div .img-div img {
    width: 100%;
}

.scanner-asset-div .health-outerdiv {
    width: 100%;
}

.scanner-asset-div .tab-health {
    padding: 0px;
    width: 100%;
}

.scanner-asset-div .health-tab-div {
    width: 100%;
    height: 120px;
}

.scanner-asset-div .health-tab-div {
    display: flex;
    flex-direction: column;
}

.scanner-asset-div .health-tab-div .health-working-condition {
    margin-top: 10px;
}

.scanner-asset-div .health-tab-div .health-vendor-div {
    margin-top: 0px;
}

.scanner-asset-div .total-div {
    margin: 17px;
    font-size: 17px;
}

.scanner-asset-div .leaflet-container {
    width: 100%;
}

.scanner-asset-div .location-div .ant-card .ant-card-body {
    padding: 0px !important;
}

.scanner-audit-history .audit-health-div .each-health-container {
    flex-direction: column;
    gap: 5px;
}

.scanner-audit-history .ant-card .ant-card-body {
    padding: 10px;
}

.scanner-audit-history .audit-health-div .audit-health-select,
.scanner-audit-history .audit-health-div .audit-health-comments {
    width: 100%;
}

.scanner-audit-history .each-health-container {
    align-items: normal;
}

.qr-scanner-div p {
    font-size: 16px;
    text-transform: capitalize;
}

.update-asset-scanner img {
    width: 100%;
    height: 100%;
}

/* .qr-scanner-div .update-image img {
    width: 100%;
    height: 100%;
    margin: auto;
} */