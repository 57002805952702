.header-activity {
	background-color: #f9f9f9;
	padding-bottom: 20px;
}

.header-activity>div {
	margin-left: 40px;
	margin-right: 40px;
	padding-top: 40px;
	border-bottom: 2px solid #808080;
}

.activity-table-container .ant-table-content {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 15px;
}

.activity-table-container .ant-table-thead .ant-table-cell {
	font-family: 'League Spartan';
	font-weight: 600;
	font-size: 16px;
}

.activity-table-container {
	margin-left: 100px;
	margin-right: 100px;
	margin-top: 50px;
}

.activity-info-filters .heading-div {
	font-family: "League Spartan";
	font-size: 17px;
	margin-bottom: 6px;
}

.activity-table-head.MuiTableHead-root {
	z-index: 0;
	position: relative;
}

.activity-table-head.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root {
	background-color: #33d7db;
	color: #3f3f3f;
	font-weight: bold;
}

.header-activity>.header-full {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.header-activity>.header-full>h4 {
	margin-left: 10px;
	margin-right: 20px;
}

.header-activity>.header-full>.jobs-info {
	color: #3f3f3f50;
	text-decoration: none;
}

.activity-table-container>.filter-container {
	margin-bottom: 20px;
	display: flex;
	justify-content: right;
}