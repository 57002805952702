.features-signup-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 50px;
	padding-top: 50px;
}

.features-signup-container > .head-1 {
	font-size: 32px;
	font-weight: 800;
	margin-bottom: 20px;
}

.features-signup-container > .head-2 {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;
}

button.signup-button-feature {
	margin-top: 0px;
	border: 0px;
	padding: 12px 30px;
	background-color: #66f9a4;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
}
