.ewaste-innerdiv {
    max-width: 1100px;
    margin: 140px auto;
    padding: 0 20px;
    /* background-color: whitesmoke; */
    font-family: 'League-Spartan';
}

.ewaste-innerdiv h1,
.ewaste-innerdiv h3 {
    color: #33D7DB;
}

.ewaste-innerdiv .content-div {
    margin: 30px 0;
    font-size: 18px;
}

.ewaste-innerdiv .div1 {
    background-color: #33D7DB;
    padding: 10px 8px;
    border-radius: 5px;
}

.ewaste-innerdiv li {
    padding: 5px 0;
}

@media only screen and (max-width:600px) {
    /* .ewaste-innerdiv {
        width: 90%;
    } */
}