.feature-container {
	position: relative;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.feature-contianer-list {
	position: absolute;
	top: 60px;
	background-color: white;
	box-shadow: 0 0 5px #f1f1f1;
	border-radius: 5px;
	/* padding: 10px 0px; */
	width: 200px;
	font-size: 14px;
	color: #333333;
	display: none;
}

.feature-contianer-list > .each-feature-head {
	padding: 10px 20px;
	cursor: pointer;
}

.feature-contianer-list > .each-feature-head:hover {
	background-color: #defeff;
}

.feature-contianer-list > .each-feature-head:nth-child(1):hover {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.feature-contianer-list > .each-feature-head:last-child:hover {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.feature-container:hover > .feature-contianer-list {
	display: block;
}

.feature-container > button {
	border: 0px;
	background-color: transparent;
	color: #333;
	font-size: 16px;
	margin-right: 20px;
	font-weight: bold;
	display: flex;
	align-items: center;
}

.feature-container > button > svg {
	font-size: 20px;
	margin-left: 10px;
}

.menu-container {
	position: relative;
	height: 60px;
}

.menu-contents {
	position: absolute;
	top: 0px;
	height: calc(100vh);
	background-color: white;
	left: 0px;
	right: 0px;
	top: 0px;
	padding: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 0px;
}

.menu-contents > div {
	margin: 15px 0px;
}

.early-access-menu {
	background-color: transparent;
	border: 0px;
	padding: 0px;
}

.menu-container {
	display: none;
}
.highlighter-page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	background: #f2f2f2;
}

.highlighter-page > .text-head {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 21px;
	color: #3a5052;
	padding-right: 30px;
}
.sticky-header {
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 1000;
}

@media only screen and (max-width: 600px) {
	.menu-container {
		display: block;
	}
}

.early-access-new {
	background-color: none;
	border: 0px;
	background: #33d7db;
	border-radius: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	/* identical to box height */
	padding: 10px 20px;
	color: #000000;
}
