.dispose-asset-div {
    width: 90%;
    margin: auto;
    font-family: "League Spartan";
}

.dispose-asset-div .heading {
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
    font-size: 30px;
}

.dispose-asset-div .text {
    font-weight: 400;
    font-size: 24px;
}

.dispose-asset-div .label {
    font-weight: 400;
    font-size: 23px;
    margin-top: 20px;
}

.dispose-asset-div .scanner-div {
    width: 85%;
    height: 380px;
    background-color: #FFF6F7;
    border-radius: 20px;
    margin: 10px auto;
}

.dispose-asset-table {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.dispose-asset-table .ant-table-content {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    text-transform: capitalize;
}

.dispose-asset-table .ant-table-thead .ant-table-cell {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}