.payment-failed-container {
	margin-top: 50px;
	margin-left: 120px;
}

.payment-failed-container > .payment-status {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
}

.payment-failed-container > img {
	padding: 30px;
}

.payment-failed-container > .status-msg,
.payment-failed-container > .payment-timestamp,
.payment-failed-container > .transaction-id {
	font-size: 18px;
	font-weight: 400;
	color: #555555;
	padding-bottom: 10px;
}
