.new-email-add>.create-new-container {
	margin-top: 20px;
	color: #666;
	font-family: League Spartan;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 40px;
}

.new-email-add {
	border-bottom: 2px solid #3f3f3f;
}

.new-email-add>h4 {
	margin-bottom: 20px;
	/* font-family: "League-Spartan"; */
	font-size: 1.4rem;
	font-weight: normal;
}

.new-email-add>.select-emp {
	width: 50%;
}

.new-email-add>.create-new-container>.highlighter {
	color: #33d7db;
	cursor: pointer;
}