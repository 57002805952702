.footer-container {
	background-color: #5efba1;
	padding-top: 40px;
	padding-bottom: 20px;
	padding-left: 5vw;
	padding-right: 5vw;
}

.footer-container > .content > .image-container {
	border-bottom: 1px solid #1a2e35;
}

.footer-container > .content > .image-container > img {
	height: 50px;
	margin-bottom: 50px;
}

.footer-container > .content > .text-content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.footer-container > .content > .text-content > .each-content {
	width: 25%;
}

.footer-container > .content > .text-content > .each-content > .head-1 {
	font-weight: 600;
	font-size: 21px;
	line-height: 42px;
	/* identical to box height, or 200% */
	padding-bottom: 25px;
	color: #000000;
}
.footer-container > .content > .text-content > .each-content > .content-1 {
	font-weight: 400;
	font-size: 12px;
	line-height: 42px;
	color: #2c3f46;
}

.footer-container > .content > .text-content > .each-content > .content-1.action {
	cursor: pointer;
}

.footer-container > .content > .text-content > .each-content > .content-1.sales-container {
	display: flex;
}

.copyright-new {
	height: 60px;
	background: #1a2e35;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 129% */

	color: #ffffff;
}
