.create-ticket-div {
    background-color: #FAFAFA;
    padding-bottom: 40px;
}

.create-ticket-div .heading {
    font-family: "League Spartan";
    font-size: 25px;
    width: 90%;
    margin: auto;
    padding: 20px 0px 30px 15px;
    font-weight: 500;
}

.create-ticket-div .datadiv {
    display: flex;
    align-items: center;
}

.create-ticket-div .input-label {
    font-family: "League Spartan";
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
}

.create-ticket-div .maindiv {
    width: 60%;
    border-radius: 20px;
}

.create-ticket-div .maindiv .innerdiv {
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px;
    width: 80%;
    margin: auto;
}

.create-ticket-div .img-div {
    width: 40%;
}

@media only screen and (max-width:700px) {
    .create-ticket-div .img-div {
        display: none;
    }

    .create-ticket-div .maindiv {
        width: 100%;
    }

    .create-ticket-div .maindiv .innerdiv {
        padding: 20px 17px;
        width: 90%;
    }

    .create-ticket-div .heading {
        font-size: 21px;
    }
}