.login-contianer {
	padding: 20px 40px;
}

.login-contianer>.login-form {
	display: flex;
	padding: 10px;
	flex-direction: column;
	width: 300px;
}

.login-contianer>.login-form>input,
.login-contianer>.login-form>.password>input {
	padding: 10px 15px;
	margin-bottom: 20px;
	border: 0px;
	border: 1px solid #808080;
	border-radius: 5px;
	/* color: #a0a0a0; */
	font-weight: 400;
}

.login-contianer>.login-form>.login-button {
	background: rgb(16, 176, 160);
	background: linear-gradient(90deg, rgba(16, 176, 160, 1) 0%, rgba(84, 241, 161, 1) 100%);
	color: white;
	padding: 12px 0px;
	border: 0px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 18px;
}

.hide {
	display: none;
}

.login-contianer>.login-form>.highlight-fp {
	margin-bottom: 20px;
	color: #33d7db;
	font-size: 12px;
	font-weight: 500;
	text-align: right;
	cursor: pointer;
	border: none;
	background-color: white;
	padding-top: 10px;
}

.login-form>.eye-icon {
	position: absolute;
	top: 165px;
	right: 60px;
	color: #808080;
	height: 1.5rem;
	width: 1.5rem;
	cursor: pointer;
}

.login-contianer>.login-form>.password {
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: row;
}

.login-form>.password>.eye-icon {
	position: absolute;
	color: #808080;
	top: 7px;
	height: 1.5rem;
	width: 1.5rem;
	cursor: pointer;
}

.login-contianer>.login-form>.password>input {
	width: 70vw;
}

.login-contianer>h1 {
	padding-left: 10px;
}

.resend-otp-text {
	margin-bottom: 20px;
	color: #33d7db;
	font-size: 12px;
	font-weight: 500;
	text-align: right;
	cursor: pointer;
	border: none;
	background-color: white;
	padding-top: 10px;
}