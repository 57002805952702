.order-confirm-container {
	margin-left: 73px;
	margin-bottom: 150px;
}

.order-confirm-container > .order-confirm {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
}

.order-confirm-container > .orderer-name {
	font-size: 21px;
	font-weight: 800;
	color: #333333;
	margin-top: 30px;
	margin-bottom: 25px;
}
.order-confirm-container > .order-confirm-msg {
	font-size: 21px;
	font-weight: 800;
	color: #888888;
}

.show-item {
	border-top: 0.5px solid #888888;
	border-bottom: 0.5px solid #888888;
	margin-top: 30px;
	margin-right: 50px;

	display: flex;
	justify-content: space-around;
}

.show-item > .eta-shipping {
	margin-top: 50px;
}

.show-item > .eta-shipping > .eta-delivery {
	font-size: 14px;
	font-weight: 800;
	color: #888888;
}

.show-item > .eta-shipping > .eta-addr {
	font-size: 14px;
	font-weight: 800;
	color: #333333;
	padding-top: 5px;
	padding-bottom: 40px;
}
.cart-item {
	display: flex;
	justify-content: space-between;
}

.cart-item > img {
	padding: 20px;
}

.cart-item > .device-details > .device-name {
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	padding-top: 50px;
}

.cart-item > .device-details > .device-color {
	font-size: 12px;
	font-weight: 500;
	color: #333333;
	padding-top: 15px;
}
.cart-item > .device-details > .device-memory,
.cart-item > .device-details > .device-storage {
	font-size: 12px;
	font-weight: 500;
	color: #555555;
	padding-top: 15px;
}

.shop-track {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
}

.shop-track > .to-shopping {
	padding: 14px;
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	background-color: #33d7db;
	border: none;
	border-radius: 5px;
	margin-bottom: 15px;
}

.shop-track > .to-track {
	padding: 14px 42px;
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	border: none;
	border-radius: 5px;
	background-color: #eaeaea;
}

.order-confirm-container > .view-products-message {
	font-size: 24px;
	font-weight: 800;
	color: #333333;
	margin-top: 50px;
	padding-left: 20px;
	margin-bottom: 50px;
}
