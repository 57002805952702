.eachtip-container {
	margin: 20px;
	background-color: white;
	box-shadow: 0 0 10px #f1f1f1;
	border-radius: 10px;
	padding: 10px;
}

.eachtip-container > .eachtip {
	display: flex;
	align-items: center;
	justify-content: center;
}

.eachtip-container > .eachtip > img {
	height: 120px;
	margin-right: 40px;
}
