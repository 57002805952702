body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	cursor: pointer;
	/* font-weight: 800; */
}

.dashboard-container {
	background-color: #f9f9f9;
	/* max-height: calc(100vh - 60px); */
	/* overflow-y: scroll; */
}

.row {
	display: flex;
	align-items: center;
}

@font-face {
	font-family: 'Avenir-Medium';
	src: url('https://assetze.s3.ap-south-1.amazonaws.com/fonts/AvenirMedium.ttf');
}

@font-face {
	font-family: 'League-Spartan';
	src: url('https://assetze.s3.ap-south-1.amazonaws.com/fonts/LeagueSpartan-VariableFont_wght.ttf	');
}

body,
textarea {
	font-family: Avenir-Medium;
}

textarea,
:-ms-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
::-webkit-input-placeholder {
	font-family: Avenir-Medium;
}
