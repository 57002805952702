.vendorRegistrationList {
	border-bottom: 0.5px solid #808080;
	padding-bottom: 10px;
	padding-left: 10px;
	margin: 30px;
	font-family: 'Avenir';
	font-weight: 900;
	font-size: 30px;
	color: #333333;
}

/* .fileModal {
  width: 100%;
  height: 100%;
} */

.vendorList {
	border-bottom: 3px solid #33d7db;
	font-family: 'Avenir';
	font-weight: 500;
	font-size: 18px;
	color: #333333;
	outline: none;
	border-top: none;
	border-left: none;
	border-right: none;
	background: none;
	padding: 5px 18px;
	margin-left: 18px;
	text-align: center;
}

.vendor-content-container .vendor-table .ant-table-content {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 16px;
	color: #333333;
	text-transform: capitalize;
}

.vendor-content-container .vendor-table .ant-table-thead .ant-table-cell {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 17px;
	color: #333333;
}