.shop-container {
	background-color: white;
	margin-bottom: 65px;
}

.shop-container > .prod-title {
	border: none;
	background-color: #f9f9f9;
	border-bottom: 3px solid #33d7db;

	font-size: 18px;
	font-weight: 500;
	margin-top: 50px;
	margin-left: 45px;
	margin-bottom: 35px;
	padding-bottom: 15px;
	padding-left: 50px;
	padding-right: 50px;
}

.shop-container > .shop-body {
	background-color: white;
	display: flex;
}

.shop-container > .shop-body > .body-left {
	border: 0.25px solid #555555;
	border-radius: 5px;
	box-sizing: border-box;
	width: 400px;
}

.shop-container > .shop-body > .body-left > .customize {
	font-size: 32px;
	font-weight: 800;
	color: #333333;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
}

.sec-proc > .sec-head,
.sec-mem > .sec-head,
.sec-stor > .sec-head {
	text-align: center;
	background-color: #33d7db;
	border: 3px;

	font-size: 21px;
	font-weight: 800;
	color: #ffffff;
	margin-left: 30px;
	margin-right: 40px;
	padding: 5px 50px;
	margin-top: 30px;
	margin-bottom: 20px;
}

.sec-proc > .item-1,
.sec-proc > .item-2,
.sec-proc > .item-3 {
	font-size: 12px;
	font-weight: 500;
	color: #333333;

	box-sizing: border-box;
	border-radius: 3px;
	border: 0.5px solid #888888;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-left: 15px;
	padding-right: 26px;
	margin-left: 30px;
	margin-right: 40px;
	margin-top: 30px;
	margin-bottom: 20px;
}

.shop-now-container > .shop-container > .shop-body > .body-left > .note {
	font-size: 8px;
	font-weight: 500;
	color: #000000;
	text-align: center;
	margin: 20px 0;
	margin-bottom: 0px;
}
.shop-container > .shop-body > .body-right {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.carousel-img {
	width: fit-content;
	margin-right: 0px;
}

.prod-slider {
	margin-top: 80px;
	margin-right: 20%;
	width: 1200px;
	padding-bottom: 30px;
}

.body-right > .body-details > .product-buy {
	background-color: #f9f9f9;
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
}

.product-buy > .prod-name {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
}

.product-buy > .cost-buy {
	padding-right: 50px;
}

.product-buy > .cost-buy > .cost {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
}

.product-buy > .cost-buy > .buy-now {
	border: none;
	background: #52f1b4;
	box-shadow: 0px 2px 6px rgba(93, 204, 141, 0.5);
	border-radius: 5px;

	font-size: 30px;
	font-weight: 800;
	color: #333333;
	margin-top: 18px;
	padding: 10px 70px;
}

.prod-config > ul > li {
	font-size: 26px;
	font-weight: 400;
	color: #000000;
}

.body-right > .body-details {
	padding-left: 30px;
	background-color: #f9f9f9;
	width: calc(100% - 349px);
}

.best-suit {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
	margin-top: 35px;
}

.tech-team {
	display: flex;
	flex-wrap: wrap;
	padding-top: 50px;
	padding-left: 55px;
}

.tech-team > .team {
	font-size: 21px;
	font-weight: 400;
	color: #555555;
	padding-top: 10px;
	padding-right: 35px;
}

.tech-team > .team-name {
	background: #ffffff;
	border: 1px solid #888888;
	box-sizing: border-box;
	border-radius: 25px;

	font-size: 21px;
	font-weight: 400;
	color: #555555;
	padding: 10px 20px;
	margin-left: 25px;
}

.lease-status {
	display: flex;
	margin-top: 40px;
	padding-bottom: 50px;
}

.lease-status > .lease-rent {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
}

.lease-status > .status {
	font-size: 36px;
	font-weight: 800;
	color: #33d7db;
}

.ratings {
	display: flex;
}

.ratings > .rating-count {
	font-size: 21px;
	font-weight: 800;
	color: #333333;
	margin-left: 30px;
	margin-top: 3px;
}

.timeline {
	text-align: center;
}

.horizontal-slider {
	padding-bottom: 20px;

	margin-left: 30px;
	margin-right: 40px;
	margin-top: 30px;
	margin-bottom: 20px;
}
.example-thumb {
	width: 10px;
	bottom: 8px;
	height: 10px;

	cursor: pointer;
	position: fixed;
	z-index: 100;
	background: linear-gradient(180deg, #fdfdfd 0%, #f0f0f0 100%);
	border: 5px solid #ffffff;
	border-radius: 100%;
	display: block;
	box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-track {
	position: relative;
	height: 4px;
	background: #ddd;
}
.example-track.example-track-0 {
	background: #33d7db;
}

.example-mark {
	width: 8px;
	height: 8px;
	border: none;
	background-color: rgb(255, 255, 255);
	cursor: pointer;
	border-radius: 50%;
	vertical-align: middle;
	margin: 0px calc(19px);
	bottom: calc(50% - 6px);
}
.example-mark.example-mark-active {
	border: 2px solid #00d4ff;
	background-color: rgb(255, 255, 255);
}
.example-mark.example-mark-completed {
	border: 2px solid rgb(255, 255, 255);
	background-color: #00d4ff;
}

.sec-mem > label,
.sec-stor > label {
	font-size: 12px;
	font-weight: 500;
	color: #333333;

	margin-left: 30px;
	margin-right: 40px;
	margin-bottom: 20px;
}
.sec-mem > #mem2,
.sec-stor > #str2 {
	padding-left: 50px;
}
.sec-mem > #mem3 {
	padding-left: 47px;
}
.sec-stor > #str3 {
	padding-left: 60px;
}
