.footer-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.footer-actions > div {
	cursor: pointer;
	margin: 10px;
	font-size: 12px;
	font-weight: 500;
}

.footer-signup {
	border: 0px;
	padding: 8px 40px;
	background: #fff;
	border-radius: 5px;
	color: #333;
	font-weight: 500;
	font-size: 14px;
}
