/* .filter-assets-container {
	background-color: #f2f2f2;
	padding: 10px 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
} */

.filter-search-input {
	display: flex;
	background-color: white;
	margin-right: 20px;
	align-items: center;
	border-radius: 20px;
}

.filter-search-input > input {
	border: 0px;
	border-radius: 20px;
	outline: none;
	height: 24px;
	width: 300px;
	color: #3f3f3f;
}

.filter-search-input > svg {
	font-size: 14px;
	padding: 0 10px;
	color: #999999;
}

/* .filter-search>button,
.filter-buttons-container>button {
	border-radius: 20px;
	border: 0px;
	border: 1px solid #33d7db;
	display: flex;
	align-items: center;
	padding: 0 20px;
	color: #33d7db;
	font-style: italic;
	padding: 5px 20px;
	cursor: pointer;
} */

.filter-buttons-container > .asset-setting-button {
	background-color: #f2f2f2;
	padding: 0px;
	border: none;
	border-radius: 0px;
}

.filter-search > button > svg {
	font-size: 18px;
	margin-right: 10px;
}

.filter-buttons-container > button > svg {
	font-size: 18px;
	margin-right: 10px;
	color: #333333;
}

.filter-buttons-container {
	display: flex;
}

/* .filter-buttons-container>button {
	margin-right: 20px;
	color: #333333;
	font-style: italic;
	background-color: #33d7db;
	border-radius: 5px;
} */

.filter-search {
	display: flex;
}

.assets-modal > .checklist > label > .checkbox-options {
	margin-right: 65px;
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
}

.assets-modal > .checklist > label {
	font-size: 16px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	margin-left: 34px;
	margin-bottom: 16px;
}

.checklist {
	display: flex;
	flex-wrap: wrap;
	margin-top: 15px;
	max-height: 200px;
	overflow-y: scroll;
}

.each-option > .checked {
	border: none;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	margin-right: 10px;
	background-color: #33d7db;
	font-size: 10px;
}

.each-option > .unchecked {
	border: 0.6px solid #999999;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	margin-right: 10px;
	background-color: white;
	font-size: 10px;
}

.assets-modal > .checklist > .each-option {
	cursor: pointer;
}

.assets-modal > .checklist > .each-option > label {
	font-size: 16px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	margin-left: 65px;
	margin-bottom: 16px;
}

.assets-modal > .checklist > .each-option > .checkbox {
	color: #33d7db;
}

.assets-modal > button {
	width: 50%;
	margin-top: 26px;
	margin-bottom: 23px;
	/* height: 30px; */
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	background-color: #33d7db;
	border-radius: 5px;
	border: 0px solid #ffffff;
	margin-left: auto;
	margin-right: auto;
}

.upload-modal-heading {
	background-color: white;
	font-size: 21px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	width: 100%;
	text-align: center;
	padding-top: 14px;
	padding-bottom: 7px;
	box-shadow: 0px 8px 6px rgba(170, 170, 170, 0.1);
}

.upload-options-containers {
	display: flex;
	/* margin-top: 13px; */
}

.upload-excel {
	background-color: #ffffff;
	margin: 20px 10px 20px 30px;
	width: 300px;
	text-align: center;
	padding: 10px;
}

.upload-manually {
	background-color: #ffffff;
	margin: 20px 30px 20px 10px;
	width: 300px;
	text-align: center;
	padding: 10px;
}

.upload-excel > img {
	margin-top: 40px;
	height: 90px;
}

.upload-manually > img {
	margin-top: 40px;
	height: 90px;
}

.upload-caption {
	font-size: 21px;
	font-weight: 800;
	color: rgba(51, 51, 51, 1);
	margin-top: 32px;
}

.upload-excel > p {
	font-size: 12px;
	font-weight: 500;
	color: rgba(51, 215, 219, 1);
	margin-top: 20px;
}

.modal-header {
	display: flex;
}

.modal-header > .add,
.modal-header > .delete {
	width: 20px;
	height: 20px;
	flex-grow: 0.5;
}

.modal-title {
	flex-grow: 0.5;
	font-size: 15px;
}

.upload-note {
	font-size: 14px;
	font-weight: 400;
	color: rgba(51, 51, 51, 1);
	margin-top: 11px;
}

.upload-button {
	background-color: #33d7db;
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
	padding: 7px 42px;
	border: none;
	margin-top: 25px;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
}

.upload-manually > button {
	background-color: #33d7db;
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
	padding: 7px 53px;
	border: none;
	margin-top: 77px;
	border-radius: 5px;
}

.upload-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-button > svg {
	margin-right: 10px;
}

.add-column-filter {
	width: 270px;
	height: 40px;
	margin-left: 15px;
	margin-bottom: 27px;
	background: #ffffff;
	border: 0.5px solid #777777;
	box-sizing: border-box;
	border-radius: 5px;
}

.filter-icon-container {
	border: 1px solid #33d7db;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: white;
}

.filter-modal-container {
	width: 350px;
	padding: 15px 30px 30px 30px;
	/* height: 50vh; */
	overflow-y: scroll;
}

.filter-heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.clear-heading {
	color: #33d7db;
	font-size: 12px;
	cursor: pointer;
}

.label-text {
	font-size: 12px;
	margin-bottom: 10px;
}

.search-input-modal {
	display: flex;
	align-items: center;
	background-color: white;
	padding: 10px 10px;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	color: #aaaaaa;
	border: 0.5px solid #afafaf;
	border-radius: 5px;
	margin-bottom: 20px;
}

.search-input-modal > input {
	border: 0px;
	display: flex;
	flex: 1;
	outline: none;
	padding-left: 10px;
}

.filter-apply-btn {
	width: 116px;
	height: 30px;
	background: #5efba1;
	border-radius: 5px;
	margin: 0 auto;
	border: 0px;
	margin-top: 30px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
}

.alert-container-cmp {
	width: 500px;
	text-align: left;
	padding-left: 40px;
	padding-right: 20px;
	padding-bottom: 20px;
	background-color: white;
}

.alert-container-cmp > .description {
	font-size: 20px;
	line-height: 24px;
}

.alert-container-cmp > .btn-contianer {
	margin-top: 20px;
	text-align: right;
}

.alert-container-cmp > .btn-contianer > button,
.replace-assets-container > .actions > button {
	margin-right: 20px;
	padding: 10px 20px;
	background-color: transparent;
	border: 0px;
	border-radius: 5px;
}

.alert-container-cmp > .btn-contianer > button.no,
.replace-assets-container > .actions > button.no {
	background-color: #f2f2f2;
}

.alert-container-cmp > .btn-contianer > button.yes,
.replace-assets-container > .actions > button.yes {
	background-color: #5efba1;
}

.replace-assets-container {
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 20px;
	max-width: 90vw;
}

.modified-data {
	color: red;
}

.replace-assets-container > .actions {
	text-align: center;
	margin-top: 20px;
}

.filter-input {
	width: 100%;
	padding: 5px;
	padding-left: 5px;
	padding-right: 5px;
}

.filter-list {
	margin-top: 15px;
	padding: 20px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
		rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.filter-list > .head-1 {
	font-weight: 700;
	margin-bottom: 5px;
}

.filter-list > .list-container > .each-item:hover {
	background-color: #33333310;
	border-radius: 500000px;
}

.filter-list > .list-container > .each-item {
	cursor: pointer;
	/* padding-bottom: 5px; */
	padding-top: 2.5px;
	padding-left: 15px;
	padding-bottom: 2.5px;
}

.filter-list > .list-container > .each-item:first-child {
	margin-top: 20px;
}

.filter-list > .list-container > .each-item.custom-el {
}

.adding-filter > .head-1 {
	margin-top: 20px;
	background-color: #1f1f1f50;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.adding-filter > .filter-body > .options-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.adding-filter > .body > .options-container > .each-option {
	display: flex;
	align-items: center;
}

.filter-assets-container {
	background-color: #f2f2f2;
	padding: 10px 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filter-search-input {
	display: flex;
	background-color: white;
	margin-right: 20px;
	align-items: center;
	border-radius: 20px;
}

.filter-search-input > input {
	border: 0px;
	border-radius: 20px;
	outline: none;
	height: 24px;
	width: 300px;
	color: #3f3f3f;
}

.filter-search-input > svg {
	font-size: 14px;
	padding: 0 10px;
	color: #999999;
}

/* .filter-search>button,
.filter-buttons-container>button {
	border-radius: 20px;
	border: 0px; */
/* border: 1px solid #33d7db; */
/* display: flex;
	align-items: center;
	padding: 0 20px;
	color: #33d7db;
	font-style: italic;
	padding: 5px 20px;
	cursor: pointer;
} */

.filter-buttons-container > .asset-setting-button {
	background-color: #f2f2f2;
	padding: 0px;
	border: none;
	border-radius: 0px;
}

.filter-search > button > svg {
	font-size: 18px;
	margin-right: 10px;
}

.filter-buttons-container > button > svg {
	font-size: 18px;
	margin-right: 10px;
	color: #333333;
}

.filter-buttons-container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

/* .filter-buttons-container>button {
	margin-right: 20px;
	color: #333333;
	font-style: italic;
	background-color: #33d7db;
	border-radius: 5px;
} */

.filter-search {
	display: flex;
}

.assets-modal > .checklist > label > .checkbox-options {
	margin-right: 65px;
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
}

.assets-modal > .checklist > label {
	font-size: 16px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	margin-left: 34px;
	margin-bottom: 16px;
}

.checklist {
	display: flex;
	flex-wrap: wrap;
	/* margin-top: 15px; */
}

.each-option > .checked {
	border: none;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	margin-right: 10px;
	background-color: #33d7db;
	font-size: 10px;
}

.each-option > .unchecked {
	border: 0.6px solid #999999;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	margin-right: 10px;
	background-color: white;
	font-size: 10px;
}

.assets-modal > .checklist > .each-option > label {
	font-size: 16px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	margin-left: 65px;
	margin-bottom: 16px;
}

.assets-modal > .checklist > .each-option > .checkbox {
	color: #33d7db;
}

.assets-modal > button {
	width: 50%;
	/* margin-top: 26px;
	margin-bottom: 23px; */
	/* height: 30px; */
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	background-color: #33d7db;
	border-radius: 5px;
	border: 0px solid #ffffff;
	text-align: center;
	/* margin-left: 23%; */
	margin: 20px auto;
}

.upload-modal-heading {
	background-color: white;
	font-size: 21px;
	font-weight: 500;
	color: rgba(51, 51, 51, 1);
	width: 100%;
	text-align: center;
	padding-top: 14px;
	padding-bottom: 7px;
	box-shadow: 0px 8px 6px rgba(170, 170, 170, 0.1);
}

.upload-options-containers {
	display: flex;
	flex-direction: column;
	/* gap: 15px; */
	/* margin-top: 13px; */
	padding: 20px 10px;
	background-color: #ececec;
}

.upload-excel img {
	margin-top: 40px;
	height: 90px;
}

.upload-excel {
	background-color: #ffffff;
	/* margin: 20px 10px 20px 30px; */
	/* border-right: 1px solid #a0a0a0; */
	margin: 0;
	width: 313px;
	text-align: center;
	padding: 10px;
}

.upload-manually {
	background-color: #ffffff;
	/* margin: 20px 30px 20px 10px; */
	margin: 0;
	width: 313px;
	text-align: center;
	padding: 10px;
}

.upload-excel > img {
	margin-top: 40px;
	height: 90px;
}

.upload-manually > img {
	margin-top: 40px;
	height: 90px;
}

.upload-caption {
	font-size: 18px;
	font-weight: 800;
	color: rgba(51, 51, 51, 1);
	margin-top: 32px;
}

.upload-excel > p {
	font-size: 12px;
	font-weight: 500;
	color: rgba(51, 215, 219, 1);
	margin-top: 20px;
}

.upload-excel a {
	font-size: 12px;
	font-weight: 500;
	color: rgba(51, 215, 219, 1);
	margin-top: 25px;
}

.modal-header {
	display: flex;
}

.modal-header > .add,
.modal-header > .delete {
	width: 20px;
	height: 20px;
	flex-grow: 0.5;
}

.modal-title {
	flex-grow: 0.5;
	font-size: 15px;
}

.upload-note {
	font-size: 12px;
	font-weight: 400;
	color: rgba(51, 51, 51, 1);
	margin-top: 11px;
}

.upload-button {
	background-color: #33d7db;
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
	padding: 7px 42px;
	border: none;
	margin-top: 25px;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
}

.upload-manually > button {
	background-color: #33d7db;
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
	padding: 7px 53px;
	border: none;
	/* margin-top: 77px; */
	margin-top: 26px;
	margin-bottom: 20px;
	border-radius: 5px;
}

.upload-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-button > svg {
	margin-right: 10px;
}

.add-column-filter {
	width: 270px;
	height: 40px;
	margin-left: 15px;
	margin-bottom: 27px;
	background: #ffffff;
	border: 0.5px solid #777777;
	box-sizing: border-box;
	border-radius: 5px;
}

.filter-icon-container {
	border: 1px solid #33d7db;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: white;
}

.filter-modal-container {
	width: 400px;
	padding: 15px 30px 30px 30px;
	/* height: 50vh; */
	overflow-y: scroll;
}

.filter-heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.clear-heading {
	color: #33d7db;
	font-size: 12px;
	cursor: pointer;
}

.label-text {
	font-size: 11px;
	margin-bottom: 10px;
}

.search-input-modal {
	display: flex;
	align-items: center;
	background-color: white;
	padding: 10px 10px;
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	color: #aaaaaa;
	border: 0.5px solid #afafaf;
	border-radius: 5px;
	margin-bottom: 20px;
}

.search-input-modal > input {
	border: 0px;
	display: flex;
	flex: 1;
	outline: none;
	padding-left: 10px;
}

.filter-apply-btn {
	width: 116px;
	height: 30px;
	background: #5efba1;
	border-radius: 5px;
	margin: 0 auto;
	border: 0px;
	margin-top: 30px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
}

.alert-container-cmp {
	width: 100%;
	/* text-align: left; */
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	background-color: white;
}

.alert-container-cmp > .description {
	font-size: 17px;
	line-height: 24px;
}

.alert-container-cmp > .btn-contianer {
	margin-top: 20px;
	text-align: right;
}

.alert-container-cmp > .btn-contianer > button,
.replace-assets-container > .actions > button {
	margin-right: 20px;
	padding: 10px 20px;
	background-color: transparent;
	border: 0px;
	border-radius: 5px;
}

.alert-container-cmp > .btn-contianer > button.no,
.replace-assets-container > .actions > button.no {
	background-color: #f2f2f2;
}

.alert-container-cmp > .btn-contianer > button.yes,
.replace-assets-container > .actions > button.yes {
	background-color: #5efba1;
}

.replace-assets-container {
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 20px;
	max-width: 90vw;
}

.modified-data {
	color: red;
}

.replace-assets-container > .actions {
	text-align: center;
	margin-top: 20px;
}

.filter-input {
	width: 100%;
	padding: 5px;
	padding-left: 5px;
	padding-right: 5px;
}

.filter-list {
	margin-top: 15px;
	padding: 20px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
		rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
	max-height: 80vh;
	overflow-y: scroll;
}

.filter-list > .head-1 {
	font-weight: 700;
	margin-bottom: 5px;
}

.filter-list > .list-container > .each-item:hover {
	transition: 0.2s ease-in-out;
	background-color: #33333310;
	border-radius: 500000px;
}

.filter-list > .list-container > .each-item {
	cursor: pointer;
	/* padding-bottom: 5px; */
	padding-top: 2.5px;
	padding-left: 15px;
	padding-bottom: 2.5px;
}

.filter-list > .list-container > .each-item:first-child {
	margin-top: 20px;
}

.filter-list > .list-container > .each-item.custome-key {
	margin-left: 25px;
}

.filter-list > .list-container > .each-item.custom-el {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 15px;
}

.adding-filter > .head-1 {
	margin-top: 20px;
	background-color: #1f1f1f50;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.adding-filter > .filter-body {
	padding: 15px;
	border-left: 1px solid #3f3f3f30;
	border-right: 1px solid #3f3f3f30;
	border-bottom: 1px solid #3f3f3f30;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.adding-filter > .filter-body > .options-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.adding-filter > .filter-body > .options-container > .each-option {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.adding-filter > .filter-body > .input-container > input {
	width: 100%;
	padding: 5px;
	padding-left: 5px;
	padding-right: 5px;
	border: 0px;
	border: 1px solid #3f3f3f;
	border-radius: 5px;
	outline: none;
}

.adding-filter > .filter-body > .input-container {
	margin-bottom: 20px;
	margin-top: 15px;
}

.adding-filter > .filter-body > .actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

.adding-filter > .filter-body > .actions > button {
	/* border: 0px; */
	/* background: transparent; */
	/* padding: 7px 20px; */
	margin-left: 15px;
	/* font-size: 14px; */
}

.adding-filter > .filter-body > .actions > .cancel {
	background-color: #e0e0e0;
	border-radius: 5px;
	color: black;
}

.adding-filter > .filter-body > .actions > .apply {
	background-color: #33d7db;
	border-radius: 5px;
}

.dropdown-div {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.dropdown-div .dropdown-inner-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropdown-div .actions {
	display: flex;
	gap: 20px;
	justify-content: right;
}

.adding-filter > .filter-body > .each-filter-value {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 10px;
	border: 1px solid #a0a0a0;
	border-radius: 5px;
	margin-bottom: 5px;
	cursor: pointer;
}

.adding-filter > .filter-body > .each-filter-value > .each-value {
	font-size: 14px;
}

.custom-filter-container {
	padding: 10px 5px;
	display: flex;
	flex-wrap: wrap;
}

.filter-badge {
	display: inline-flex;
	gap: 10px;
	align-items: center;
	background-color: #f0f0f0;
	border: 1px solid #d1d1d1;
	color: #555;
	border-radius: 20px;
	padding: 6px 12px;
	font-size: 14px;
	margin-bottom: 10px;
	cursor: pointer;
	font-family: Arial, sans-serif;
	white-space: nowrap;
}

.custom-filter-container.dashboard-filter {
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	font-family: 'League-Spartan';
	/* padding-left: 50px;
	padding-right: 100px; */
	margin: auto;
	width: 90%;
	gap: 10px;
}

.custom-filter-container > .each-selected-filter {
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 111111px;
	border: 1px solid #a0a0a0;
	padding: 4px 15px;
	font-family: 'League-Spartan';
	font-size: 16px;
	cursor: pointer;
	overflow-wrap: anywhere;
	background-color: #efefef70;
}

.custom-filter-container.dashboard-filter > .each-selected-filter {
	margin-right: 20px;
}

.custom-filter-container > .each-selected-filter > div {
	width: 90%;
}

.custom-filter-container.dashboard-filter > .each-selected-filter > div {
	width: 100%;
}

.custom-filter-container > .each-selected-filter > svg {
	width: 24px;
}

.filter-modal-drawer > .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
	width: 400px;
}

.filter-action-container {
	margin-top: 20px;
}

.filter-action-container > button {
	width: 100%;
	margin-bottom: 10px;
}

.filter-action-container > button > span {
	padding-left: 20px;
}

.filter-action-container > .download {
	margin-right: 20px;
	color: #333333;
	background-color: #33d7db;
	border-radius: 5px;
	border: 0px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-action-container > .show {
	color: #333333;
	background-color: #33d7db;
	border-radius: 5px;
	border: 0px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.filter-search-input .ant-input-group {
	width: 310px;
}

.filter-modal-container {
	width: 330px;
	padding: 0;
	overflow-y: auto;
}

.filter-list {
	margin-top: 10px;
	padding: 0px;
	border-radius: 5px;
	box-shadow: none;
	overflow: auto;
}

.filter-select-items {
	height: 30px;
	padding: 5px 10px;
	font-family: 'League Spartan';
	font-size: 16px;
	cursor: pointer;
	text-transform: capitalize;
	border-radius: 5px;
}

.filter-select-items:hover {
	transition: 0.3s ease-out;
	background-color: #33333310;
}

.filter-modal-container .ant-card .ant-card-body {
	padding: 15px;
}

.assets-modal {
	display: flex;
	flex-direction: column;
}

.filter-add-column .ant-modal-content {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	width: 1000px;
}

.radio-values-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.radio-values-row > .each-option {
	display: flex;
	cursor: pointer;
}

.radio-container > .actions {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	margin-left: 40%;
}

@media only screen and (max-width: 1000px) {
	.filter-add-column .ant-modal-content {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.filter-assets-container {
		padding: 20px 10px;
		gap: 20px;
		flex-direction: column;
	}

	.filter-buttons-container > button {
		margin-right: 4px;
	}

	.filter-search-input {
		margin-right: 5px;
	}

	.filter-modal-container {
		width: 100%;
	}

	.upload-options-containers {
		flex-direction: column;
	}

	.upload-excel,
	.upload-manually {
		margin: auto;
	}
}
