.qr-scanner-div .qr-reader {
    /* width: 430px; */
    /* height: 100vh; */
    /* width: 330px;
    height: 430px; */
    height: 100%;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
}

.qr-scanner-div .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.qr-scanner-div .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-scanner-div .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
}

.qr-scanner-div .scanner-div {
    /* width: 350px; */
    width: 95%;
    height: 450px;
    background-color: #FFF6F7;
    border-radius: 20px;
    margin: 0 auto;
}

.qr-scanner-div .scanner-div img {
    width: 100%;
    height: 100%;
}

.qr-scanner-div .qr-main-div {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 auto;
    text-align: center;
}

.qr-scanner-div .qr-btn {
    background: linear-gradient(90deg, #10b0a0, #54f1a1);
    outline: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 0;
    margin: 0 auto;
    width: 50%;
}