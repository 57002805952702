.cart-container {
	background-color: white;
	margin-bottom: 65px;
}
.cart-container > .prod-title {
	border: none;
	background-color: #f9f9f9;
	border-bottom: 3px solid #33d7db;

	font-size: 18px;
	font-weight: 500;
	margin-top: 50px;
	margin-left: 45px;
	margin-bottom: 35px;
	padding-bottom: 15px;
	padding-left: 50px;
	padding-right: 50px;
}

.cart-container > div > .heading {
	font-size: 36px;
	font-weight: 800;
	color: #333333;

	margin-top: 30px;
	margin-left: 170px;
}

.item-order {
	display: flex;
}

.items-table {
	border-top: 0.5px solid #808080;
	border-bottom: 0.5px solid #808080;
	border-collapse: collapse;
	margin-top: 50px;
	margin-left: 90px;
	margin-bottom: 30px;
	margin-right: 50px;
}

.items-table > thead > tr > th {
	font-size: 16px;
	font-weight: 800;
	color: #555555;
	padding-right: 70px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: left;
	padding-left: 20px;
}

.items-table > tbody > tr > td {
	border-top: 0.5px solid #808080;
	border-collapse: collapse;
	padding-right: 50px;
	font-size: 14px;
	font-weight: 800;
	color: #333333;
	padding-left: 20px;
}

.items-table > tbody > tr > td > .product-image {
	display: flex;
}

.product-image > .device-details {
	margin-left: 15px;
}

.product-image > .device-details > .device-name {
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	padding-top: 15px;
}

.product-image > .device-details > .device-color {
	font-size: 12px;
	font-weight: 500;
	color: #333333;
	padding-top: 15px;
}
.product-image > .device-details > .device-memory,
.product-image > .device-details > .device-storage {
	font-size: 12px;
	font-weight: 500;
	color: #555555;
	padding-top: 15px;
}

.order-summary {
	background: #ebffff;
	padding: 25px 25px;
	margin-left: 100px;
	margin-top: 50px;

	font-size: 16px;
	font-weight: 800;
	color: #555555;
}

.order-summary > .item-summary,
.order-summary > .ship-summary,
.order-summary > .total-cost {
	display: flex;
	justify-content: space-around;
	padding-top: 20px;

	font-size: 14px;
	font-weight: 800;
	color: #333333;
}

.order-summary > .item-summary > .prod-cost,
.order-summary > .total-cost > .bill {
	padding-left: 50px;
}
.order-summary > .ship-summary > .ship-cost {
	padding-left: 125px;
}

.order-summary > hr {
	border: 1px solid #888888;
	margin-top: 20px;
}

.order-summary > .checkout {
	background: #33d7db;
	box-shadow: 0px 2px 6px rgba(136, 136, 136, 0.5);
	border-radius: 5px;
	border: none;

	font-size: 16px;
	font-weight: 800;
	color: #333333;

	padding: 11px 42px;
	margin-top: 20px;
	margin-left: 30px;
}

.order-summary > input {
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 30px;
	padding-left: 15px;
	padding-top: 12px;
	padding-bottom: 12px;

	font-size: 16px;
	font-weight: 400;
	color: #aaaaaa;
	text-transform: capitalize;
}

.shopping-cart > .heading {
	font-size: 26px;
	font-weight: 800;
	color: #333333;

	margin-top: 30px;
	margin-left: 150px;
}

.shopping-cart > .name-container {
	display: flex;
	margin-left: 120px;
}

.name-container > .first-name {
	margin-right: 50px;
}

.name-container > .first-name > .container-heading,
.name-container > .last-name > .container-heading {
	font-size: 21px;
	font-weight: 800;
	color: #333333;

	padding-left: 12px;
	margin-top: 30px;
}

.name-container > .first-name > input,
.name-container > .last-name > input {
	background: #ffffff;
	border: 0.5px solid #555555;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 70px;
}

.shopping-cart > .address,
.shopping-cart > .landmark {
	font-size: 21px;
	font-weight: 800;
	color: #333333;

	padding-left: 12px;
	margin-left: 120px;
	margin-top: 25px;
}

.shopping-cart > input {
	background: #ffffff;
	border: 0.5px solid #555555;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 10px;
	margin-left: 120px;

	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 380px;
}

.shopping-cart > .address-type {
	font-size: 24px;
	font-weight: 800;
	color: #333333;

	margin-left: 120px;
	padding-left: 12px;
	margin-top: 55px;
}

.address-type-container {
	display: flex;
	margin-left: 120px;
	margin-top: 25px;
	margin-bottom: 45px;
}
.address-type-container > .addr-type-item {
	display: flex;
	margin-right: 50px;
}
.address-type-container > .addr-type-item > .addr-item {
	font-size: 21px;
	font-weight: 500;
	color: #333333;
}

.radio-icon {
	width: 22px;
	height: 22px;
	margin-right: 5px;
	color: #33d7db;
}

.save-changes {
	display: flex;
	margin-left: 65px;
	padding-bottom: 100px;
}

.save-changes > .yes,
.save-changes > .no {
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	border: none;
	padding: 15px 55px;
	margin-left: 55px;
	border-radius: 5px;
}

.save-changes > .yes {
	background-color: #33d7db;
}

.save-changes > .no {
	background-color: #cacaca;
}

.cart-addr {
	margin-top: 50px;
	margin-left: 120px;
}
.cart-addr > .cart-login,
.cart-addr > .usr-addr {
	background: #ffffff;
	border: 0.5px solid #888888;
	box-sizing: border-box;
	border-radius: 5px;

	display: flex;
	margin-top: 25px;
	padding-bottom: 25px;
	justify-content: space-between;
}

.cart-details > .box-heading {
	display: flex;
}

.cart-details > .box-heading > img {
	padding: 20px;
}

.cart-details > .box-heading > .addr-heading {
	font-size: 21px;
	font-weight: 800;
	color: #888888;

	padding-left: 20px;
	padding-top: 20px;
}

.cart-details > .usr-details,
.cart-details > .addr-details {
	display: flex;
	margin-left: 30px;
}

.cart-addr > .cart-login > .cart-details > .usr-details > .usr-name,
.cart-addr > .cart-login > .cart-details > .usr-details > .usr-phno,
.cart-addr > .cart-login > .cart-details > .usr-details > .usr-email,
.cart-addr > .usr-addr > .cart-details > .addr-details > .usr-address {
	font-size: 16px;
	font-weight: 800;
	color: #333333;

	padding-left: 55px;
}

.cart-edit > button {
	font-size: 18px;
	font-weight: 800;
	color: #333333;

	background-color: #f3f3f3;
	border-radius: 5px;
	border: none;

	margin-top: 40px;
	margin-right: 33px;
	padding: 9px 20px;
	margin-left: 30px;
}
