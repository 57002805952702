.privacy-container {
	padding: 30px 5%;
}

.privacy-container,
.terms-container {
	color: #3f3f3f;
	margin-top: 120px;
}

.privacy-container > h1,
.terms-container > h1 {
	color: #1f1f1f;
}

.address-width-trim {
	max-width: 40%;
	margin: 10px 0px;
}

.privacy-container > div {
	font-weight: bold;
	color: #3f3f3f;
}
