.search-office-container {
	margin-top: 30px;
}

.search-office-container>.create-new-container {
	margin-top: 10px;
	color: #666;
	font-family: League Spartan;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 5px;
}

.search-office-container>.create-new-container>.highlighter {
	color: #33d7db;
}

.new-asset-searchoffice .ant-descriptions .ant-descriptions-item-content,
.new-asset-searchoffice .ant-descriptions .ant-descriptions-item-label {
	font-size: 17px;
}

.search-office-container>.search-office-select {
	width: 40%;
	margin-bottom: 5px;
	margin-top: 10px;
}

.search-office-container>.head-1 {
	color: #000;
	/* font-family: Avenir; */
	font-family: "League-Spartan";
	/* font-size: 14px; */
	font-size: 17px;
	font-style: normal;
	/* font-weight: 500; */
	line-height: normal;
	margin-bottom: 10px;
}