.header-activity {
	background-color: #f9f9f9;
	padding-bottom: 20px;
}

.header-activity > div {
	margin-left: 40px;
	margin-right: 40px;
	padding-top: 40px;
	border-bottom: 2px solid #808080;
}

.activity-table-container {
	margin-left: 100px;
	margin-right: 100px;
	margin-top: 50px;
}

.activity-table-head.MuiTableHead-root {
	z-index: 0;
	position: relative;
}

.activity-table-head.MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root {
	background-color: #33d7db;
	color: #3f3f3f;
	font-weight: bold;
}
