.error-page-container > .error-page-body > img {
	width: 90vw;
	margin-top: 50px;
	height: 50vh;
	object-fit: contain;
}

.error-page-container {
	background-color: rgb(249, 249, 249);
}

.error-page-container > .error-page-body {
	width: 95vw;
	text-align: center;
	padding-bottom: 100px;
}

.error-page-container > .error-page-body > .p {
	font-size: 30px;
	font-weight: 800;
	color: rgb(51, 51, 51);
	padding-top: 10px;
}

.error-page-container > .error-page-body > .h4 {
	font-weight: 400;
	font-size: 24px;
	color: rgb(51, 51, 51);
	margin-top: 28px;
}

.error-page-container > .error-page-body > button {
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	color: #333333;
	width: 250px;
	height: 50px;
	border-radius: 5px;
	background: #5efba1;
	border: 1px white;
	margin-top: 48px;
}
