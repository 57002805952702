.mydevices-container > .heading {
	font-size: 25px;
	font-weight: 800;
	color: #333333;
	margin-top: 30px;
	margin-left: 35px;
}

.dev1 {
	margin-left: 4%;
	margin-top: 20px;
	margin-right: 4%;
	display: flex;
	flex: 0;
	border-radius: 5px;
	box-shadow: 0 0 10px #e1e1e1;
}
.dev-img {
	background-color: #3fd7da;
}

.dev-img > img {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 20px;
	padding-bottom: 10px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.dev-details > div {
	font-size: 15px;
	font-weight: 400;
	color: #333333;
	margin-left: 15px;
	margin-right: 10px;
	margin-top: 7px;
	text-align: left;
}

.dots {
	color: #555555;
	margin-top: 10px;
}

.mydevices-container > button {
	border: none;
	background: linear-gradient(90deg, #55fc9d 0%, #54f1a1 100%);
	border-radius: 5px;

	font-size: 24px;
	font-weight: 500;
	color: #333333;
	padding: 10px 25%;

	margin-top: 40px;
	margin-bottom: 30px;
}
