.getstarted-container {
	overflow-x: hidden;
	padding-bottom: 80px;
}

.getstarted-container > .logo {
	margin-top: 30px;
	margin-left: 30px;
}

.getstarted-container > .tagline {
	color: #333333;
	font-size: 30px;
	font-weight: 400;
	padding: 30px 30px;
}

.getstarted-container > .login {
	border: none;
	background-color: #55fc9d;
	border-radius: 5px;

	color: #333333;
	font-size: 14px;
	font-weight: 500;
	padding: 8px 60px;
	margin-top: 50px;
	margin-left: 120px;
}

.getstarted-container > hr {
	border: 1px solid #555555;
	margin: 25px;
	width: 75%;
}

.getstarted-container > .ps {
	font-size: 20px;
	font-weight: 400;
	color: #555555;
	text-align: center;
}

.getstarted-container > .note {
	font-size: 20px;
	font-weight: 800;
	color: #555555;
	text-align: left;
	margin-left: 30px;
	margin-top: 20px;
}

.getstarted-container > button {
	border: none;
	background-color: #3fd7da;
	border-radius: 5px;

	color: #ffffff;
	font-size: 28px;
	font-weight: 500;
	padding: 8px 60px;
	margin-top: 40px;
	margin-left: 60px;
}
