.contactus-screen {
	background-color: white;
	margin-top: 120px;
}

.contactus-screen > .heading {
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #333333;
}

.contactus-screen > .heading.part-1 {
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
	text-align: center;
	color: #333333;
	padding-top: 30px;
}

.contactus-screen > .heading.part-2 {
	width: 50%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px;
}

.contactus-screen > .container {
	display: flex;
	padding: 40px;
	justify-content: space-around;
}

.contactus-screen > .container > .contactus-form > .heading1 {
	font-weight: 800;
	font-size: 24px;
	line-height: 33px;
	text-align: center;
	color: #333333;
	margin-bottom: 20px;
}

.contactus-screen > .container > img {
	width: 30vw;
	object-fit: contain;
}

.contactus-form > .input-container > input,
.contactus-form > .input-container > textarea {
	border: 0px;
	outline: none;
	width: 45vw;
	color: #3f3f3f;
	padding-left: 5px;
	max-width: 250px;
}

.contactus-form > .input-container {
	border: 1px solid #d0d0d0;
	margin-bottom: 10px;
	padding: 5px 10px;
	display: flex;
	align-items: flex-start;
}

.contactus-form > .input-container > svg {
	color: #3f3f3f;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.contact-button {
	width: 100%;
	background-color: #66f9a4;
	color: #000;
	border: 0px;
	padding: 10px 0px;
	border-radius: 5px;
	font-size: 18px;
	text-transform: capitalize;
}

.alert-container {
	padding: 40px;
	text-align: center;
}

.alert-container > .ok-button {
	padding: 10px 40px;
	margin-top: 20px;
	background-color: #66f9a4;
	border: 0px;
	border-radius: 5px;
}

.connect-info {
	padding: 30px 10%;
}

.connect-info > div:first-child {
	font-size: 30px;
	line-height: 41px;
	color: #333333;
}

.connect-info > div:nth-child(2) {
	font-weight: 400;
	font-size: 21px;
	line-height: 29px;
	color: #333333;
}

@media only screen and (max-width: 600px) {
	.contactus-screen > .heading.part-2 {
		width: 90%;
	}
	.contactus-screen > .container {
		flex-direction: column;
	}
	.contactus-screen > .container > img {
		width: 85vw;
	}
	.contactus-screen > .container > .contactus-form > .heading1 {
		padding-top: 40px;
	}
}
