.aboutus-content {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.aboutus-content > .head-1 {
	font-size: 48px;
	line-height: 60px;
	width: 30%;
	padding-right: 20%;
}

.aboutus-content > .content {
	font-size: 22px;
	line-height: 30px;
	width: 50%;
}

.terms-container > .header-about-us {
	font-size: 48px;
	line-height: 60px;
	text-align: center;
	margin-bottom: 80px;
}
