.dashboard-container > .page-heading {
	font-size: 24px;
	font-weight: 800;
	color: #333333;

	margin-top: 30px;
	margin-left: 55px;
}

.dashboard-container > .tickets {
	display: flex;
	margin-top: 25px;
	margin-left: 50px;
}

.tickets > .body-box {
	border: none;
	width: 258px;
	margin-right: 21px;
	padding-left: 20px;
	padding-top: 20px;
	padding-bottom: 22px;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
}

.tickets > .body-box > .img {
	margin-bottom: 15px;
}

.tickets > .body-box > .box-name {
	padding-top: 10px;
	font-size: 16px;
	font-weight: 400;
	color: #333333;
}

.tickets > .body-box > .box-value {
	font-size: 36px;
	font-weight: 800;
	color: #33d7db;
	padding-top: 10px;
}

.each-status > .ticket-status {
	font-size: 21px;
	font-weight: 500;
	color: #333333;
	padding: 12px 16px;
	padding-right: 90px;
}

.each-status > .icon-count {
	display: flex;
	justify-content: space-around;
	padding-left: 15px;
	padding-bottom: 50px;
}
.each-status > .icon-count > img {
	width: 36px;
	height: 36px;
	padding-top: 8px;
}

.each-status > .icon-count > .count {
	font-size: 46px;
	font-weight: 400;
	color: #333333;
	padding-left: 40px;
}
.each-status > .count {
	font-size: 46px;
	font-weight: 400;
	color: #333333;
	padding-left: 170px;
}

.page-body {
	display: flex;
	margin-top: 50px;
	margin-left: 50px;
}

.row1,
.row2 {
	display: grid;
	grid: 100%/ 50% 50%;
	grid-auto-flow: column;
}

.row2 {
	margin-top: 21px;
}

.page-right {
	background-color: #ffffff;
	padding-top: 18px;
	padding-left: 20px;
	border-radius: 21px;
	border: none;
	width: 258px;
	box-sizing: border-box;
}

.page-right > .right-title {
	font-size: 21px;
	font-weight: 400;
	color: #333333;
	padding-right: 70px;
}

.page-right > .name-priority {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	padding-right: 20px;
}

.name-priority > .name {
	font-size: 18px;
	font-weight: 500;
	color: #333333;
}

.name-priority > .priority1 {
	font-size: 14px;
	font-weight: 500;
	color: #eb4444;
	padding-right: 30px;
}

.name-priority > .priority2 {
	font-size: 14px;
	font-weight: 500;
	color: #f5a937;
	padding-right: 30px;
}

.name-priority > .priority3 {
	font-size: 14px;
	font-weight: 500;
	color: #6def58;
	padding-right: 30px;
}

.ticket-table {
	margin-left: 20px;
	margin-top: 20px;
	display: flex;
}

.this-week,
.ticket-board,
.leaderboard,
.weekly-chart {
	background-color: #ffffff;
	margin-right: 21px;
	border-radius: 21px;
	height: 390px;

	border: none;
	width: 537px;
	box-sizing: border-box;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
}

.ticket-board > .heading,
.this-week > .heading,
.leaderboard > .heading,
.weekly-chart > .heading {
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 356px;
	font-weight: 100;
	font-size: 24px;
}

.ticket-board > .heading {
	padding-bottom: 30px;
}

.ticket-table > .table-name {
	font-size: 16px;
	font-weight: 500;
	color: #333333;
}
.ticket-table > .table-issue {
	padding-left: 80px;
	text-align: left;
	font-size: 16px;
	font-weight: 500;
	color: #333333;
}

.ticket-table > .table-priority {
	color: #5efaa0;
	padding-left: 80px;
	font-size: 16px;
	font-weight: 500;
}
.ticket-table > .table-priority1 {
	color: #eb4444;
	margin-left: 80px;
	font-size: 18px;
	font-weight: 500;
}

.leaderboard > table > thead > tr > th {
	color: #3fd7da;
	font-size: 14px;
	font-weight: 400;
	padding-left: 40px;
	padding-right: 40px;
}

.leaderboard > table > tbody > tr > td {
	font-size: 18px;
	font-weight: 500;
	color: #333333;
	padding-top: 25px;
	text-align: center;
}

.leaderboard > table {
	margin-top: 20px;
	padding-bottom: 50px;
}

.download-report {
	border: none;
	background-color: #3fd7da;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	margin-top: 40px;
	padding: 10px 40px;
	margin-left: 40%;
}
