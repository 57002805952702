.dashboard-container > .management-header {
	padding: 20px 5% 30px 5%;
	display: flex;
}

.dashboard-container {
	background-color: #ececec;
	padding-bottom: 10%;
}

.dashboard-container > .management-header > div {
	font-size: 24px;
	margin-right: 30px;
	color: #aaa;
	cursor: pointer;
	font-weight: 800;
}

.dashboard-container > .management-header > div.active {
	color: #333;
}
