.asset-dashboard {
	background: #f9f9f9;
	padding-bottom: 30px;
	/* padding-right: 20px; */
}

.asset-dashboard > .dashboard-body > .dashboard-heading {
	display: flex;
	margin-left: 30px;
	margin-right: 30px;
	padding-top: 10px;
	border-bottom: 0.5px solid #808080;
	padding-bottom: 30px;
	align-items: baseline;
	justify-content: space-between;
}

.asset-dashboard > .dashboard-body > .dashboard-heading > .name-1 {
	font-size: 36px;
	font-weight: 800;
	color: #333333;
	padding-left: 20px;
	padding-right: 9px;
}

.asset-dashboard > .dashboard-body > .dashboard-heading > .slash {
	color: #888888;
	font-size: 36px;
	padding-right: 9px;
}

.asset-dashboard > .dashboard-body > .dashboard-heading > .name-2 {
	font-size: 21px;
	font-weight: 400;
	color: #555555;
}

.asset-dashboard > .dashboard-body > .body {
	margin-left: 50px;
	/* margin-right: 5vw; */
	margin-top: 70px;
	display: flex;
	/* flex-wrap: wrap; */
	/* flex-direction: column; */
	justify-content: space-between;

	width: 95%;
	margin: 60px auto;
}

.dashboard-body > .body > .dashboard-left {
	/* margin-right: 3vw; */
	padding: 0 10px;
	/* margin: auto; */
	margin-left: auto;
}

.dashboard-body > .body > .dashboard-right {
	/* margin-right: 30px; */
	margin: 0 auto;
}

.dashboard-body > .body > .dashboard-left > .left-1 {
	width: 100%;
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	flex-wrap: wrap;
}

.stock-return {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}

.body > .dashboard-left > .left-2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	/* margin-top: 35px; */
	margin: 40px auto;
}

.body-box {
	/* width: 13.5vw; */
	width: 185px;
	padding-left: 20px;
	padding-top: 20px;
	padding-bottom: 10px;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
	margin-bottom: 10px;
}

/* .body-box:nth-child(1),
.body-box:nth-child(2),
.body-box:nth-child(3) {
	margin-right: 3vw;
} */

.body-box > div > div > .img {
	margin-bottom: 10px;
}

.body-box > div {
	display: flex;
	padding-right: 15%;
	justify-content: space-between;
}

.box-value {
	font-size: 36px;
	font-weight: 900;
	color: #33d7db;
	padding-top: 10px;
}

.asset-type-name {
	text-align: left;
	text-transform: capitalize;
}

.box-name {
	padding-top: 10px;
	font-size: 16px;
	font-weight: 800;
	color: #333333;
}

.left-2 > .run-maintenance {
	width: 13.5vw;

	padding-top: 20px;
	box-sizing: border-box;
	border: 1px solid #e0e0e0;

	width: 13.5vw;
	background: #5efba1;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;

	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 30px;
}

.assettype-table-container {
	margin: auto;
	padding-left: 1em;
	padding-right: 1em;
	max-height: 50vh;
	overflow-y: scroll;
	margin-left: auto;
	margin-right: auto;
}

.left-2 > .non-it::-webkit-scrollbar {
	display: none;
}

.upcoming-joinee-container {
	padding-bottom: 21vh;
}

.non-it.alignement-2 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 26vw;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
	/* padding-left: 20px; */
	/* padding-right: 20px; */
	padding-bottom: 30px;
	padding-top: 15px;
	text-align: center;
	/* max-height: 63vh; */
	max-height: 56vh;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* margin-top: 20px; */
}

.left-2 > .non-it {
	width: 26vw;

	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;

	/* padding-left: 20px; */
	/* padding-right: 20px; */
	padding-bottom: 30px;
	padding-top: 15px;
	text-align: center;
	/* max-height: 63vh; */
	max-height: 56vh;
	overflow-y: scroll;
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;
	/* margin-top: 20px; */
}

.run-maintenance > .heading {
	font-size: 17px;
	font-weight: 800;
	color: #333333;
}

.run-maintenance > img {
	margin: 15% 25%;
}

.run-maintenance > .note {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: #000000;
}

.run-maintenance > button {
	background: #ffffff;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 900;
	color: #000000;
	width: 100%;
	border: none;
	margin-top: 65px;
	padding: 5% 15%;
}

.body-centre {
	padding-right: 30px;
	margin-bottom: 30px;
	/* margin-left: 3vw; */
	margin-left: 0vw;
}

.upcoming-joinees {
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;

	/* margin-right: 3vw; */
	padding-left: 30px;
	padding-top: 20px;

	width: 35vw;
	width: 446px;
}

.upcoming-joinees > .heading {
	font-size: 20px;
	font-weight: 900;
	color: #333333;
	padding-bottom: 13px;
}

.upcoming-joinees > table > thead > tr > th {
	font-size: 15px;
	font-weight: 800;
	color: #33d7db;
	padding-bottom: 13px;
	padding-right: 3vw;
}

.upcoming-joinees > table > tbody > tr > td {
	font-size: 15px;
	font-weight: 500;
	color: #333333;
	padding-bottom: 10px;
	padding-right: 3vw;
}

@media only screen and (max-width: 1400px) {
	.upcoming-joinees > table > tbody > tr > td:nth-child(3) {
		padding-right: 0px;
	}
}

.non-it > .heading {
	font-size: 16px;
	font-weight: 900;
	color: #333333;
}

.non-it > .count {
	font-size: 36px;
	font-weight: 800;
	color: #33d7db;
	/* padding-top: 22px;
	padding-bottom: 16px; */
	padding-top: 12px;
	padding-bottom: 2px;
}

.non-it > .suffix {
	padding-top: 6px;
	font-size: 12px;
	font-weight: 500;
	color: #555555;
	padding-bottom: 40px;
}

.non-it > table > tbody > tr > td {
	font-size: 12px;
	font-weight: 500;
	color: #333333;
	padding-bottom: 6px;
	text-align: left;
}

.non-it > table > tbody > tr > td:first-child {
	padding-right: 3vw;
}

.dashboard-right > .right-top {
	padding: 20px;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
}

.dashboard-right > .right-top > .asset-health {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 0.5px solid #555555;
}

.dashboard-right > .right-top > .asset-health > .left > div > div {
	padding-top: 40px;
	font-size: 16px;
	font-weight: 900;
	color: #333333;
	padding-right: 5vw;
}

.dashboard-right > .right-top > .asset-health > .left {
	display: flex;
	justify-content: space-between;
}

.dashboard-right > .right-top > p {
	padding-top: 5px;
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	color: #000000;
}

.dashboard-right > .right-top > table > tbody > tr > td {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;

	/* identical to box height */

	color: #000000;
}

.dashboard-right > .right-top > table > tbody > tr > td:first-child {
	padding-right: 5vw;
}

.dashboard-right > .right-top > .health-score {
	display: flex;
}

.dashboard-right > .right-top > .health-score > p {
	font-size: 12px;
	font-weight: 900;
	color: #333333;
	padding-right: 6px;
}

.health-score > div {
	font-size: 12px;
	font-weight: 900;
	color: #5efba1;
	padding-top: 12px;
}

.right-top > button {
	background: #33d7db;
	border-radius: 5px;
	border: none;
	font-size: 12px;
	font-weight: 900;
	color: #000000;
	padding: 8px 80px;
	margin-top: 20px;
	width: 100%;
}

.right-bottom {
	margin-top: 40px;
	padding: 30px 20px;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 6px 6px 12px 5px rgba(136, 136, 136, 0.1);
	border-radius: 21px;
}

.right-bottom > .count-icon {
	display: flex;
	justify-content: space-between;
}

.right-bottom > .count-icon > .count {
	font-size: 36px;
	font-weight: 800;
	color: #33d7db;
}

.right-bottom > p {
	font-size: 21px;
	font-weight: 900;
	color: #333333;
}

.right-bottom > div {
	font-size: 12px;
	font-weight: 400;
	color: #888888;
}

.stock-return > .body-box > .box-name {
	cursor: pointer;
}

.comment-design {
	display: block;
	width: 100px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.body-box.hover {
	cursor: pointer;
}

@media only screen and (max-width: 1000px) {
	.asset-dashboard > .dashboard-body > .body {
		display: flex;
		flex-direction: column;
	}

	.dashboard-body > .body > .dashboard-left {
		padding: 0;
	}

	.dashboard-body > .body > .dashboard-left {
		margin: 0;
	}

	.asset-dashboard > .dashboard-body > .dashboard-heading {
		margin: 0 0;
	}

	.asset-dashboard > .dashboard-body > .body {
		margin-top: 20px;
		padding: 0px 25px;
	}

	.asset-dashboard .body-box {
		width: 180px;
		margin-top: 25px;
		margin-right: 0px;
	}

	.dashboard-body > .body > .dashboard-left > .left-1 {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.asset-dashboard {
		padding-right: 0;
	}

	.dashboard-body > .body > .dashboard-left {
		margin-right: 0;
	}

	.asset-dashboard .body > .dashboard-left > .left-2 {
		display: flex;
		flex-direction: column;
		margin-top: 35px;
	}

	.asset-dashboard .upcoming-joinees {
		margin-right: 0;
		padding: 15px 0;
		padding-left: 20px;
		width: 100%;
	}

	.asset-dashboard .body-centre {
		margin-left: 0;
		padding-right: 0;
	}

	.asset-dashboard .stock-return {
		display: flex;
		justify-content: space-around;
		margin-top: 30px;
	}

	.asset-dashboard .left-2 > .non-it {
		margin: 15px auto;
		width: 550px;
	}

	.asset-dashboard .assettype-table-container {
		margin: auto;
	}

	.asset-dashboard .asset-type-name {
		text-align: left;
	}

	.asset-dashboard .dashboard-body > .body > .dashboard-right {
		width: 550px;
		margin: auto;
	}

	.dashboard-right > .right-top > .asset-health {
		display: block;
	}
}

@media only screen and (max-width: 650px) {
	.asset-dashboard .body-box {
		width: 170px;
		margin-top: 15px;
	}

	.asset-dashboard .stock-return {
		justify-content: space-between;
	}

	.asset-dashboard .left-2 > .non-it {
		width: 100%;
	}

	.asset-dashboard .dashboard-body > .body > .dashboard-right {
		width: 100%;
	}

	.asset-dashboard > .dashboard-body > .body {
		padding: 0px 10px;
	}
}
