.diagnostic-container-screen {
	padding: 2% 5%;
	text-align: center;
}

.diagnostic-container-screen > h2.head-1 {
	color: #33d7db;
	text-align: left;
}

.diagnostic-container-screen > .head-2 {
	font-size: 20px;
	text-align: center;
	margin-bottom: 30px;
	color: #333333;
}

.diagnostic-container-screen > .know-more > .content-1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10%;
	margin-top: 60px;
}

.diagnostic-container-screen > .know-more > .content-1 > .each-feature {
	text-align: center;
}

.diagnostic-container-screen > .know-more > .content-1 > .each-feature > img {
	height: 150px;
	margin-bottom: 20px;
}
.diagnostic-container-screen > .know-more > .content-1 > .each-feature > .sub-head {
	color: #333333;
}

.diagnostic-container-screen > .diagnostic-button {
	margin-top: 50px;
	margin-bottom: 50px;
	background-color: #5efba1;
	color: #333333;
	font-size: 18px;
	padding: 10px 30px;
	border: 0px;
	border-radius: 5px;
}

.running-dgn-screen-container {
	padding: 2% 5%;
}

.running-dgn-screen-container > .head-1 {
	color: #33d7db;
}
.all-circles-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: 80px;
}

.all-circles-container > .each-circle {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: #5efba1;
	position: relative;
}
.all-circles-container > .each-circle > .content {
	position: absolute;
	top: 85px;
	/* left: -50%;
	text-align: center; */
	left: 50%;
	/* top: 50%; */
	transform: translate(-50%, -50%);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.all-circles-container > .each-circle > .content > img {
	height: 20px;
	margin-top: 10px;
	width: 20px;
}

.diagnostic-scale-container {
	position: relative;
}

.diagnostic-scale-container > .scale-highlight {
	position: absolute;
	height: 10px;
	background-color: #5efba1;
	width: 790px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	top: calc(50% - 5px);
}

.diagnostic-results-container {
	padding: 2% 5vw;
	text-align: center;
}

.diagnostic-results-container > .head-1 {
	color: #33d7db;
	text-align: left;
	font-size: 36px;
	font-weight: 900;
}

.diagnostic-results-container > .download-report {
	background-color: #5efba1;
	padding: 10px 30px;
	border: 0px;
	border-radius: 5px;
	color: #333333;
	margin-top: 50px;
}

.diagnostic-results-container > .results-body {
	background-color: white;
}

.diagnostic-results-container > .results-body > .result-row1 > .dev-diagnose,
.diagnostic-results-container > .results-body > .result-row2 > .asset-deprec,
.diagnostic-results-container > .results-body > .result-row3 > .total-spend {
	padding-top: 25px;
	border-bottom: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	text-align: left;
	padding-left: 40px;
	padding-bottom: 50px;
}

.diagnostic-results-container > .results-body > .result-row1 > .asset-health,
.diagnostic-results-container > .results-body > .result-row2 > .total-assets,
.diagnostic-results-container > .results-body > .result-row3 > .total-disposables {
	padding-top: 25px;
	border-bottom: 1px solid #cccccc;
	text-align: left;
	padding-left: 30px;
	padding-bottom: 50px;
}
.diagnostic-results-container > .results-body > .result-row3 > .total-disposables,
.diagnostic-results-container > .results-body > .result-row3 > .total-spend {
	border-bottom: none;
}

.asset-deprec > .title > .tagline {
	font-size: 18px;
	font-weight: 500;
	color: #888888;
	margin-top: 5px;
}

.item-heading {
	font-size: 30px;
	font-weight: 900;
	color: #333333;
}

.total-assets > .item-heading {
	margin-bottom: 10vh;
}

.dev-diagnose > .item-check > ul {
	font-size: 21px;
	font-weight: 500;
	color: #333333;
	padding-top: 35px;
}

.dev-diagnose > .item-check {
	display: flex;
}

.diagnostic-results-container > .results-body > .result-row1,
.diagnostic-results-container > .results-body > .result-row2,
.diagnostic-results-container > .results-body > .result-row3 {
	display: grid;
	grid: 100% / 50% 50%;
	grid-auto-flow: column;
}
.asset-health > .asset-health-items {
	display: flex;
}
.asset-health > .asset-health-items > .asset-health-percent {
	font-weight: 900;
	font-size: 50px;
	padding-top: 60px;
}
.asset-deprec > .details {
	display: flex;
	justify-content: space-evenly;
}

.asset-deprec > .details > .percent {
	font-size: 42px;
	font-weight: 900;
	color: #555555;
	padding-left: 40px;
	flex-grow: 1;
}

.asset-deprec > .details > .time-period {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	margin-right: 15px;
	padding-left: 10px;
	padding-right: 18px;
	padding-top: 5px;
	box-shadow: 4px 4px 12px 5px rgba(136, 136, 136, 0.1);
}

.time-period > button {
	border: none;
	background-color: #ffffff;
	padding-bottom: 12px;
	font-size: 18px;
	font-weight: 500;
	color: #888888;
}

.time-period > button:hover {
	color: #333333;
}

.LineChart {
	margin-left: 30px;
	background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(94, 251, 161, 0.06) 100%);
}
.total-assets > .it-body,
.total-spend > .spend-body,
.total-disposables > .disp-body {
	margin-top: 30px;
	display: flex;
}

.total-assets > .it-body > img {
	margin-top: 12px;
	height: fit-content;
}

.total-assets > .it-body > .it-table {
	margin-left: 3vw;
}

.total-assets > .it-body > .it-table > table > thead > tr > th {
	padding-left: 40px;
	font-size: 14px;
	font-weight: 800;
	color: #333333;
}
.total-assets > .it-body > .it-table > table > tbody > tr > td {
	padding-left: 50px;
	font-size: 18px;
	font-weight: 800;
	columns: #333333;
	padding-top: 15px;
}

.total-assets > .it-body > .it-table > hr {
	margin-top: 15px;
	margin-left: 30px;
	margin-right: 5px;
	margin-bottom: 15px;
	border: 1px solid #888888;
}

.total-spend > .spend-body > .spend-details > .spend-amount {
	font-size: 30px;
	font-weight: 900;
	color: #333333;
}

.total-spend > .spend-body > .spend-details > .spend-percent {
	font-size: 21px;
	font-weight: 500;
	color: #333333;
}

.total-spend > .spend-body > .spend-details,
.total-disposables > .disp-body > .total-disp-count {
	margin-left: 115px;
}
.total-disposables > .disp-body > .total-disp-count {
	font-size: 42px;
	font-weight: 900;
	color: #333333;
}
