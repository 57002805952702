.vendorFormHeading {
	background: #33d7db;
	color: #333333;
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 30px;
	padding: 15px;
	padding-left: 100px;
}

.descriptiveContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
	gap: 10px;
	margin: 30px 100px;
	min-height: 90vh;
	/* margin-top: 60px; */
}
.sectionDetailsContainer {
	display: flex;
	flex-direction: column;
}
.sectionName {
	background: #ccf8f9;
	width: max-content;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 32px;
	color: #555555;
	padding: 10px 20px;
}
.sectionDescription {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 28px;
	color: #888888;
	padding: 10px 20px;
}
.descriptiveForm {
	/* display: flex; */
	/* flex-direction: column; */
}
.descriptiveForm h4 {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 30px;
	color: #555555;
	padding: 5;
	margin-left: 5px;
	width: 85%;
	/* margin-top: 30px; */
}

.question-arrow {
	display: flex;
	align-items: center;
	gap: 50px;
	padding: 5px;
	margin-top: 10px;
	flex-direction: row;
	position: relative;
}
.responseError::placeholder {
	color: #c64343;
}
.question-arrow input {
	border: none;
	outline: none;
	border-bottom: 1px solid #888888;
	color: #888888;
	font-size: 21px;
	flex: 5;
}
.arrows {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -21px;
	font-size: 30px;
	color: #888888;
	flex: 2;
	gap: 30px;
}
.arrows button {
	border: none;
	outline: none;
}
.arrow_icon_container {
	max-height: 50px;
}
.moveUpIcon {
	position: absolute;
	top: -30px;
}
.moveDownIcon {
	position: absolute;
	top: 50px;
}

.next-btn {
	margin-top: 30px;
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 24px;
	border: none;
	outline: none;
	padding: 5px 30px;
	background: #277a7c;
	border-radius: 5px;
	color: #ffffff;
}

.responseErrorMessage {
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 21px;
	color: #c64343;
}
.uploadResponseError {
	color: #c64343;
}
.labelContainer {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	gap: 3px;
	flex: 5;
}

.labelContainer > div {
	display: flex;
	gap: 10px;
	background: #ffffff;
	border: 0.5px solid #888888;
	border-radius: 3px;
	padding: 5px 30px;
}
.responseChoices {
	min-width: 150px;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.selectedChoice {
	background-color: cyan !important;
}

.labelContainer label p {
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 14px;
	color: #555555;
	display: inline;
}
.uploadQuery {
	display: flex;
	gap: 30px;
	margin-top: 10px;
}
.nonUploadOptions {
	display: flex;
	gap: 10px;
	align-items: center;
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 14px;
	color: #555555;
}
.uploadLabel {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: start;
}
.uploadLabel > p {
	margin-top: 10px;
}

.radioArrows {
	margin-top: -30px;
}

.upload-btn {
	padding: 10px 30px;
	background: #33d7db;
	border-radius: 5px;
	border: none;
	outline: none;
}
.submit-btn {
	padding: 10px 30px;
	background: #01f89d;
	border-radius: 5px;
	border: none;
	outline: none;
	margin-left: 15vw;
}

.responseFormContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30vh;
}
.responseFormContainer h1 {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 30px;
	color: #555555;
}
.submit-img {
	width: 130px;
	height: 130px;
	margin-top: 30px;
}
.progressiveContainer {
	height: 68px;
	width: 450px;
	background: #ffffff;
	border: 0.5px solid #aaaaaa;
	border-radius: 5px;
	padding: 10px;
	color: #888888;
	margin-top: 30px;
}
.progressExtension {
	background: #f4f4f4;
	height: 8px;
	width: 430px;
	border-radius: 5px;
}
.progress {
	height: 8px;
	border-radius: 5px;
	margin-top: -10px;
	margin-right: 30px;
}
.certificationContainer {
	margin: 50px 100px;
	display: flex;
	flex-direction: column;
	gap: 30px;
}
.certificationName {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 42px;
	color: #555555;
}
.certificationDescription {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 30px;
	color: #888888;
}
.certificationInfo {
	display: flex;
	gap: 30px;
}
.certificationCheckbox {
	padding: 10px;
}
.certificationInfoText {
	width: 900px;
	padding: 10px;
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 21px;
	color: #888888;
}
.otpText {
	display: flex;
	flex-direction: column;
}
.otpText > input {
	border-bottom: 1px solid #888888;
	width: 900px;
	border-top: none;
	border-right: none;
	border-left: none;
	margin-left: 70px;
	outline: none;
}
.resendOtp {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.resendOtp p {
	margin-left: 70px;
	margin-top: 10px;
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 18px;
	color: #333333;
}
.resendOtp button {
	margin-right: 100px;
	cursor: pointer;
	border: none;
	outline: none;
}
.certificationSubmit {
	background: #5efba1;
	border: none;
	outline: none;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 30px;
	color: #333333;
	align-self: center;
	padding: 5px 30px;
}
.disabledButton {
	background: #999999;
	border: none;
	outline: none;
	border-radius: 5px;
	font-family: 'League Spartan';
	font-weight: 300;
	font-size: 30px;
	color: #333333;
	align-self: center;
	padding: 5px 30px;
}

.sectionCountIndex {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 21px;
	color: #555555;
	margin-bottom: 10px;
	margin-left: 10px;
}
.sectionCountIndex > span {
	background: #50efb5;
	border-radius: 3px;
	padding: 6px 12px;
	margin-left: 10px;
	font-size: 18px;
}
