.contact-outerdiv {
	display: flex;
	width: 80%;
	margin: 140px auto;
	font-family: 'League Spartan';
}

.contact-outerdiv .contact-leftdiv {
	width: 50%;
	font-size: 17px;
}

.contact-outerdiv .contact-leftdiv img {
	/* width: 100%; */
	height: 250px;
}

.contact-leftdiv p {
	margin: 15px 0;
}

.contact-rightdiv h5 {
	margin-bottom: 30px;
}

.contact-leftdiv h5 {
	margin: 20px 0;
}

.contact-outerdiv .contact-rightdiv {
	width: 50%;
}

.contact-infodiv .flexdiv {
	display: flex;
	align-items: center;
	margin-top: 15px;
}

.contact-infodiv .flexdiv .icons {
	padding-top: 5px;
	font-size: 18px;
	color: black;
}

.contact-infodiv .flexdiv .info {
	padding-left: 10px;
}

.contact-outerdiv .contact-rightdiv .formdiv {
	margin: auto;
	width: 75%;
}

.contact-outerdiv .contact-rightdiv Button {
	float: right;
	margin-top: 30px;
	background-color: #54f2b1 !important;
	border-color: #54f2b1 !important;
}

@media only screen and (max-width: 1000px) {
	.contact-outerdiv {
		width: 90%;
	}

	.contact-outerdiv .contact-rightdiv,
	.contact-outerdiv .contact-leftdiv {
		width: 100%;
	}

	.contact-outerdiv .contact-rightdiv .formdiv {
		width: 90%;
	}

	.contact-outerdiv .contact-leftdiv img {
		width: 100%;
		height: 300px;
	}
}

@media only screen and (max-width: 600px) {
	.contact-outerdiv {
		flex-direction: column;
		width: 90%;
		padding-top: 220px;
	}

	.contact-outerdiv .contact-rightdiv {
		margin-top: 30px;
	}

	.contact-outerdiv .contact-leftdiv {
		padding: 0px 15px;
	}

	.contact-outerdiv .contact-rightdiv,
	.contact-outerdiv .contact-leftdiv {
		width: 100%;
	}

	.contact-outerdiv .contact-rightdiv .formdiv {
		width: 90%;
	}

	h1 {
		font-size: 30px;
	}

	p {
		font-size: 18px;
	}

	.contact-outerdiv .contact-leftdiv img {
		width: 100%;
		height: 230px;
	}
}
