.search-invocice-container {
	width: 100%;
	margin-top: 30px !important;
	margin-top: 30px;
}

.search-invocice-container>.search-invoice-select {
	height: 35px;
	width: 50%;
}

.add-assest-container .create-new-container,
.search-invocice-container>.create-new-container {
	margin-top: 15px;
	color: #666;
	font-family: League Spartan;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 5px;
}

.add-assest-container .create-new-container .highlighter,
.search-invocice-container>.create-new-container>.highlighter {
	color: #33d7db;
	font-family: League Spartan;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
}

.new-asset-searchpo-div .ant-descriptions .ant-descriptions-item-content,
.new-asset-searchpo-div .ant-descriptions .ant-descriptions-item-label {
	font-size: 17px;
}

.search-invocice-container .invoice-select-div {
	width: 40%;
}

.search-invocice-container>.head-1 {
	color: #000;
	/* font-family: Avenir; */
	font-family: "League-Spartan";
	font-size: 17px;
	font-style: normal;
	/* font-weight: 500; */
	line-height: normal;
	margin-bottom: 10px;
}

@media only screen and (max-width:600px) {
	.search-invocice-container .invoice-select-div {
		width: 100%;
	}
}