table.assetsTable {
	/* width: 100%; */
	table-layout: fixed;
	overflow-wrap: break-word;
	border-collapse: collapse;
	/* display: block; */
	overflow-x: auto;
	/* white-space: nowrap; */
	margin: 0 auto;
}

.assetsTable > thead > tr {
	text-align: left;
}

.assetsTable > thead > tr > th {
	padding-right: 30px;
}

.assetsTable > tbody > tr > td:first-child,
.assetsTable > tbody > tr > th:first-child {
	padding-left: 10px;
}

.assetsTable > thead > tr > th {
	padding-bottom: 10px;
	position: sticky;
	top: 0;
	font-size: 16px;
	font-weight: 800;
	color: #333333;
	line-height: 22px;
	background-color: #ececec;
}

.assetsTable > tbody > tr > td:last-child,
.assetsTable > tbody > tr > th:last-child {
	padding-right: 60px;
}

.assetsTable > tbody > tr > td,
.assetsTable > tbody > tr > th {
	margin: 0;
	padding: 10px 10px;
	max-width: 150px;
	word-wrap: break-word;
	font-size: 14px;
	line-height: 18px;
	text-transform: capitalize;
	text-align: left;
	font-family: 'Avenir-Medium';
}

.assetsTable > tbody > tr:nth-child(even) {
	background-color: #f2f2f2;
}

.assetsTable > tbody > tr:nth-child(odd) {
	background-color: #ffffff;
}

.assets-table-container {
	margin-top: 60px;
	padding-bottom: 120px;
	/* overflow-y: scroll;
	height: 100vh; */
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
	width: 700px;
}

.assetsTable > tbody > tr:hover {
	background-color: #f5feff !important;
}

.each-assets-action > svg {
	margin-right: 10px;
	cursor: pointer;
}

.each-assets-action {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	/* transform: translate(0px, 70%); */
}

td.left {
	text-align: left !important;
}

.table-health-column {
	width: 200px;
}

.each-custom-field {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 5px;
	text-align: left;
}

.each-custom-field > .key::after {
	content: ':';
}

.each-custom-field > .key {
	text-transform: capitalize;
	font-size: 16px;
	padding-right: 10px;
}

.each-custom-field > .value {
	font-size: 14px;
}

.delete-asset-modal {
	padding: 20px;
	text-align: center;
	color: #3f3f3f;
}

.delete-asset-modal > .asset-actions {
	margin-top: 20px;
}

.delete-asset-modal > .asset-actions > button {
	border: 0px;
	background-color: transparent;
	margin-right: 10px;
	padding: 10px;
	border-radius: 5px;
	color: white;
	padding-left: 15px;
	padding-right: 15px;
	font-weight: 600;
}

.delete-asset-modal > .asset-actions > button.positive {
	background-color: #61dafb;
	color: #000;
}

.delete-asset-modal > .asset-actions > button.negative {
	background-color: #aaa;
}

.table-design-1 {
	margin-left: 5%;
	margin-right: 5%;
}

.table-action-assets {
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-action-assets > svg:first-child {
	margin-right: 10px;
}

.table-action-assets > svg {
	cursor: pointer;
}

.assets-table-container .ant-table-content {
	font-family: 'League Spartan';
	font-weight: 400;
	font-size: 16px;
	color: #333333;
	text-transform: capitalize;
}

.assets-table-container .ant-table-thead .ant-table-cell {
	font-family: 'League Spartan';
	font-weight: 500;
	font-size: 17px;
	color: #333333;
}
