@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.company-branch {
	font-family: 'League Spartan', sans-serif;
}

.company-branch-container {
	display: flex;
	/* flex-direction: row; */
	align-items: center;
	justify-content: space-between;
	margin: 10px 10px;
}

.company-branch .size-div {
	width: 90%;
}

.company-heading {
	/* color: #333;
	font-weight: 500;
	border-bottom: 3px solid #33d7db; */
	font-size: 20px;
	font-family: 'League Spartan';
	font-weight: 600;
	color: rgba(19, 18, 18, 0.838);
}

.search {
	display: flex;
	flex-direction: row;
	gap: 30px;
	align-items: center;
	justify-content: center;
}

.search-bar {
	background: #f6f6f6;
	color: #888888;
	font-weight: 400;
	padding: 10px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	width: 300px;
	height: 36px;
}

.search-bar input {
	border: none;
	outline: none;
	background: transparent;
	margin-left: 30px;
	font-size: 18px;
	font-weight: 300;
}

.font {
	font-size: 24px;
}

.add {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #33d7db;
	padding: 9px;
	color: #000;
	position: relative;
	height: 35px;
	width: 120px;
	border-radius: 5px;
}

.add button {
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	background: transparent;
	font-weight: 400;
	margin-top: 3px;
}

.company-branches-table {
	margin-top: 30px;
}

.company-branches-table Table {
	/* width: 90%; */
	/* margin: auto; */
}

.company-branches-table .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 13px;
	color: #000000;
	text-transform: capitalize;
}

.company-branches-table .ant-table-thead .ant-table-cell {
	background: #FAFAFA !important;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #555555;
}

.company-branch-container .search-input .ant-input-affix-wrapper {
	border-radius: 10px;
	width: 215px;
	height: 30px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
	box-shadow: none !important;
}

.table-container {
	margin-top: 40px;
}

.table-container table,
.companyHeader,
.companyData {
	border-bottom: 0.5px solid #888;
	border-collapse: collapse;
	padding: 10px;
	margin: 10px;
}

.table-container th {
	font-weight: 500;
	color: #888;
	width: 200px;
}

.table-container td {
	font-weight: 600;
	color: #555;
	width: 200px;
}

.locality {
	width: 300px;
}

.checkbox {
	color: #555;
}

.checkbox input {
	margin-right: 10px;
}

.edit-icon {
	margin-left: 10px;
}

.modal-form-container {
	padding: 10px;
	margin-right: 100px;
}

.modal-form-container .company-info {
	display: flex;
	flex-direction: row;
	/* align-items: center; */
}

.company-details {
	font-family: 'League Spartan';
	font-style: normal;
	flex: 2;
	margin-left: 30px;
}

.company-details h3 {
	font-weight: 500;
	font-size: 21px;
	color: #555;
}

.company-details p {
	font-weight: 300;
	font-size: 16px;
	color: #9dacac;
}

.company-id {
	display: flex;
	flex-direction: column;
	flex: 3;
	margin-left: 100px;
	width: 100%;
}

.branch {
	display: flex;
	flex-direction: column;
	height: 50px;
	width: 380px;
	border: 1px solid #9c9e9e;
	border-radius: 5px;
	width: 100%;
	/* z-index: 21; */
}

.branch span {
	margin-left: 5px;
	color: #9dacac;
	font-size: 12px;
}

.branch input {
	/* width: 100%;
  border: 1px solid #9c9e9e;
  border-radius: 5px;
  padding: 10px; */
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	color: #555;
}

.title {
	font-size: 1em;
	position: absolute;
	top: 50%;
	left: 15px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	white-space: nowrap;
	transition: all 0.2s;
	width: calc(100% - 15px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.address-info {
	display: flex;
	margin-top: 10px;
}

.address-info-details {
	font-family: 'League Spartan';
	font-style: normal;
	flex: 2;
	margin-left: 30px;
	width: 240px;
}

.address-info-details h3 {
	font-weight: 500;
	font-size: 21px;
	color: #555;
}

.address-info-details p {
	font-weight: 300;
	font-size: 16px;
	color: #9dacac;
}

.address-details {
	/* margin-left: -30px; */
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 3;
	margin-left: 100px;
}

.street-container {
	display: flex;
	gap: 10px;
}

.contact-info {
	display: flex;
	margin-top: 40px;
}

.info-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 3;
	margin-left: 100px;
}

.name-container {
	display: flex;
	gap: 10px;
}

.mobile-container {
	display: flex;
	gap: 10px;
}

.code {
	width: 15%;
}

.name-title {
	width: 18%;
}

.contact {
	width: 85%;
}

.select-one {
	border: 1px solid #9c9e9e;
	border-radius: 5px;
	padding: 10px;
	color: #555;
	font-size: 16px;
}

.btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 40px;
}

.save-btn {
	background: #5efba1;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	width: 100px;
	color: #333;
	font-size: 12px;
	font-weight: 400;
}

.cancel-btn {
	background: #f2f2f2;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	width: 100px;
	color: #333;
	font-size: 12px;
	font-weight: 400;
}

.company-branch-div .companyInfo-div Input {
	width: 380px;
}

.company-branch-div .contactInfo-div .name-div {
	display: flex;
	gap: 10px;
}

.company-branch-div .contactInfo-div .phone-no-div {
	display: flex;
	gap: 16px;
}

.drawer-mobile-btns {
	display: none;
}

@media only screen and (max-width:600px) {

	.company-branch-div .companyInfo-div Input {
		width: 100%;
	}

	.company-branch-div .contactInfo-div .name-div {
		gap: 3px;
	}

	.company-branch-div .contactInfo-div .phone-no-div {
		gap: 8px;
	}

	.ant-drawer .ant-drawer-body {
		padding: 15px;
	}

	.company-branch-div .companyInfo-div,
	.company-branch-div .addressInfo-div,
	.company-branch-div .contactInfo-div {
		flex-direction: column;
	}

	.company-branch-div .company-details,
	.company-branch-div .address-info-details {
		padding-top: 20px;
		margin-left: 0px;
	}

	.drawer-mobile-btns {
		margin-top: 40px;
		display: flex;
		justify-content: space-around;
	}

	.mobile-hide-btns {
		display: none;
	}

	.company-branch-div .company-details,
	.company-branch-div .address-info-details,
	.company-branch-div .company-details {
		margin-bottom: 10px;
	}

	.company-branch-container {
		flex-direction: column;
		align-items: normal;
	}

	.company-branch-container .search {
		justify-content: left;
	}

	.company-branch .size-div {
		width: 100%;
	}

}