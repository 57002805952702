.products-container {
	background-color: white;
	padding-top: 50px;
	padding-bottom: 100px;
}

.products-container > .landing-content {
	padding: 50px 10%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.products-container > .landing-content > .content > .head-1 {
	font-size: 60px;
	font-weight: bold;
	color: #333;
	margin-bottom: 20px;
}

.products-container > .landing-content > .content > .head-2 {
	font-weight: 400;
	font-size: 20px;
	margin-bottom: 20px;
}

.products-container > .landing-content > .content > button.shopnow {
	background-color: #33d7db;
	border: 0px;
	color: #333;
	padding: 10px 40px;
	border-radius: 5px;
	font-weight: 800;
}

.products-container > .rates-like {
	padding: 50px 10%;
	columns: #333;
}

.products-container > .rates-like > .head-1 {
	font-size: 50px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
}

.products-container > .rates-like > .head-2 {
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 50px;
}

.each-product-container {
	background-color: #cdffeb;
	padding: 10px;
	border-radius: 5px;
	/* width: 90%; */
	box-shadow: 0 0 10px #cdffeb;
	max-width: 342px !important;
}
.each-product-container > .image-container {
	background-color: white;
}

.each-product-container > .image-container > img {
	height: 200px;
	width: 200px;
	border-radius: 5px;
}

.each-product-container > .product-name {
	font-size: 12px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.each-product-container > .product-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.each-product-container > .product-actions > button {
	border: 0px;
	box-shadow: 0 0 10px #f1f1f1;
	background-color: #ffffff;
	padding: 10px 20px;
	border-radius: 20px;
}

.each-product-container > .product-actions > .product-actions {
	font-weight: 800;
	font-size: 18px;
}

.landing-content-2 {
	padding: 50px 10%;
}

.landing-content-2 > .head-1 {
	font-size: 48px;
	font-weight: 800;
	text-align: center;
}
.landing-content-2 > .contents {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}

.landing-content-2 > .contents > div {
	width: 28%;
	text-align: center;
}

.landing-content-2 > .contents > div > .head-1 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 10px;
}

.landing-content-2 > .contents > div > .content {
	text-align: left;
}

.product-options {
	display: flex;
	align-items: center;
	padding: 20px 5%;
}

.product-options > div {
	padding-right: 25px;
	padding-left: 25px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #aaaaaa;
	font-weight: 500;
}

.product-options > div.active {
	border-bottom: 3px solid #33d7db;
	color: #333333;
}

.saas-product-container {
	padding: 0 5%;
	padding-bottom: 10%;
	background-color: white;
	padding-top: 15px;
}

.saas-heading {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	margin-top: 50px;
}

.saas-heading > .name {
	padding-left: 20px;
	font-size: 20px;
	color: #555;
	font-weight: 800;
}

.saas-actions {
	margin-bottom: 30px;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.saas-actions > button {
	padding: 10px 50px;
	background-color: transparent;
	border: 0px;
	font-weight: 500;
	font-size: 18px;
}

.saas-actions > button:nth-child(1) {
	background-color: #e1e1e1;
	color: #333;
	margin-right: 20px;
}

.saas-actions > button:nth-child(2) {
	background-color: #33d7db;
	color: white;
	margin-right: 20px;
}

.saas-product-container > .head-1 {
	margin-top: 50px;
	font-weight: 800;
	color: #333;
	font-size: 32px;
	margin-bottom: 20px;
}

.saas-product-container > .head-2 {
	font-weight: 800;
	color: #333;
}

.saas-product-container > .head-2 > .number {
	font-size: 32px;
}

.it-service-container {
	background-color: white;
	padding: 50px 10%;
}

.it-service-container > .head-1 {
	font-weight: 400;
	font-size: 36px;
	line-height: 49px;

	color: #333333;
	text-align: center;
	margin-bottom: 10px;
}

.it-service-container > .head-2 {
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
	/* identical to box height */

	color: #333333;
	text-align: center;
	margin-bottom: 30px;
}

.it-service-container > .question-1 {
	font-weight: 400;
	font-size: 21px;
	line-height: 29px;
	color: #333333;
	margin-bottom: 10px;
}

.it-service-container > .question-1:last-child {
	padding-bottom: 50px;
	border-bottom: 2px solid #9f9f9f;
}

.it-service-container > .content {
	padding-top: 50px;
	display: flex;
	justify-content: space-around;
	padding-bottom: 50px;
	border-bottom: 2px solid #9f9f9f;
	margin-bottom: 50px;
}

.it-service-container > .content > .section-1 > img {
	height: 250px;
}

.it-service-container > .content > .section-1 > .head-3 {
	text-align: center;
	margin-top: 30px;
	font-weight: 800;
	font-size: 24px;
	line-height: 33px;
	/* identical to box height */

	color: #333333;
}

.it-service-container > .content > .section-1 > ul {
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;

	color: #333333;
}

.it-service-container > .service-form {
	width: 300px;
	margin: 0 auto;
}

.it-service-container > .service-form > .head-4 {
	font-weight: 800;
	font-size: 24px;
	line-height: 33px;
	/* identical to box height */

	color: #333333;
	text-align: center;
}
.it-service-container > .service-form > form {
	margin-top: 40px;
}

.it-service-container > .service-form > form > .input {
	display: block;
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #cacaca;
	box-sizing: border-box;
	border-radius: 5px;
}

.it-service-container > .service-form > form > button {
	width: 100%;
	background: #33d7db;
	border-radius: 5px;
	border: 0px;
	padding: 13px;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	/* identical to box height */

	color: #ffffff;
}
