.idfc-connect-image-1 {
	width: 40vw;
}

.idfc-concect-container {
	margin-top: 120px;
}

.idfc-concect-container > .section-1 {
	padding: 85px 10vw;
	display: flex;
	justify-content: space-between;
}

.idfc-concect-container > .section-1 > div {
	display: flex;
	flex: 1;
	flex-direction: column;
	box-sizing: border-box;
}

.idfc-concect-container > .section-1 > .left-part > .actions {
	display: flex;
	margin-top: 20px;
}

.idfc-concect-container > .section-1 > .left-part > h2.heading {
	font-weight: 400;
	font-size: 30px;
	line-height: 41px;

	color: #555555;
}

.idfc-concect-container > .section-1 > .left-part > div.paragraph {
	font-weight: 300;
	font-size: 21px;
	line-height: 30px;
	color: #555555;
	max-width: 80%;
}

.idfc-concect-container > .section-1 > .left-part > .actions > button {
	background-color: transparent;
	border: 0px;
	padding: 10px 30px;
	border-radius: 10000px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;

	color: #555555;
}

.idfc-concect-container > .section-1 > .left-part > .actions > button.get-started {
	background-color: #66f9a4;
	margin-right: 20px;
}

.idfc-concect-container > .section-1 > .left-part > .actions > button.request-demo {
	border: 1px solid #000000;
}

.idfc-concect-container > .section-2 {
	display: flex;
	padding: 20px 5vw;
	justify-content: space-between;
	margin-bottom: 30px;
}

.idfc-concect-container > .section-3 {
	padding: 20px 5vw;
}

.idfc-concect-container > .section-3 > .each-feature.reverse {
	flex-direction: row-reverse;
}

.idfc-concect-container > .section-3 > .each-feature {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.idfc-concect-container > .section-4 {
	margin: 0 auto;
	width: 600px;
	background-color: #c0fdda;
	border: 10px solid #5efba1;
	padding: 30px;
	margin-bottom: 40px;
	text-align: center;
}

.idfc-concect-container > .section-4 > .idfc-logo {
	margin-bottom: 30px;
}

.idfc-concect-container > .section-4 > .heading {
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
	color: #555555;
	margin-bottom: 20px;
}

.idfc-concect-container > .section-4 > .content {
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: #555555;
	margin-bottom: 18px;
}

.idfc-concect-container > .section-4 > button {
	background-color: white;
	border: 0px;
	padding: 15px 50px;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	/* or 133% */

	text-align: center;
	margin-top: 15px;
	color: #555555;
	border-radius: 1000px;
}

.idfc-concect-container > .section-3 > .each-feature > .image-container > img {
	width: 350px;
}

.idfc-concect-container > .section-3 > .each-feature > .content-container > .heading {
	font-weight: 500;
	font-size: 24px;
	line-height: 33px;
	color: #555555;
	margin-bottom: 20px;
}

.idfc-concect-container > .section-3 > .each-feature > .content-container > .heading.main {
	font-weight: 800;
	font-size: 48px;
	line-height: 66px;

	color: #555555;
}

.idfc-concect-container > .section-3 > .each-feature > .content-container > .info {
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	color: #555555;
}

.idfc-concect-container > .section-3 > .each-feature > div {
	/* display: flex; */
	/* flex-direction: column; */
	/* flex: 1; */
}

.idfc-concect-container > .section-3 > .each-feature > .image-container {
	width: 350px;
	margin-right: 40px;
}

.idfc-concect-container > .section-3 > .each-feature > .content-container {
	width: 350px;
	margin-left: 40px;
}

.idfc-concect-container > .section-3 > .each-feature.reverse > .image-container {
	margin-left: 40px;
	margin-right: 0px;
}

.idfc-concect-container > .section-3 > .each-feature.reverse > .content-container {
	margin-right: 40px;
	margin-left: 0px;
}

.idfc-concect-container > .section-2 > .each-feature {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0 20px;
	border: 1px solid #888888;
	border-radius: 5px;
	padding: 32px 20px;
	text-align: center;
}

.idfc-concect-container > .section-2 > .each-feature > .feature-img {
	height: 180px;
	margin: 0 auto;
}

.idfc-concect-container > .section-2 > .each-feature > .heading {
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
	text-align: center;
	color: #555555;
	margin-top: 20px;
	margin-bottom: 20px;
}

.idfc-concect-container > .section-2 > .each-feature > .info {
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: #555555;
	margin-bottom: 20px;
}

.community-review {
	width: 400px !important;
	border: 1px solid #9f9f9f;
	border-radius: 5px;
}

.community-review > .image-container {
	margin: 0 auto;
}

.community-review > .image-container > img {
	width: 360px;
	height: 350px;
	object-fit: cover;
	object-position: top;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	border-radius: 10px;
}

.community-review > .contents {
	background-color: #bcf5f6;
	padding: 20px;
	border-radius: 5px;
	margin-top: -20px;
	padding-top: 40px;
}

.community-review > .contents > .info {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #333333;
	margin-bottom: 30px;
}

.community-review > .contents > .company-info > .name {
	margin-bottom: 20px;
}

.idfc-concect-container > .section-5 {
	margin-bottom: 60px;
}

.idfc-concect-container > .section-5 > .heading {
	font-weight: 900;
	font-size: 36px;
	line-height: 49px;
	text-align: center;
	color: #333333;
	margin-bottom: 20px;
	margin-top: 60px;
}

.idfc-concect-container > .section-5 > .info {
	font-weight: 900;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #333333;
	margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
	.idfc-concect-container > .section-1 {
		padding: 5vw;
		flex-direction: column;
		text-align: center;
	}
	.idfc-concect-container > .section-1 > .left-part > div.paragraph {
		max-width: 100%;
	}
	.idfc-concect-container > .section-1 > .left-part > .actions {
		align-items: center;
		justify-content: center;
	}
	.idfc-connect-image-1 {
		width: 98vw;
		margin-top: 30px;
	}
	.idfc-concect-container > .section-2 {
		flex-direction: column;
	}
	.idfc-concect-container > .section-2 > .each-feature {
		margin-bottom: 20px;
	}
	.idfc-concect-container > .section-3 > .each-feature {
		flex-direction: column;
		text-align: center;
	}
	.idfc-concect-container > .section-3 > .each-feature.reverse > .image-container {
		margin-left: 0px;
	}

	.idfc-concect-container > .section-3 > .each-feature > .content-container {
		margin-top: 10px;
		margin-left: 0px;
	}
	.idfc-concect-container > .section-3 > .each-feature.reverse {
		flex-direction: column;
	}
	.idfc-concect-container > .section-3 > .each-feature.reverse > .content-container {
		margin-right: 0px;
		margin-left: 0px;
	}
	.idfc-concect-container > .section-4 {
		max-width: 80vw;
	}
	.community-review {
		max-width: 95vw;
	}
	.idfc-concect-container > .section-3 > .each-feature > .image-container {
		margin-right: 0px;
	}
}
