.each-custom-key-input {
	width: 200px;
	margin-right: 20px;
	margin-bottom: 10px;
}

.each-custom-input-container {
	display: flex;
	align-items: center;
}

.each-assest-row .editasset-custom-table {
	margin-bottom: 15px;
}

.each-assest-row .editasset-custom-table .ant-table-content {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	color: #555555;
}

.each-assest-row .editasset-custom-table .ant-table-thead .ant-table-cell {
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #555555;
}

.edit-asset-invoice {
	width: 100% !important
}

.edit-asset-antd-select {
	width: 40%;
}

.edit-asset-innerdiv {
	width: 80%;
	margin: auto;
}

.edit-asset-innerdiv input {
	font-size: 18px;
}

.edit-asset-innerdiv h4 {
	font-family: "League-Spartan";
	font-size: 1.4rem;
	font-weight: normal;
}

@media only screen and (max-width:600px) {
	.edit-asset-antd-select {
		width: 100%;
	}

	.edit-asset-innerdiv {
		width: 95%;
	}
}