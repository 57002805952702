.loading-bar {
	margin-top: 68px;
}

.fetch-details {
	font-size: 30px;
	font-weight: 400;
	color: #333333;

	text-align: center;
	margin-top: 250px;
}
