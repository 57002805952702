.header-div {
    position: relative;
    text-align: center;
    width: 100%;
    height: 60px;
    background-color: #33D7DB;
    color: white;
    padding: 10px 10px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
}

.header-div .icon-div {
    position: absolute;
    padding-top: 4px;
}