.dashboard-footer-container {
	height: 65px;
	background-color: #33d7db;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0px;
	width: 100%;
	z-index: 101;
}

.dashboard-footer-container > .each-footer-icon {
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px;
	position: relative;
	color: white;
	font-size: 32px;
	margin: 0px 30px;
	cursor: pointer;
}

.each-footer-icon.active {
	color: black;
	background-color: white;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	font-size: 35px;
	cursor: pointer;
}

.each-footer-icon ::after {
	position: absolute;
	top: 0px;
	height: 20px;
	background-color: red;
	width: 20px;
}
