.header-container {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 100px;
	margin-bottom: 20px;
	background-color: white;
}

.header-info {
	display: flex;
}

.header-info > .notifications {
	background-color: #e6e6e6;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: #4d4d4d;
	margin-right: 20px;
}

.header-info > .accounts-icon {
	background-color: #33d7db;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #000000;
	cursor: pointer;
}

.accounts-icon > .content {
	/* position: absolute; */
	background-color: white;
	top: 50px;
	right: 0px;
	z-index: 200;
	/* box-shadow: 0 0 10px #f1f1f1; */
	padding: 20px 20px 20px 20px;
}

.accounts-icon > .content > .name {
	display: flex;
	/* margin-bottom: 20px; */
	align-items: center;
}

.accounts-icon > .content > .name > .initial {
	height: 60px;
	width: 60px;
	background-color: #33d7db;
	color: black;
	font-size: 28px;
	border-radius: 50%;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.name-info {
	align-items: left;
}

.accounts-icon > .content > .name > .name-info > .full-name {
	font-size: 16px;
	line-height: 22px;
	color: black;
	text-transform: capitalize;
}

.accounts-icon > .content > .name > .name-info > .email {
	font-size: 12px;
	line-height: 16px;
	color: #808080;
}

.accounts-icon > .content > .account-settings,
.accounts-icon > .content > .logout {
	display: flex;
	font-size: 14px;
	color: #333333;
	cursor: pointer;
}

.accounts-icon > .content > .account-settings > svg,
.accounts-icon > .content > .logout > svg {
	font-size: 20px;
	width: 60px;
	margin-right: 20px;
	/* margin-bottom: 20px; */
	color: #33d7db;
}

.logo-header {
	height: 50px;
}

.app-header-image-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-header-image-container > img {
	height: 45px;
	cursor: pointer;
}

.company-logo-header {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: contain;
}

.new-logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.new-logo-container > .company-logo-header {
	border-right: 1px solid #052a61;
	width: inherit;
	height: 40px;
	border-radius: 0%;
	object-fit: contain;
	padding-right: 20px;
}

.new-logo-container > .assetze-logo {
	height: 40px;
	display: flex;
	flex-direction: column;
	margin-left: 20px;
}

.new-logo-container > .assetze-logo > .logo-header {
	height: 30px;
	object-fit: contain;
}

.new-logo-container > .assetze-logo > .info-head {
	font-size: 10px;
	font-weight: 400;
	font-size: 8px;
	line-height: 10px;
	color: #333333;
}

.header-inner-modal {
	width: 100% !important;
}

.header-inner-modal .ant-modal-content {
	position: absolute;
	right: 100px;
	top: -40px;
	/* width: 230px; */
}

.accounts-icon > .content {
	margin-bottom: 10px;
	gap: 25px;
	padding: 0;
}

.accounts-icon > .content > .name > .initial {
	width: 50px;
	height: 50px;
}

.accounts-icon > .content > .account-settings > svg,
.accounts-icon > .content > .logout > svg {
	width: 52px;
	margin-right: 0px;
}

@media only screen and (max-width: 600px) {
	.header-inner-modal .ant-modal-content {
		right: 20px;
		top: -50px;
		width: 230px;
	}
}
