.pricing-container {
	background-color: #f9f9f9;
	padding-top: 40px;
}

.pricing-container > .head-1,
.pricing-container > .head-2 {
	text-align: center;
}

.pricing-container > .head-3 {
	text-align: center;
	font-size: 16px;
	line-height: 24px;
}

.pricing-container > .content-1,
.pricing-container > .content-2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 50px;
	padding: 40px 10%;
}

.pricing-container > .content-1 > .image-container > img {
	width: 400px;
	object-fit: contain;
}

.pricing-container > .content-1 > .points > .head-1 {
	font-size: 16px;
	margin-bottom: 20px;
	font-weight: 800;
}

.points > .all-points {
	padding-left: 30px;
}

.points > .all-points > .each-point,
.content-container > .all-points > .each-point {
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 10px;
}

.points > .all-points > .each-point > svg,
.content-container > .all-points > .each-point > svg {
	margin-right: 10px;
	color: #51f0b6;
}

.content-2 > .content-container > .head-1 {
	color: #333333;
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 20px;
}

.content-2 > .content-container > .head-2 {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;
}

.request-quote {
	background-color: #33d7db;
	border: 0px;
	padding: 10px 40px;
	margin-top: 20px;
	color: white;
	font-size: 18px;
	font-weight: 400;
	border-radius: 5px;
}

.pricing-container > .divider {
	background-color: white;
	height: 50px;
}

.pricing-content-1 {
	padding: 20px 10%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.trashcan-image-container > img {
	width: 400px;
}
.pricing-content-container > h1 {
	padding-top: 70px;
}

.pricing-content-container > h1,
.pricing-content-container > h4 {
	text-align: center;
}

.pricing-content-container > .list {
	margin: 0 25%;
}

.pricing-content-container {
	background-color: #f9f9f9;
	margin-top: 120px;
}

.pricing-content-2 {
	margin-top: 60px;
	background-color: #f9f9f9;
	padding: 20px 10%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 60px;
}

.pricing-content-2 > .ewaste-imagecontainer > img {
	height: 350px;
}

.pricing-content-2 > .contents {
	width: 40%;
	color: #333;
}

.pricing-content-2 > .contents > .each-list-item {
	margin: 10px 0px;
}

.pricing-content-2 > .contents > .each-list-item > svg {
	color: #51f0b6;
}

.pricing-content-2 > .contents > .button-action {
	background-color: #33d7db;
	padding: 10px 20px;
	border: 0px;
	border-radius: 5px;
	color: white;
	margin-top: 20px;
}

.pricing-content-3 > .table-header {
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 1px solid #555555;
	text-align: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.pricing-content-3 {
	padding: 20px 15%;
	color: #555555;
}

.pricing-content-3 > .table-header > .each-header {
	padding: 20px 0;
	flex: 1;
}

.pricing-content-3 > .table-header > .each-header:nth-child(2) {
	border-left: 1px solid #555555;
	border-right: 1px solid #555555;
}

.pricing-content-3 > .table-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.pricing-content-3 > .table-content > .eachtable-content {
	flex: 1;
	padding-left: 20px;
}

.pricing-content-3 > .table-content > .eachtable-content:nth-child(1) {
	border-left: 1px solid #555;
}

.pricing-content-3 > .table-content > .eachtable-content:nth-child(2) {
	border-left: 1px solid #555;
	border-right: 1px solid #555;
}
.pricing-content-3 > .table-content > .eachtable-content:nth-child(3) {
	border-right: 1px solid #555;
}

.pricing-content-3 > .table-content > .eachtable-content > .eachtable-info {
	margin: 10px 0px;
}

.pricing-content-3 > .table-content > .eachtable-content > .eachtable-info > svg {
	color: #33d7db;
	margin-right: 10px;
}

.pricing-content-3 > .table-footer {
	display: flex;
	background-color: #33d7db;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #555;
	color: white;
}

.pricing-content-3 > .table-footer > .each-footer {
	flex: 1;
	text-align: center;
	padding: 10px 0px;
}

.pricing-content-3 > .table-footer > .each-footer:nth-child(2) {
	border-left: 1px solid #555;
	border-right: 1px solid #555;
}

.pricing-content-3 > .table-footer > .each-footer > div > span {
	font-size: 24px;
}

.select-plan {
	margin-top: 20px;
	background-color: #66f9a4;
	border: 0px;
	padding: 10px 30px;
	border-radius: 5px;
}

@media only screen and (max-width: 600px) {
	.pricing-content-1 {
		flex-direction: column-reverse;
		padding: 20px 1%;
	}
	.pricing-content-2 {
		flex-direction: column;
	}
	.pricing-content-3 > .table-header > .each-header {
		/* width: 90vw; */
	}
	.pricing-content-2 > .contents {
		width: 90%;
		padding-top: 40px;
	}
	.pricing-content-container > .list {
		margin: 0 2%;
	}
	.trashcan-image-container > img {
		width: 90vw;
		/* padding-top: 40px; */
	}
}
