.shopping-cart-container > .product-options {
	background-color: #f5f5f5;
}

.product-options > div {
	padding-right: 25px;
	padding-left: 25px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #aaaaaa;
	font-weight: 500;
}

.product-options > div.active {
	border-bottom: 3px solid #33d7db;
	color: #333333;
}
