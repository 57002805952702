.feature-incident-container {
	/* max-height: calc(100vh - 60px);
	height: calc(100vh - 60px); */
	/* padding: 0px 0%; */
	color: #333;
	/* overflow-y: scroll; */
	margin-top: 120px;
}

.feature-incident-container > .content-container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 50px 10%;
	color: #333;
}

.content-text-container {
	/* padding-left: 50px; */
}

.content-text-container > ul {
	max-width: 70%;
}

.content-container > .content-text-container > h1 {
	font-size: 48px;
	font-weight: 800;
}

.content-text-container > .description {
	font-size: 20px;
	font-weight: 400;
	max-width: 85%;
}

button.signup-button {
	margin-top: 30px;
	border: 0px;
	padding: 12px 30px;
	background-color: #66f9a4;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
}

.features-highlights {
	display: flex;
	justify-content: space-between;
	padding: 50px 10%;
}

.features-highlights > .each-highlight {
	width: 50%;
}

.features-highlights > .each-highlight > .description {
	max-width: 70%;
}

@media only screen and (max-width: 600px) {
	.feature-incident-container {
		padding: 13vh 10px;
	}
	.feature-incident-container > .content-container {
		flex-direction: column;
	}
	.feature-incident-container > .content-container > .image-container > img {
		width: 90vw;
	}
	.feature-incident-container > .content-container > .content-text-container {
		text-align: center;
	}
	.content-text-container > .description {
		max-width: 100%;
	}
	.feature-incident-container > .content-container {
		padding: 20px 4%;
	}
	.feature-incident-container > .content-container > .image-container {
		margin-top: 40px;
	}
	.content-container > .content-text-container > h1 {
		font-size: 32px;
	}
	.feature-incident-container > .content-container:nth-child(3) {
		flex-direction: column-reverse;
	}
	.feature-incident-container > .content-container:nth-child(3) > .content-text-container {
		padding-left: 0px !important;
	}
	.features-highlights {
		flex-direction: column;
	}
	.features-highlights > .each-highlight {
		width: 100%;
		margin-bottom: 30px;
	}
	.features-highlights > .each-highlight > .description {
		max-width: 100%;
	}
	.features-signup-container > .head-1 {
		font-size: 24px;
	}
	.features-signup-container {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
