.mydevices-container > hr {
	border: 1px solid #555555;
	margin: 35px 25px;
	width: 85%;
}

.mydevices-container {
	overflow: hidden;
	text-align: center;
}

.issue {
	margin-left: 20px;
	margin-right: 20px;
}

.issue > div {
	font-size: 20px;
	font-weight: 800;
	color: #333333;
	padding-bottom: 10px;
	text-align: left;
}

.issue > input {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 5px;

	padding-left: 30px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	width: 100%;
}

.issue > .description {
	height: 300px;
}

.mydevices-container > .button {
	border: none;
	background: linear-gradient(90deg, #55fc9d 0%, #54f1a1 100%);
	border-radius: 5px;

	font-size: 24px;
	font-weight: 500;
	color: #333333;
	padding: 10px 25%;

	margin-top: 40px;
	margin-bottom: 30px;
	margin-left: 10px;
	margin-right: 10px;
}

.assurance {
	font-size: 25px;
	font-weight: 400;
	color: #333333;

	margin-top: 20px;
}

.support {
	font-size: 16px;
	font-weight: 400;
	color: #333333;

	margin-top: 10px;
}
