.dashboard-bg-1 {
	background: url('../../images/dashboard/wallpaper-bg.png');
	min-height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 50px;
}

.dashboard-bg-1 > .heading-1 {
	color: #496b73;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard-bg-1 > .container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-right: 150px;
	margin-top: 40px;
}
.dashboard-bg-1 > .container > .text-container {
	width: 50%;
}

.dashboard-bg-1 > .container > .image-container {
	width: 50%;
}

.dashboard-bg-1 > .container > .image-container > img {
	width: 500px;
}

.dashboard-bg-1 > .container > .text-container > .email-container {
	margin-bottom: 20px;
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid #496b73;
	border-radius: 5px;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-top: 40px;
}

.dashboard-bg-1 > .container > .text-container > .action-getstarted {
	width: 70%;
	background: #ffffff;
	border: 0.5px solid #456653;
	border-radius: 5px;
	padding-top: 11px;
	padding-bottom: 11px;
}

.dashboard-bg-1 > .container > .text-container > .email-container > input {
	border: 0px;
	background-color: transparent;
	outline: none;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	color: #555555;
	margin-top: 10px;
	margin-bottom: 5px;
}

.dashboard-bg-1 > .container > .text-container > .email-container > label {
	font-weight: 300;
	font-size: 16px;
	line-height: 42px;
	color: #777777;
	margin-bottom: -16px;
	margin-top: -5px;
}

.dashboard-bg-1 > .container > .text-container > .text-info {
	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 36px;
	color: #547982 !important;
	margin-top: 40px;
}

.dashboard-bg-1 > .container > .text-container > .heading-2 {
	color: #496b73;
	font-family: 'League Spartan';
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 42px;
}

.circle-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 50px;
	padding-bottom: 50px;
}

.circle-container > .heading-1 {
	font-style: normal;
	font-weight: 400;
	font-size: 42px;
	line-height: 42px;
	color: #555555;
}

.circle-container > .heading-2 {
	font-weight: 300;
	font-size: 30px;
	line-height: 42px;
	text-align: center;
	color: #555555;
	padding-top: 15px;
	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
	padding-top: 10px;
	padding-bottom: 20px;
}

.circle-container > .circle-image {
	padding-top: 50px;
}

.circle-container > .circle-image > img {
	width: 70vw;
}

.circle-container > .action {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 80px;
}

.circle-container > .action > button {
	background: #33d7db;
	box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.01);
	border-radius: 5px;
	width: 400px;
	border: 0px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #333333;
}

.software-info-container {
	/* display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column; */

	background: #f9f9f9;
	padding-top: 75px;
}

.software-info-container > .heading-1 {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 36px;
	line-height: 42px;
	/* identical to box height, or 117% */

	color: #555555;

	justify-content: center;
	padding-bottom: 15px;
}

.software-info-container > .heading-2 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	/* or 131% */

	text-align: center;

	color: #555555;
	padding-bottom: 15px;

	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}

.software-info-container > .content {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: row;
	margin-top: 20px;
}

.software-info-container > .content > .image-container > img {
	height: 450px;
	padding-left: 80px;
}

.software-info-container > .content > .text-container {
	padding-right: 80px;
}

.software-info-container > .content > .text-container > .each-content {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	max-width: 450px;
	margin-bottom: 23px;
	padding: 32px 20px;
	cursor: pointer;
}

.software-info-container > .content > .text-container > .each-content > .icon-container {
	padding-right: 25px;
}

.software-info-container > .content > .text-container > .each-content > .icon-container > img {
	width: 36px;
}

.software-info-container > .content > .text-container > .each-content > .text-content > .heading-1 {
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	/* identical to box height, or 140% */

	color: #555555;

	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
	margin-top: -8px;
	padding-bottom: 18px;
}

.software-info-container > .content > .text-container > .each-content > .text-content > .heading-2 {
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
	/* or 143% */

	color: #555555;

	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}

.software-info-container > .content > .text-container > .each-content.active {
	background: #c3fce7;
}

.shipment-container {
	/* display: flex;
	align-items: center;
	flex-direction: column; */
	padding: 50px;
}

.shipment-container > .heading-1 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 34px;
	/* identical to box height, or 117% */
	padding-bottom: 15px;
	color: #555555;
	display: flex;
	align-items: center;
	justify-content: center;
}

.shipment-container > .heading-2 {
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	text-align: center;
	color: #555555;
	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
	max-width: 60%;
	padding-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
}

.shipment-container > .image-conatiner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.shipment-container > .image-conatiner > div {
	margin-right: 40px;
}

.shipment-container > .image-conatiner > div > img {
	width: 250px;
	max-width: 306px;
}

.new-task-container {
	padding: 50px 140px;
}

.new-task-container > .heading-1 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 36px;
	line-height: 50px;
	text-align: center;
	color: #555555;
	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
	padding-bottom: 20px;
}

.new-task-container > .tasklist-container {
	display: flex;
	align-items: stretch;
	justify-content: space-around;
}

.new-task-container > .tasklist-container > .each-task {
	border: 1px solid #555555;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-direction: column;
	padding: 50px;
	border-radius: 5px;
	margin-right: 40px;
	width: 20vw;
	/* align-items: stretch; */
}

.new-task-container > .tasklist-container > .each-task > .image-container {
	width: 170px;
	height: 150px;
}

.new-task-container > .tasklist-container > .each-task > div.image-container > img {
	width: 170px;
	height: 150px;
	object-fit: contain;
}

.new-task-container > .tasklist-container > .each-task > .head {
	font-weight: 500;
	font-size: 24px;
	line-height: 22px;
	/* identical to box height */

	text-align: center;
	margin-top: 20px;
	margin-bottom: 10px;
	color: #555555;

	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}

.new-task-container > .tasklist-container > .each-task > .subtext {
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	/* or 133% */

	text-align: center;

	color: #555555;

	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
	margin-top: 24px;
}

.portfolio {
	padding: 50px 100px;
	background: #f9f9f9;
}

.portfolio > .heading {
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 33px;
	text-align: center;
	color: #555555;
}

.portfolio > .slider-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100vw - 200px);
}

.each-review {
	width: 350px !important;
	background: white;
}

.direction-icon {
	height: 30px;
	cursor: pointer;
}

.slider-container > div > .slick-slider {
	width: calc(100vw - 300px);
}

.each-review > .review-content {
	padding: 30px 30px 20px 60px;
}

.each-review > .review-content > .head {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #555555;
	text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}

.each-review > .review-content > .name {
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 22px;
	padding-top: 23px;
	color: #333333;
	padding-bottom: 30px;
}

.each-review > .review-content > .name > .designation {
	font-weight: 400;
}

.each-review > .image-container > img {
	width: 350px !important;
}

.each-review > .review-content > .image-conatiner > img {
}

.next-container > .right {
	rotate: 180deg;
}

.dashboard-bg-1 > .text-info {
	display: none;
}
.dashboard-bg-1 > .email-container {
	display: none;
}
.dashboard-bg-1 > .action-getstarted {
	display: none;
}

.software-info-container > .content {
	/* display: block; */
}
.software-info-container > .content-2 {
	display: none;
}

@media only screen and (max-width: 600px) {
	.slick-2 > .slick-list > .slick-track > .slick-active {
		display: flex !important;
		align-items: center;
		justify-content: space-around;
	}
	.dashboard-bg-1 {
		padding-bottom: 50px;
	}
	.dashboard-bg-1 > .container {
		flex-direction: column;
		padding-right: 0px;
	}
	.dashboard-bg-1 > .text-info {
		font-style: normal;
		font-weight: 400;
		font-size: 26px;
		line-height: 36px;
		color: #547982 !important;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		display: block;
	}
	.dashboard-bg-1 > .email-container {
		margin-bottom: 20px;
		background: rgba(255, 255, 255, 0.5);
		border: 1px solid #496b73;
		border-radius: 5px;
		padding-left: 10px;
		display: flex;
		flex-direction: column;
		width: 70%;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
	}
	.dashboard-bg-1 > .email-container > input {
		border: 0px;
		background-color: transparent;
		outline: none;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		color: #555555;
		margin-top: 10px;
		margin-bottom: 5px;
	}
	.dashboard-bg-1 > .email-container > label {
		font-weight: 300;
		font-size: 16px;
		line-height: 42px;
		color: #777777;
		margin-bottom: -16px;
		margin-top: -5px;
	}
	.dashboard-bg-1 > .action-getstarted {
		width: 70%;
		background: #ffffff;
		border: 0.5px solid #456653;
		border-radius: 5px;
		padding-top: 11px;
		padding-bottom: 11px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.dashboard-bg-1 > .container > .image-container > img {
		width: 90%;
	}
	.dashboard-bg-1 > .container > .text-container {
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
	}
	.dashboard-bg-1 > .container > .image-container {
		width: 100%;
		padding-left: 5%;
	}
	.dashboard-bg-1 > .container > .text-container > .text-info {
		display: none;
	}
	.dashboard-bg-1 > .container > .text-container > .action-getstarted {
		display: none;
	}
	.dashboard-bg-1 > .container > .text-container > .email-container {
		display: none;
	}
	.circle-container > .circle-image > img {
		height: inherit;
		width: 90vw;
	}
	.dashboard-bg-1 > .container > .text-container > .heading-2 {
		text-align: center;
		font-style: normal;
		font-weight: 400;
		font-size: 26px;
		line-height: 30px;
	}
	.container {
		padding-left: 0px !important;
	}

	.circle-container {
		padding-left: 5%;
		padding-right: 5%;
	}
	.circle-container > .heading-1 {
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 42px;
		text-align: center;
	}
	.circle-container > .heading-2 {
		font-weight: 300;
		font-size: 1rem;
		line-height: 1.3rem;
		/* or 125% */

		text-align: center;

		color: #555555;
	}
	.circle-container > .heading-2 > br {
		display: none;
	}
	.software-info-container > .heading-1 {
		font-size: 1.1rem;
		line-height: 30px;
		/* or 125% */
		padding-left: 5%;
		padding-right: 5%;
		text-align: center;
		font-weight: 600;
		color: #555555;
	}
	.software-info-container > .heading-2 {
		font-style: normal;
		font-weight: 300;
		font-size: 0.95rem;
		line-height: 1.4rem;
		/* or 131% */
		padding-left: 5%;
		padding-right: 5%;

		text-align: center;

		color: #555555;
	}
	.software-info-container > .content {
		display: none;
	}
	.software-info-container > .content-2 {
		display: block;
	}
	.shipment-container > .image-conatiner {
		flex-direction: column;
	}
	.shipment-container {
		padding: 5%;
	}
	.shipment-container > .heading-1 {
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 42px;
		/* identical to box height, or 140% */

		color: #555555;
		padding-top: 50px;
	}
	.shipment-container > .heading-2 {
		max-width: 90%;
		padding-bottom: 20px;
	}
	.shipment-container > .image-conatiner > div {
		margin-right: 0px;
	}
	.new-task-container {
		padding: 5%;
	}
	.new-task-container > .heading-1 {
		font-weight: 700;
		font-size: 30px;
		line-height: 42px;
		/* or 140% */

		text-align: center;

		color: #555555;
	}
	.new-task-container > .tasklist-container {
		flex-direction: column;
	}
	.new-task-container > .tasklist-container > .each-task {
		width: 80%;
		margin-right: 0px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		padding: 10px;
	}
	.new-task-container > .tasklist-container > .each-task > .head {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 22px;
		/* identical to box height */

		text-align: center;

		color: #555555;
	}
	.portfolio {
		padding: 5%;
	}
	.portfolio > .slider-container {
		/* width: 100%; */
	}
	.portfolio > .slider-container > .all-slides-data {
		/* width: 80%; */
	}
	.slider-container > .all-slides-data > .slick-slider {
		width: calc(90vw) !important;
	}

	.slider-container > .previous {
		display: none;
	}
	.slider-container > .next-container {
		display: none;
	}
	.each-review {
		width: 100%;
	}
	.portfolio > .heading {
		/* margin-bottom: 25px; */
		font-size: 1.3rem;
		font-weight: 500;
	}
	.each-review > .review-content > .head {
		font-size: 1.1rem;
	}

	.each-review > .image-container > img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		width: 100% !important;
		overflow: hidden;
	}
	.circle-container > .action > button {
		width: 75vw;
	}
	.service-info > .image-container > img {
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
	}
	.service-info > .each-content {
		margin-left: 5%;
		margin-right: 5%;
		background: #c3fce7;
		margin-top: 20px;
		display: flex;
		padding: 5%;
	}
	.service-info > .each-content > .icon-container {
		margin-right: 20px;
	}
	.service-info > .each-content > .text-content > .heading-1 {
		font-weight: 500;
		font-size: 17px;
		/* line-height: 42px; */
		/* identical to box height, or 200% */

		color: #555555;
		margin-bottom: 10px;
	}
	.service-info > .each-content > .text-content > .heading-2 {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 18px;
		/* or 167% */
		padding-bottom: 20px;

		color: #555555;
	}
	.prod-slider > .slick-list {
	}
}
